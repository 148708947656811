import React from "react";
import { connect } from "react-redux";

import { Form, Button, Row, message } from "antd";
import { get_flat_block_names } from "../../../../appRedux/actions/Flat";
import {
  get_all_devices_in_use,
  relay_device,
} from "../../../../appRedux/actions/Device";
import { fetchStart } from "../../../../appRedux/actions/Common";
import CircularProgress from "components/CircularProgress";
import SelectionFilter from "../dropdown";

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device_id : "",
      deviceList: [],
      loading: false,
      iconLoading: false,
      customData: false,
    };
  }


  componentDidMount() {
    this.props.fetchStart();
    this.props.get_all_devices_in_use();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.list_devices !== nextProps.list_devices) {
      this.setState({ deviceList: nextProps.list_devices });
      return false;
    } else {
      return true;
    }
  }
  enterLoading = () => {
    this.setState({ loading: true });
    if(this.state.device_id == "All"){
      this.props.get_flat_block_names()
      this.setState({ loading: false });
    }else{
      this.setState({ loading: false });
      this.props.get_flat_block_names(`device_id=${this.state.device_id} `)
    }
    
  };
  handleSelected(val, type) {
    this.setState({device_id : val})
  }

  render() {
    const { loading, error_occurred, show_message } = this.props;
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          <SelectionFilter
            key="device"
            type={"device"}
            place={"All"}
            data={this.state.deviceList}
            selected={this.handleSelected.bind(this)}
          />
          <Button
            type="primary"
            style={{ marginTop: "28px", marginLeft: "10px" }}
            loading={this.state.loading}
            onClick={this.enterLoading}
          >
            Submit
          </Button>
        </Row>

        <div>
          {loading ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {error_occurred ? message.error(show_message.toString()) : null}
        </div>
      </div>
    );
  }
}

const FlatList = Form.create()(EditableTable);

function mapStateToProps({ common, device, Block, flats }) {
  const { blocks } = Block;
  const { block_flats } = flats;
  const { list_devices } = device;
  const { error_occurred, loading, show_message, warning_message } = common;
  return {
    blocks,
    block_flats,
    error_occurred,
    loading,
    show_message,
    warning_message,
    list_devices
  };
}
export default connect(mapStateToProps, {
  get_all_devices_in_use,
  get_flat_block_names,
  fetchStart,
})(FlatList);
