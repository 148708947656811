import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_ALL_UTILITIES, GET_FAILED_UTILITES } from "../../constants/ActionTypes";

import { get_all_utilities_success,get_failed_utilities_success } from "../actions/Utility";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";
import axios from "axios";

const fetchUtilities = async () =>
  await axios
    .get("/project/utility/" + JSON.parse(localStorage.getItem("projectId")))
    .then((res) => res.data)
    .catch((error) => error);

const fetchFailedUtilities = async (url) =>
  await axios
    .get(`/failed/meters?projectId=${JSON.parse(
      localStorage.getItem("projectId")
    )}${url}`)
    .then((res) => res.data)
    .catch((error) => error);

function* getUtilites() {
  try {
    const data = yield call(fetchUtilities);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_all_utilities_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getAllFailedUtilites({payload}) {
  try {
    const data = yield call(fetchFailedUtilities, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_failed_utilities_success(data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
export function* getAllUtilities() {
  yield takeEvery(GET_ALL_UTILITIES, getUtilites);
}

export function* getFailedUtilities() {
  yield takeEvery(GET_FAILED_UTILITES, getAllFailedUtilites);
}
export default function* rootSaga() {
  yield all([fork(getAllUtilities),fork(getFailedUtilities)]);
}
