import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, DatePicker, Popconfirm } from "antd";
import moment from "moment";

import { fetchStart } from "../../../../appRedux/actions/Common";
import { get_all_blocks } from "../../../../appRedux/actions/Block";
import { get_all_flats } from "../../../../appRedux/actions/Flat";
import { get_all_bills, send_bills } from "../../../../appRedux/actions/Bill";
import { get_tariff } from "../../../../appRedux/actions/Tariff";

import Dropdown from "./Dropdown";

const { MonthPicker } = DatePicker;
const monthFormat = "YYYY/MM";

/**
 * @description `This component renders dropdown group for recharge page`
 */

const allType = { name: "All", id: "myall" };

const dropdownPayTypes = [
  { name: "All", id: "All" },
  { name: "Pending", id: "Pending" },
  { name: "Paid", id: "Paid" },
];

const dropdownBillStatus = [
  {
    name: "Not Sent",
    id: "notsent",
  },
  {
    name: "Sent",
    id: "sent",
  },
];

const MainDropdownGroup = ({ blocks, flats, common, ...props }) => {
  const [dropdownBlocks, setDropdownBlocks] = useState([allType]);
  const [dropdownFlats, setDropdownFlats] = useState([allType]);

  const [block, setBlock] = useState(allType);
  const [flat, setFlat] = useState(allType);
  const [payType, setPayType] = useState(dropdownPayTypes[0]);
  const [billStatus, setBillStatus] = useState(dropdownBillStatus[0]);
  const [dropdownMonth, setDropdownMonth] = useState(new Date());
  const [popVisible, setPopVisible] = useState(false);
  const [localLoading, setLocalLoading] = useState("");

  useEffect(() => {
    props.get_all_blocks();
    props.get_all_flats();
    props.get_tariff();
  }, []);

  useEffect(() => {
    if (block.id === "myall") {
      setDropdownFlats([allType]);
      setFlat(allType);
    } else {
      const temp = [];
      flats.forEach((flat) => {
        if (flat.blockId._id === block.id) {
          temp.push({ name: flat.name, id: flat._id });
        }
      });
      setDropdownFlats([allType, ...temp]);
      setFlat(allType);
    }
  }, [block]);

  useEffect(() => {
    const temp = blocks.map((block) => ({ name: block.name, id: block._id }));
    setDropdownBlocks([allType, ...temp]);
  }, [blocks]);

  useEffect(() => {
    if (localLoading !== "") {
      handleSubmit();
    }
  }, [props.page, props.rows]);

  const handleSubmit = () => {
    props.fetchStart();
    let blockId = "All";
    if (block.name !== "All") {
      blockId = block.id;
    }
    let flatId = "All";
    if (flat.name !== "All") {
      flatId = flat.id;
    }
    const date = moment(dropdownMonth).format(monthFormat);
    // at bill fetching url and call api
    const url = `&paymentStatus=${payType.id}&blockId=${blockId}&flatId=${flatId}&date=${date}&pagination=${props.rows}&page=${props.page}&status=${billStatus.id}`;
    props.get_all_bills(url);
    setLocalLoading("get");
  };

  const handleBillSend = () => {
    setLocalLoading("post");
    props.send_bills({
      date: moment(dropdownMonth).format("YYYY-MM-DD"),
      allSelectedBills: props.allSelectedBills,
      billsId: props.billsId,
    });
    setPopVisible(false);
  };

  return (
    <div className="gx-d-flex gx-align-items-end">
      <Dropdown
        title="block"
        data={dropdownBlocks}
        value={block}
        setValue={setBlock}
      />
      <Dropdown
        title="flat"
        data={dropdownFlats}
        value={flat}
        setValue={setFlat}
        width={120}
      />
      <Dropdown
        title="payment type"
        data={dropdownPayTypes}
        value={payType}
        setValue={setPayType}
        width={150}
      />
      <Dropdown
        title="bill status"
        data={dropdownBillStatus}
        value={billStatus}
        setValue={setBillStatus}
        width={150}
      />
      <div>
        <h5>Select Month</h5>
        <MonthPicker
          style={{ marginRight: 10 }}
          defaultValue={moment(dropdownMonth)}
          format={monthFormat}
          onChange={(str, dt) => setDropdownMonth(moment(dt))}
        />
      </div>
      <Button
        type="primary"
        className="gx-mb-0"
        loading={localLoading === "get" && common.loading}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <Popconfirm
        title={`${moment(dropdownMonth).format("MMMM YYYY")}, Are you sure?`}
        visible={popVisible}
        onConfirm={handleBillSend}
        onCancel={() => setPopVisible(false)}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          className="gx-mb-0 gx-ml-auto"
          disabled={billStatus.id == "sent" ? true : false}
          loading={localLoading === "post" && common.loading}
          onClick={() => setPopVisible(true)}
        >
          Send Bills
        </Button>
      </Popconfirm>
    </div>
  );
};

export default connect(
  (state) => ({
    blocks: state.Block.blocks,
    flats: state.flats.flats_list,
    common: state.common,
  }),
  {
    get_all_blocks,
    get_all_flats,
    fetchStart,
    get_all_bills,
    get_tariff,
    send_bills,
  }
)(MainDropdownGroup);
