import { GET_ALL_UTILITIES_SUCCESS,GET_FAILED_UTILITES_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  utilities: [],
  failedUtilites : { data: [], count: 0}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_UTILITIES_SUCCESS: {
      return {
        ...state,
        utilities: action.payload || [],
      };
    }
    case GET_FAILED_UTILITES_SUCCESS: {
      return {
        ...state,
        failedUtilites: action.payload ||{ data: [], count: 0 },
      };
    }
    default:
      return state;
  }
};
