import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "antd";

import { fetchStart } from "../../../../appRedux/actions/Common";
import { get_all_blocks } from "../../../../appRedux/actions/Block";
import {
  get_all_flats
} from "../../../../appRedux/actions/Flat";
import {
  get_failed_utilities
} from "../../../../appRedux/actions/Utility";


import Dropdown from "./Dropdown";

/**
 * @description `This component renders dropdown group for recharge page`
 */

const allType = { name: "All", id: "myall" };

const DropdownGroup = ({ blocks, flats, common, ...props }) => {
  const [dropdownBlocks, setDropdownBlocks] = useState([allType]);

  const [dropdownFlats, setDropdownFlats] = useState([allType]);

  const [block, setBlock] = useState(allType);
  const [flat, setFlat] = useState(allType);

  useEffect(() => {
    props.get_all_blocks();
    props.get_all_flats();
  }, []);

  useEffect(() => {
    if (block.id === "myall") {
      setDropdownFlats([allType]);
      setFlat(allType);
    } else {
      const temp = [];
      flats.forEach((flat) => {
        if (flat.blockId._id === block.id) {
          temp.push({ name: flat.name, id: flat._id });
        }
      });
      setDropdownFlats([allType, ...temp]);
      setFlat(allType);
    }
  }, [block]);

  useEffect(() => {
    const temp = blocks.map((block) => ({ name: block.name, id: block._id }));
    setDropdownBlocks([allType, ...temp]);
  }, [blocks]);

  useEffect(() => {
    handleSubmit();
  }, [props.page, props.rows]);
  const handleSubmit = () => {
    props.fetchStart();
    let blockId = "All";
    if (block.name !== "All") {
      blockId = block.id;
    }
    let flatId = "All";
    if (flat.name !== "All") {
      flatId = flat.id;
    }
    const url = `&blockId=${blockId}&flatId=${flatId}&pagination=${props.rows}&page=${props.page}`;
    props.get_failed_utilities(url);
  };

  return (
    <div className="gx-d-flex gx-align-items-end">
      <Dropdown
        title="block"
        data={dropdownBlocks}
        value={block}
        setValue={setBlock}
      />
      <Dropdown
        title="flat"
        data={dropdownFlats}
        value={flat}
        setValue={setFlat}
        width={100}
      />
      <Button
        type="primary"
        className="gx-mb-0"
        loading={common.loading}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default connect(
  (state) => ({
    blocks: state.Block.blocks,
    flats: state.flats.flats_list,
    common: state.common,
  }),
  {
    get_all_blocks,
    get_all_flats,
    get_failed_utilities,
    fetchStart,
  }
)(DropdownGroup);
