import {
  GET_ALL_BLOCKS,
  GET_ALL_BLOCKS_SUCCESS,
  POST_BLOCK,
  POST_BLOCK_SUCCESS,
  UPDATE_BLOCK,
} from "constants/ActionTypes";

export const get_all_blocks = () => {
  return {
    type: GET_ALL_BLOCKS,
  };
};

export const get_all_blocks_success = (blocks) => {
  return {
    type: GET_ALL_BLOCKS_SUCCESS,
    payload: blocks,
  };
};

export const post_block = (payload) => {
  return {
    type: POST_BLOCK,
    payload: payload,
  };
};
export const update_block = (payload) => {
  return {
    type: UPDATE_BLOCK,
    payload: payload,
  };
};
