import {
  GET_ALL_DEVICES_SUCCESS,
  GET_ALL_DEVICES_IN_USE_SUCCESS,
  GET_FLAT_DEVICE_READING_SUCCESS,
  GET_ALL_FLAT_DEVICES_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  get_devices: [],
  list_devices: [],
  flat_device_readings: [],
  all_flat_readings: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_DEVICES_SUCCESS: {
      return {
        ...state,
        get_devices: action.payload,
      };
    }
    case GET_ALL_DEVICES_IN_USE_SUCCESS: {
      return {
        ...state,
        list_devices: action.payload,
      };
    }
    case GET_ALL_FLAT_DEVICES_SUCCESS: {
      return {
        ...state,
        all_flat_readings: action.payload,
      };
    }
    case GET_FLAT_DEVICE_READING_SUCCESS:
      return {
        ...state,
        flat_device_readings: action.payload,
      };
    default:
      return state;
  }
};
