import React from "react";
import DeviceRead from "./Device/device";
import { Card } from "antd";
import Dropdown from "./Dropdown/mainDropdown";

const DeviceReading = () => {
  return (
    <Card className="gx-card" title="All Flats Readings">
      <div>
        <Dropdown />
      </div>
      <div>
        <DeviceRead />
      </div>  
    </Card>
  );
};
export default DeviceReading;
