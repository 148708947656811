import React, { Component } from "react";
import {
  AutoComplete,
  Button,
  Card,
  Form,
  Icon,
  Input,
  Select,
  Radio,
  Tooltip,
  Checkbox,
  Col,
} from "antd";
import { connect } from "react-redux";
import { get_all_blocks } from "../../../../appRedux/actions/Block.js";
import { fetchStart } from "../../../../appRedux/actions/Common";
import { post_flat, get_all_flats } from "../../../../appRedux/actions/Flat";
const FormItem = Form.Item;

class FlatRegistration extends Component {
  state = {
    blocks: [],
    checked: true,
  };

  componentDidMount() {
    this.props.get_all_blocks();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) {
      this.setState({ blocks: nextProps.blocks });
      return false;
    } else {
      return true;
    }
  }

  handleSubmit = (e) => {
    this.props.fetchStart();
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var status = this.state.checked;
        if (status) values.status = 1001;
        else values.status = 1000;
       // values.projectId = "5e66a912e65224373f394987";
        this.props.post_flat(values);
        setTimeout(() => {
          this.props.get_all_flats();
          this.props.history.push(`/flats`);
        }, 0);
      }
    });
  };

  render() {
    const label = `${this.state.checked ? "Active" : "In-Active"}`;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const children = [];
    for (let i = 10; i < 15; i++) {
      children.push(
        <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
      );
    }
    return (
      <Card className="gx-card" title="Create Flat">
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Select Block&nbsp;
                <Tooltip title="This is the project">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("blockId", {
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Select a Block"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.blocks.map((list) => {
                  return <Option value={list._id}>{list.name}</Option>;
                })}
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Flat Name&nbsp;
                <Tooltip title="This is Flat name">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input your Flat Name!",
                  whitespace: true,
                },
              ],
            })(
              <AutoComplete placeholder="Flat Name">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Flat Type&nbsp;
                <Tooltip title="This is Flat type">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("flatType", {
              rules: [
                {
                  required: true,
                  message: "Please select any one",
                },
              ],
            })(
              <Radio.Group>
                <Radio value="sft">SFT</Radio>
                <Radio value="bhk">BHK</Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Flat sq ft&nbsp;
                <Tooltip title="This is Flat square feet">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("sft", {
              rules: [
                {
                  message: "Please Flat square feet!",
                  whitespace: true,
                },
              ],
            })(
              <AutoComplete placeholder="Flat square feet">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                BHK&nbsp;
                <Tooltip title="This is Flat BHK Type">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("bhk", {
            })(
              <AutoComplete placeholder="Flat BHK Type">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={<span>Flat Code</span>}>
            {getFieldDecorator(
              "flat_code",
              {}
            )(
              <AutoComplete placeholder="Flat Code">
                <Input />
              </AutoComplete>
            )}
          </FormItem>

          <Col xs={{ span: 26 }} lg={{ span: 12, offset: 8 }}>
            <div>
              <p style={{ marginBottom: "20px" }}>
                <Checkbox
                  checked={this.state.checked}
                  disabled={this.state.disabled}
                  onChange={this.onChange}
                >
                  {label}
                </Checkbox>
              </p>
            </div>
          </Col>
          <br></br>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const FlatRegistrationForm = Form.create()(FlatRegistration);

function mapStateToProps({ common, Block }) {
  const { blocks } = Block;
  const { error_occurred, loading, show_message, warning_message } = common;
  return { blocks, error_occurred, loading, show_message, warning_message };
}
export default connect(mapStateToProps, {
  get_all_blocks,
  post_flat,
  fetchStart,
  get_all_flats
})(FlatRegistrationForm);
