import {
  CUMULATIVE_REPORT_SUCCESS,
  INDIVIDUAL_REPORT_SUCCESS,
  CUMULATIVE_WEEK_REPORT_SUCCESS,
  CUMULATIVE_DETAIL_REPORT_SUCCESS,
  INDIVIDUAL_BLOCK_REPORT_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  cumulative: { data: [], count: 0 },
  individual: { data: [], count: 0 },
  week: { data: [], count: 0 },
  individualCumulative: { data: [], count: 0 },
  individualBlockReport: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUMULATIVE_REPORT_SUCCESS: {
      return {
        ...state,
        cumulative: action.payload || { data: [], count: 0 },
      };
    }
    case CUMULATIVE_WEEK_REPORT_SUCCESS: {
      return {
        ...state,
        week: action.payload || { data: [], count: 0 },
      };
    }
    case INDIVIDUAL_REPORT_SUCCESS: {
      return {
        ...state,
        individual: action.payload || { data: [], count: 0 },
      };
    }
    case CUMULATIVE_DETAIL_REPORT_SUCCESS: {
      return {
        ...state,
        individualCumulative: action.payload || { data: [], count: 0 },
      };
    }
    case INDIVIDUAL_BLOCK_REPORT_SUCCESS: {
      return {
        ...state,
        individualBlockReport: action.payload,
      };
    }
    default:
      return state;
  }
};
