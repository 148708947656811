import React from "react";
import { Button } from "antd";
import moment from "moment";
import XLSX from "xlsx";

//? format cummulative data for xlsx in json format
function cummulativeData(indata, utilities) {
  const temp = { date: "date" };
  utilities.forEach((item) => {
    if (item.code !== "MTCE") {
      temp[item.code] = item.name;
    }
  });
  const outdata = indata.map((item) => {
    const obj = {};
    const itemkeys = Object.keys(item);
    itemkeys.forEach((key) => (obj[temp[key]] = item[key]));
    return obj;
  });
  return outdata;
}

//? format individual data for xlsx in json format
function individualData(indata, utilities) {
  const utils = {};
  utilities.forEach((item) => {
    // add all utilities except maintenance
    if (item.code !== "MTCE") {
      utils[item.code + "_DAY"] = item.name;
    }
  });
  const utilKeys = Object.keys(utils);
  const outdata = indata.map((item) =>   {
    const obj = {};
    obj.Period = item.date;
    obj.Block = item.blockId.name;
    obj.Flat = item.flatId.name;
    obj.Reading = 0;
    obj["Remaining Balance"] = item.walletBalance;
    utilKeys.forEach((key) => {
      obj[utils[key]] = item.utility[key];
    });
    return obj;
  });
  return outdata;
}

function individualDataAll(indata, utilities, period) {
  const utils = {};
  const utilsReading = {}
  var indexItem = 1;
  if (period == "Day") {
    indexItem = 0;
  }
  let usageKey;
  if(indexItem == 0){
    usageKey = "_DAY"
  }else{
    usageKey = "_TOTAL"
  }
  utilities.forEach((item) => {
    if (item.code !== "MTCE") {
      utils[item.code + usageKey] = item.name + " " + "Usage";
      utilsReading[item.code + usageKey] = item.name + " " + "Reading";
    }
  });
  const utilKeys = Object.keys(utils);
  const outdata = indata.map((item) =>   {
    const obj = {};
    obj.FromDate = item.usage[0].date;
    obj.ToDate = item.usage[indexItem].date;
    obj.Block = item.block.name;
    obj.Flat = item.flat.name;
    obj["Balance"] = item.usage[indexItem].walletBalance;
    utilKeys.forEach((key) => {
      obj[utils[key]] = item.diffObj[key];
      obj[utilsReading[key]] = item.usage[indexItem].utility[key.split("_")[0] + "_READING"];
    });
    return obj;
  });
  return outdata;
}

/**
 *
 * @param data reports data object with cummulative and individual
 * @param type type of section selected cummulative or individual
 * @param utilities all utilities available
 */

const DownloadData = ({ data, type, period, utilities }) => {
  //? download data as excel file
  const handleDownload = () => {
    const filename = `report ${type} ${moment().format("YYYY/MM/DD")}.xlsx`;
    const ws_name = `${type}`;
    const wb = XLSX.utils.book_new();
    let fileData = [];
    if (type === "cummulative") {
      fileData = cummulativeData(data.cumulative.data, utilities);
    } else if(type === "individual"){
      fileData = individualData(data.individual.data, utilities);
    } else{
      fileData = individualDataAll(data.individualCumulative.data, utilities, period);
    }
    const ws = XLSX.utils.json_to_sheet(fileData);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, filename);
  };

  //? disable download button if selected section data is empty
  const disabled =
    type === "cummulative"
      ? !data.cumulative.data.length
      : type === "individual" ? !data.individual.data.length : !data.individualCumulative.data.length;

  return (
    <Button
      type="primary"
      className="gx-mb-0 gx-ml-auto"
      disabled={disabled}
      onClick={handleDownload}
    >
      Download
    </Button>
  );
};

export default DownloadData;
