import React from "react";
import { connect } from "react-redux";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Divider,
  Card,
  Tag,
  message,
  Select,
  Icon,
} from "antd";

import { get_all_devices_in_use, unmap_project_device } from "../../../../appRedux/actions/Device";
import { fetchStart } from "../../../../appRedux/actions/Common";
import CircularProgress from "components/CircularProgress/index";
const Option = Select.Option;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  state = {
    status: "",
  };
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          dataIndex === "status" ? (
            <div>
              <Select
                style={{ width: 100 }}
                onChange={(value) => {
                  this.setState({ status: value });
                }}
                value={
                  this.state.status !== "" ? this.state.status : record.status
                }
              >
                <Option value={1001}>
                  {
                    <span>
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#52c41a"
                      />
                      Active
                    </span>
                  }
                </Option>
                <Option value={1000}>
                  {
                    <span>
                      <Icon
                        type="close-circle"
                        theme="twoTone"
                        twoToneColor="#eb2f96"
                      />
                      In-Active
                    </span>
                  }
                </Option>
              </Select>
              <Form.Item style={{ margin: 0, display: "none" }}>
                {getFieldDecorator(dataIndex, {
                  initialValue:
                    this.state.status !== "" ? this.state.status : record.value,
                })(this.getInput())}
              </Form.Item>
            </div>
          ) : (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list_devices: [], editingKey: "" };

    this.columns = [
      {
        title: "Serial No.",
        dataIndex: "device_id",
        width: "25%",
      },
      {
        title: "Hardware version",
        dataIndex: "mac_id",
        width: "25%",
      },
      {
        title: "Firmware version",
        dataIndex: "version",
        width: "25%",
      },
      {
        title: "Measurement interval",
        dataIndex: "time_interval",
        width: "25%",
      },
      {
        title: "Utilities",
        dataIndex: "utilityData",
        width: "25%",
        render: value => {
          var val = "";
          value.map(list => {
            if (val != "") {
              val = val + "," + list.name;
            } else {
              val = list.name;
            }
          });
          return <span>{val}</span>;
        }
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "15%",
        editable: true,
        render: (value) => {
          const color = value === 1001 ? "green" : "volcano";
          const name = value === 1001 ? "Active" : "In-Active";
          return <span>{<Tag color={color}>{name}</Tag>}</span>;
        },
      },
      {
        title: "Action",
        dataIndex: "operation",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <a
                    onClick={() => this.save(form, record._id)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record._id)}
              >
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Popconfirm
                title="Sure to Delete?"
                onConfirm={() => this.delete(record)}
              >
                <a>Delete</a>
              </Popconfirm>
            </span>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.props.fetchStart();
    this.props.get_all_devices_in_use();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.list_devices !== nextProps.list_devices) {
      this.setState({ list_devices: nextProps.list_devices });
      return false;
    } else {
      return true;
    }
  }
  isEditing = (record) => record._id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save(form, _id) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex((item) => _id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: "" });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(_id) {
    this.setState({ editingKey: _id });
  }
  delete(record) {
    this.props.unmap_project_device(record)
    const newData = [...this.state.list_devices];
    const index = newData.findIndex((item) => record._id === item._id);
    if (index > -1) {
      newData.splice(index, 1);
      this.setState({ list_devices: newData});
    } 
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };
    const { loading, error_occurred, show_message } = this.props;
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "code" ? "text" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });
    return (
      <div>
        <Card className="gx-card" title="Devices">
          <EditableContext.Provider value={this.props.form}>
            <Table
              components={components}
              bordered
              dataSource={this.state.list_devices}
              columns={columns}
              rowClassName="editable-row"
              pagination={{
                onChange: this.cancel,
              }}
            />
          </EditableContext.Provider>
          <div>
            {loading ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {error_occurred ? message.error(show_message.toString()) : null}
          </div>
        </Card>
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

function mapStateToProps({ common, device }) {
  const { list_devices } = device;
  const { error_occurred, loading, show_message, warning_message } = common;
  return {
    list_devices,
    error_occurred,
    loading,
    show_message,
    warning_message,
  };
}
export default connect(mapStateToProps, { get_all_devices_in_use, unmap_project_device,fetchStart })(
  EditableFormTable
);
