import {
  GET_ALL_BILLS,
  GET_ALL_BILLS_SUCCESS,
  GENERATE_BILLS,
  SEND_BILL_REQUEST,
} from "constants/ActionTypes";

export const get_all_bills = (payload) => {
  return {
    type: GET_ALL_BILLS,
    payload: payload,
  };
};

export const get_all_bills_success = (blocks) => {
  return {
    type: GET_ALL_BILLS_SUCCESS,
    payload: blocks,
  };
};

export const generate_bills = (payload) => {
  return {
    type: GENERATE_BILLS,
    payload: payload,
  };
};

export const send_bills = (payload) => {
  return {
    type: SEND_BILL_REQUEST,
    payload: payload,
  };
};
