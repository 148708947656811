import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "antd";

import { fetchStart } from "../../../../appRedux/actions/Common";
import { get_user_tickets } from "../../../../appRedux/actions/User";

import Dropdown from "./dropdown";

/**
 * @description `This component renders dropdown group for recharge page`
 */

const allType = [{ name: "Active", id: "active" }, {name: "Closed", id: "closed"}];

const DropdownGroup = ({ blocks, flats, common, ...props }) => {
  const [dropdown, setDropdown] = useState(allType[0]);


  useEffect(() => {
    const url = `?status=${1000}`;
    props.get_user_tickets(url);
  }, []);


  const handleSubmit = () => {
    props.fetchStart();
    let status;
     if(dropdown.id == "active"){
        status = 1000;
    }else{
        status = 1001
    }
    const url = `?status=${status}`;
     props.get_user_tickets(url);
  };

  return (
    <div className="gx-d-flex gx-align-items-end">
      <Dropdown
        title="Status"
        data={allType}
        value={dropdown}
        setValue={setDropdown}
      />
      <Button
        type="primary"
        className="gx-mb-0"
        loading={common.loading}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default connect(
  (state) => ({
    common: state.common,
  }),
  {
    get_user_tickets,
    fetchStart,
  }
)(DropdownGroup);
