import {
    GET_ALL_UTILITIES,
    GET_ALL_UTILITIES_SUCCESS,
    GET_FAILED_UTILITES,
    GET_FAILED_UTILITES_SUCCESS
  } from "constants/ActionTypes";
  
  export const get_all_utilities = () => {
    return {
      type: GET_ALL_UTILITIES
    };
  };

  export const get_all_utilities_success = (utilites) => {
    return {
      type: GET_ALL_UTILITIES_SUCCESS,
      payload: utilites
    };
  };

  export const get_failed_utilities = (payload) => {
    return {
      type: GET_FAILED_UTILITES,
      payload:payload
    };
  };

  export const get_failed_utilities_success = (utilites) => {
    return {
      type: GET_FAILED_UTILITES_SUCCESS,
      payload: utilites
    };
  };