import React, { useState } from "react";
import MainDropdownGroup from "./Dropdowns/Main";
import BillsTable from "./BillsTable";

const Bills = () => {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [billsId, setBillsId] = useState([]);
  const [allSelectedBills, setAllSelectedBills] = useState(false);

  return (
    <div>
      <MainDropdownGroup
        page={page}
        rows={rows}
        allSelectedBills={allSelectedBills}
        billsId={billsId}
      />
      <BillsTable
        page={page}
        setPage={setPage}
        rows={rows}
        setRows={setRows}
        billsId={billsId}
        setBillsId={setBillsId}
        allSelectedBills={allSelectedBills}
        setAllSelectedBills={setAllSelectedBills}
      />
    </div>
  );
};

export default Bills;
