import React from "react";
import { connect } from "react-redux";

import { Form, Button, Row, message } from "antd";
import { get_all_blocks } from "../../../../appRedux/actions/Block.js";
import { get_all_flats_block_id } from "../../../../appRedux/actions/Flat";
import { get_all_flat_devices } from "../../../../appRedux/actions/Device";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";
import { fetchStart } from "../../../../appRedux/actions/Common";
import CircularProgress from "components/CircularProgress";
import SelectionFilter from "../dropdown";
import flat from "../../Flat/Flat/flat.js";

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editingKey: "",
      blocks: [],
      flatList: [],
      blockId : "",
      flatId : "",
      utilityFilter: "empty",
      loading: false,
      iconLoading: false,
      customData: false,
      utility: [
        {
          value: true,
          type: "Post-Paid",
          _id: "5e78645c5f20ae41010e71d4",
          name: "Electricity",
          code: "EB",
        },
        {
          value: true,
          type: "Pre-Paid",
          _id: "5e7864c55f20ae41010e71d7",
          name: "Water",
          code: "TWM",
        },
      ],
    };

    this.columns = [
      {
        title: "Period",
        dataIndex: "name",
        width: "25%",
        editable: true,
      },
      {
        title: "Electricity",
        dataIndex: "flat_code",
        width: "15%",
        editable: true,
      },
      {
        title: "Generator",
        dataIndex: "sqft",
        width: "15%",
        editable: true,
      },
      {
        title: "Water",
        dataIndex: "sqft",
        width: "15%",
        editable: true,
      },
    ];
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }
  handleMenuClick(e) {
    message.info("Click on menu item.");
    console.log("click", e);
  }
  enterLoading = () => {
    this.setState({ loading: true });
    if(this.state.flatId !== ""){
      this.setState({ loading: false });
      this.props.get_all_flat_devices(`blockId=${this.state.blockId}&flatId=${this.state.flatId}&utilities=${this.state.utilityFilter}`)
    }else if(this.state.blockId !== ""){
      this.setState({ loading: false });
      this.props.get_all_flat_devices(`blockId=${this.state.blockId}&utilities=${this.state.utilityFilter}`)
    }else{
      this.props.get_all_flat_devices(`utilities=${this.state.utilityFilter}`)
      this.setState({ loading: false });
    }
    
  };

  enterIconLoading = () => {
    this.setState({ iconLoading: true });
  };
  componentDidMount() {
    this.props.fetchStart();
    this.props.get_all_blocks();
    this.props.get_all_utilities();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) {
      this.setState({ blocks: nextProps.blocks });
      return false;
    } else if (this.props.block_flats !== nextProps.block_flats) {
      this.setState({ flatList: nextProps.block_flats });
      return false;
    } else {
      return true;
    }
  }



  handleSelected(val, type) {
    if (type === "block") {
      if (val !== "All") {
        var obj = {
          block_id: val,
        };
        this.props.get_all_flats_block_id(obj);
        this.setState({blockId : val})
      }else{
        this.setState({blockId : ""})
      }  
    }else if(type === "flat"){
      if(val == "All"){
        this.setState({flatId : ""})
      }else{
        this.setState({flatId : val})
      }
    } else if(type === "utility") {
        let joinValue = val.join(",")
        if(joinValue !== "All utilities"){
          this.setState({utilityFilter : joinValue})
        }else{
          this.setState({utilityFilter : "empty"})
        }
    }
  }

  render() {
    const { loading, error_occurred, show_message, utilities } = this.props;
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          <SelectionFilter
            mode="default"
            key="block"
            type={"block"}
            place={"All"}
            data={this.state.blocks}
            selected={this.handleSelected.bind(this)}
          />
          <SelectionFilter
            mode="default"
            key="flat"
            type={"flat"}
            place={"All"}
            data={this.state.flatList}
            selected={this.handleSelected.bind(this)}
          />
          <SelectionFilter
            mode="multiple"
            key="utility"
            type={"utility"}
            place={"All utilities"}
            data={utilities}
            selected={this.handleSelected.bind(this)}
          />
          <Button
            type="primary"
            style={{ marginTop: "28px", marginLeft: "10px" }}
            loading={this.state.loading}
            onClick={this.enterLoading}
          >
            Submit
          </Button>
        </Row>

        <div>
          {loading ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {error_occurred ? message.error("Please select any of value block Name Flat Name") : null}
        </div>
      </div>
    );
  }
}

const FlatList = Form.create()(EditableTable);

function mapStateToProps({ common, Block, flats, utility }) {
  const { blocks } = Block;
  const { block_flats } = flats;
  const { error_occurred, loading, show_message, warning_message } = common;
  const { utilities } = utility;
  return {
    blocks,
    block_flats,
    error_occurred,
    loading,
    show_message,
    warning_message,
    utilities,
  };
}
export default connect(mapStateToProps, {
  get_all_blocks,
  get_all_flats_block_id,
  get_all_flat_devices,
  get_all_utilities,
  fetchStart,
})(FlatList);
