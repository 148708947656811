import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Tag } from "antd";
import { FilePdfFilled } from "@ant-design/icons";
import { fetchStart } from "../../../../appRedux/actions/Common";
import { get_all_blocks } from "../../../../appRedux/actions/Block";
import DownloadDataButton from "./DownloadData";
import {
  get_all_flats,
  get_flats_transactions,
} from "../../../../appRedux/actions/Flat";

import Dropdown from "./dropdown";
import CustomRangePicker from "./CustomRangePicker";
import TransactionsTable from "./Transactions";

const fmt = "YYYY/MM/DD";
const columns = [
  {
    title: "Transaction Date",
    dataIndex: "timestamp",
    width: "20%",
    render: (val) => moment(val).format("YYYY/MM/DD hh:mm A"),
  },
  {
    title: "Transaction Id",
    dataIndex: "transactionId",
    width: "10%",
  },
  {
    title: "Block",
    dataIndex: "blockId.name",
    width: "15%",
  },
  {
    title: "Flat",
    dataIndex: "flatId.name",
    width: "15%",
  },
  {
    title: "Mobile no",
    dataIndex: "userId.ownerMobile",
    width: "15%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: "10%",
  },
  {
    title: "Tranxs Type",
    dataIndex: "type",
    width: "15%",
  },
  {
    title: "Tranxs Mode",
    dataIndex: "transactionType",
    width: "15%",
  },
  {
    title: "Bill Date",
    dataIndex: "billId.billDate",
    width: "15%",
  },
  {
    title: "Status",
    dataIndex: "paymentStatus",
    width: "10%",
    render: (val) =>
      val === 0 ? (
        <Tag color="green">success</Tag>
      ) : (
        <Tag color="red">failed</Tag>
      ),
  },
  {
    title: "PDF",
    dataIndex: "file",
    width: "20%",
    render: (val) => (
      <FilePdfFilled style={{ fontSize: 24, color: "#EA473A" }} />
    ),
  },
];
const dropdownPeriod = [
  { name: "Day", id: "1" },
  { name: "Week", id: "2" },
  { name: "Month", id: "3" },
  { name: "Custom", id: "4" },
];

const dropdownTransType = [
  { name: "All", id: "All" },
  { name: "Recharge", id: "recharge" },
  { name: "Bill", id: "bill" },
];

const dropdownStatus = [
  { name: "All", id: "All" },
  { name: "Success", id: "0" },
  { name: "Failed", id: "1" },
];
const paymentModeData = [
  { name: "All", id: "All" },
  { name: "cash", id: "cash" },
  { name: "online", id: "online" },
];

/**
 * @description `This component renders dropdown group for reports page`
 * @param type type of tab either "cummulative" or "individual"
 * @param data reports data of cummulative and individual
 */

const allType = { name: "All", id: "myall" };

const Paymentgroup = ({ blocks, flats, common, transactions, ...props }) => {
  const [dropdownBlocks, setDropdownBlocks] = useState([allType]);
  const [dropdownFlats, setDropdownFlats] = useState([allType]);

  const [block, setBlock] = useState(dropdownBlocks[0]);
  const [flat, setFlat] = useState(dropdownFlats[0]);
  const [transactionType, setTransactionType] = useState(dropdownTransType[0]);
  const [transactionStatus, setTransactionStatus] = useState(dropdownStatus[0]);
  const [paymentMode, setPaymentMode] = useState(paymentModeData[0]);
  const [period, setPeriod] = useState(dropdownPeriod[0]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    props.get_all_blocks();
    props.get_all_flats();
  }, []);

  useEffect(() => {
    if (block.id === "myall") {
      setDropdownFlats([allType]);
      setFlat(allType);
    } else {
      const temp = [];
      flats.forEach((flat) => {
        if (flat.blockId._id === block.id) {
          temp.push({ name: flat.name, id: flat._id });
        }
      });
      setDropdownFlats([allType, ...temp]);
      setFlat(allType);
    }
  }, [block]);

  useEffect(() => {
    const temp = blocks.map((block) => ({ name: block.name, id: block._id }));
    setDropdownBlocks([allType, ...temp]);
  }, [blocks]);

  const handleSubmit = (val) => {
    val && setPage(1);
    props.fetchStart();
    let blockId = "All";
    if (block.name !== "All") {
      blockId = block.id;
    }
    let flatId = "All";
    if (flat.name !== "All") {
      flatId = flat.id;
    }
    let start = "";
    let end = "";
    if (period.name === "Custom") {
      start = startDate.format(fmt);
      end = endDate.format(fmt);
    }
    const url = `&period=${period.name}&startDate=${start}&endDate=${end}&status=${transactionStatus.id}&type=${transactionType.id}&payment=${paymentMode.id}&pagination=${rows}&page=${page}`;
    props.get_flats_transactions(url);
  };

  useEffect(() => {
    handleSubmit();
  }, [rows, page]);

  return (
    <div>
      <div className="gx-d-flex gx-align-items-end">
        {/* <Dropdown
          title="block"
          width={120}
          data={dropdownBlocks}
          value={block}
          setValue={setBlock}
        />
        <Dropdown
          title="flat"
          data={dropdownFlats}
          value={flat}
          setValue={setFlat}
        /> */}
        <Dropdown
          title="transaction"
          width={120}
          data={dropdownTransType}
          value={transactionType}
          setValue={setTransactionType}
        />
        <Dropdown
          title="status"
          data={dropdownStatus}
          value={transactionStatus}
          setValue={setTransactionStatus}
        />
        <Dropdown
          title="Payment"
          data={paymentModeData}
          value={paymentMode}
          setValue={setPaymentMode}
        />
        <Dropdown
          title="period"
          data={dropdownPeriod}
          value={period}
          setValue={setPeriod}
        />
        {period.name === "Custom" && (
          <CustomRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        )}
        <Button
          type="primary"
          className="gx-mb-0"
          loading={common.loading}
          onClick={() => handleSubmit(true)}
        >
          Submit
        </Button>
        <DownloadDataButton data={transactions} columns={columns} />
      </div>
      <div className="gx-mt-4">
        <TransactionsTable
          page={page}
          setPage={setPage}
          setRows={setRows}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    blocks: state.Block.blocks,
    flats: state.flats.flats_list,
    common: state.common,
    transactions: state.flats.flats_transactions,
  }),
  {
    get_all_blocks,
    get_all_flats,
    fetchStart,
    get_flats_transactions,
  }
)(Paymentgroup);
