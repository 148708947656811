import React from "react";
import { Input, Typography } from "antd";

const { Text } = Typography;


export function Maintenance({ date, editing, setMaintenance, tariffData, values }) {
    const objectList = tariffData.maintenance && tariffData.maintenance.bhkPrice
    return (
        <div>
            <Text strong style={{ margin: "30px 0 10px 0", display: "block" }}>
                Maintenance - {tariffData.maintenance && tariffData.maintenance.maintenance}
            </Text>
            <div style={{ display: "flex", alignItems: "center" }}>
                {tariffData.maintenance && tariffData.maintenance.maintenance == "BHK" ? objectList && Object.keys(values).map((k, i) => (
                    <><span>{k} BHK :&nbsp;</span><Input key={i}
                        style={{ width: 100 }}
                        name={k}
                        value={values[k]}
                        onChange={setMaintenance}
                        disabled={!editing} />&nbsp;</>
                )) :
                    <Input
                        style={{ width: 100 }}
                        disabled={!editing}
                        value={values}
                        onChange={setMaintenance}
                    />
                }
            </div>
        </div>
    );
}
