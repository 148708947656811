import {
  GET_TARIFF,
  GET_TARIFF_SUCCESS,
  UPDATE_TARIFF,
  UPDATE_BILL_DATE_REQUEST,
  UPDATE_BILL_DATE_SUCCESS,
} from "constants/ActionTypes";

export const get_tariff = () => {
  return {
    type: GET_TARIFF,
  };
};

export const get_tariff_success = (payload) => {
  return {
    type: GET_TARIFF_SUCCESS,
    payload: payload,
  };
};

export const update_tariff = (payload) => {
  return {
    type: UPDATE_TARIFF,
    payload: payload,
  };
};

export const updateBillDate = (payload) => {
  return {
    type: UPDATE_BILL_DATE_REQUEST,
    payload,
  };
};

export const updateBillDateSuccess = (payload) => {
  return {
    type: UPDATE_BILL_DATE_SUCCESS,
    payload,
  };
};
