import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  error_occurred: false,
  loading: false,
  show_message: "",
  warning_message: false,
  action_type: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error_occurred: false,
        show_message: "",
        warning_message: false,
        loading: true,
        action_type: "",
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error_occurred: false,
        show_message: action.payload.message,
        warning_message: false,
        loading: false,
        action_type: action.payload.action_type || "",
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error_occurred: false,
        show_message: action.payload,
        warning_message: true,
        loading: false,
        action_type: action.payload.action_type || "",
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error_occurred: true,
        warning_message: false,
        show_message: action.payload.message || "",
        action_type: action.payload.action_type || "",
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error_occurred: false,
        warning_message: false,
        show_message: "",
        action_type: action.payload && action.payload.action_type || "",
      };
    }
    default:
      return state;
  }
};
