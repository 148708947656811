import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_TARIFF,
  UPDATE_BILL_DATE_REQUEST,
  UPDATE_TARIFF,
} from "../../constants/ActionTypes";

import { get_tariff_success, updateBillDateSuccess } from "../actions/Tariff";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";
import axios from "axios";

const fetchTariffData = async () =>
  await axios
    .get(`/tariff/${JSON.parse(localStorage.getItem("projectId"))}`)
    .then((res) => res.data)
    .catch((error) => error.response.data);

const putTariff = async (data) =>
  await axios
    .post(`/edit/tariff`, data)
    .then((res) => res.data)
    .catch((error) => error.response.data);

const updateBillDateRequest = async (payload) =>
  await axios
    .post("/edit/bill", payload)
    .then((res) => res.data)
    .catch((error) => error.response.data);

function* getTariffData() {
  try {
    const data = yield call(fetchTariffData);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess());
      yield put(get_tariff_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* updateTariff({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(putTariff, payload);
    if (data.status === "error") {
      if (data.statusCode == 401) {
        var action_type = "tariff_error";
        let message = "Password incorrect, Please enter correct password"
        yield put(fetchError({ message, action_type }));
      } else {
        yield put(fetchError(data.message));
      }
    } else {
      var message = "Successfully updated tariff price.";
      var action_type = "tariff_success";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* postBillDate({ payload }) {
  try {
    const data = yield call(updateBillDateRequest, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(updateBillDateSuccess(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* getProjectTariffData() {
  yield takeEvery(GET_TARIFF, getTariffData);
}
export function* updateTariffData() {
  yield takeEvery(UPDATE_TARIFF, updateTariff);
}

export function* updateBillDate() {
  yield takeEvery(UPDATE_BILL_DATE_REQUEST, postBillDate);
}

export default function* rootSaga() {
  yield all([fork(getProjectTariffData), fork(updateTariffData)]);
}
