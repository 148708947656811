import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
const columnArr = [
  {
    title: "Period",
    dataIndex: "date",
    width: "25%",
    render: (value) => <span>{moment(value).format("DD-MM-YYYY")}</span>,
  },
  {
    title: "Block",
    dataIndex: "blockId.name",
    width: "15%",
  },
  {
    title: "Flat",
    dataIndex: "flatId.name",
    width: "15%",
  },
  {
    title: "Remaining balance",
    dataIndex: "walletBalance",
    width: "10%",
    sorter: (a, b) => 
    a['walletBalance'] -
    b['walletBalance'],
    render: (value) => {
      if (typeof value === "number") {
        return Number(value).toFixed(2);
      }
    } 
  },
];

/**
 * @description `This component renders Individual reports data table`
 * @param data individual reports data
 * @param utilities available utilities is used to create data source for table
 */

const Individual = ({ data, utilities, period, ...props }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    var setPeriodType;
    var indexItem = 1;
    if (period.name == "Day") {
      setPeriodType = "_DAY";
      indexItem = 0;
    } else if (period.name == "Week") {
      setPeriodType = "_WEEKLY";
    } else if (period.name == "Month") {
      setPeriodType = "_MONTHLY";
    } else if (period.name == "Custom") {
      setPeriodType = "_YEARLY";
    }
    //? map over utilities to create columns for table source
    if (data.data && data.data.length && utilities.length) {
      const temp = [];
      let usageKey;
      if(indexItem == 0){
        usageKey = "_DAY"
      }else{
        usageKey = "_TOTAL"
      }
  
      utilities.forEach((util) => {
        // add all utilities except maintenance
        if (util.code !== "MTCE") {
          // temp.push({
          //   title: util.name,
          //   dataIndex: "utility." + util.code + "_DAY",
          //   width: "15%",
          //   sorter: (a, b) =>
          //     a["utility"][util.code + "_DAY"] -
          //     b["utility"][util.code + "_DAY"],
          //   render: (value) => {
          //     if (typeof value === "number") {
          //       return Number(value).toFixed(2);
          //     }
          //   },
          // });
          let obj = {
            title: util.name,
          }
          obj.children = [
            {
              title: 'Usage',
              dataIndex: `utility.${util.code}${usageKey}`,
              width: "20%",
              sorter: (a, b) => 
              a["utility"][util.code + usageKey] -
              b["utility"][util.code + usageKey],
              render: (value) => {
                if (typeof value === "number") {
                  return Number(value).toFixed(2);
                }
              }  
            },
            {
              title: 'Reading',
              dataIndex:  `utility.${util.code}${"_READING"}`,
              width: "20%",
              sorter: (a, b) => 
              a["utility"][util.code + "_READING"] -
              b["utility"][util.code + "_READING"],
            render: (value) => {
              if (typeof value === "number") {
                return Number(value).toFixed(2);
              }
            },
            }
          ]
          temp.push(obj);
        } else {
          temp.push({
            title: "View Details",
            dataIndex: "_id",
            width: "20%",
            render: (value) => {
              const basepath = "readings/details?";
              const flat = data.data.find((item) => item._id === value);
              const pid = flat && flat.projectId ? flat.projectId : null;
              const bid = flat && flat.blockId ? flat.blockId._id : null;
              const fid = flat && flat.flatId ? flat.flatId._id : null;
              const date = flat && flat.date ? flat.date : null;
              const routePath =
                basepath +
                `projectId=${pid}&date=${date}&blockId=${bid}&flatId=${fid}`;

              return <Link to={routePath}>View Details</Link>;
            },
          });
        }
      });
      setColumns([...columnArr, ...temp]);
    }
  }, [data, utilities]);

  return (
    <div>
      <Table
        bordered
        dataSource={data.data}
        columns={columns}
        rowKey="_id"
        pagination={true}
      />
      <div>
        {/* {error_occurred ? message.error(show_message.toString()) : null} */}
      </div>
    </div>
  );
};

export default Individual;
