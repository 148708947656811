import React from "react";
import { connect } from "react-redux";

import {
  Table,
  Input,
  InputNumber,
  Select,
  Popconfirm,
  Form,
  Divider,
  Card,
  Icon,
  message,
  Tag,
} from "antd";
import { fetchStart } from "../../../../appRedux/actions/Common";
import { get_all_flats, update_flat } from "../../../../appRedux/actions/Flat";
import CircularProgress from "components/CircularProgress/index";

const Option = Select.Option;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  state = {
    status: "",
  };
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          dataIndex === "status" ? (
            <div>
              <Select
                style={{ width: 100 }}
                onChange={(value) => {
                  this.setState({ status: value });
                }}
                value={
                  this.state.status !== "" ? this.state.status : record.status
                }
              >
                <Option value={1001}>
                  {
                    <span>
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#52c41a"
                      />
                      Active
                    </span>
                  }
                </Option>
                <Option value={1000}>
                  {
                    <span>
                      <Icon
                        type="close-circle"
                        theme="twoTone"
                        twoToneColor="#eb2f96"
                      />
                      In-Active
                    </span>
                  }
                </Option>
              </Select>
              <Form.Item style={{ margin: 0, display: "none" }}>
                {getFieldDecorator(dataIndex, {
                  initialValue:
                    this.state.status !== ""
                      ? this.state.status
                      : record.status,
                })(this.getInput())}
              </Form.Item>
            </div>
          ) : (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], editingKey: "" };

    this.columns = [
      {
        title: "Flat Name",
        dataIndex: "name",
        width: "25%",
        editable: true,
      },
      {
        title: "Block Name",
        dataIndex: "blockId.name",
        width: "15%",
      },
      {
        title: "Square Feet",
        dataIndex: "sft",
        width: "15%",
        editable: true,
      },
      {
        title: "BHK",
        dataIndex: "bhk",
        width: "15%",
        editable: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        _id: "status",
        width: "15%",
        editable: true,
        render: (value) => {
          const color = value === 1001 ? "green" : "volcano";
          const name = value === 1001 ? "Active" : "In-Active";
          return <span>{<Tag color={color}>{name}</Tag>}</span>;
        },
      },
      {
        title: "Action",
        dataIndex: "mode",
        render: (value, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <a
                    onClick={() => this.save(form, record._id)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record._id)}
              >
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <a
                disabled={value === "modbus-wired" ? true : editingKey !== ""}
                onClick={() => this.edit(record._id)}
              >
                Edit
              </a>
              <Divider type="vertical" />
              <Popconfirm
                title="Sure to Delete?"
                onConfirm={() => this.delete(record._id)}
              >
                <a
                  disabled={value === "modbus-wired" ? true : editingKey !== ""}
                >
                  Delete
                </a>
              </Popconfirm>
            </span>
          );
        },
      },
    ];
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }
  handleMenuClick(e) {
    message.info("Click on menu item.");
    console.log("click", e);
  }

  componentDidMount() {
    this.props.fetchStart();
    this.props.get_all_flats();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.flats_list != nextProps.flats_list) {
      this.setState({ data: nextProps.flats_list });
      return false;
    } else {
      return true;
    }
  }
  isEditing = (record) => record._id === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  save(form, _id) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];

      const index = newData.findIndex((item) => _id === item._id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.props.update_flat(newData[index]);
        this.setState({ data: newData, editingKey: "" });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: "" });
      }
    });
  }

  edit(_id) {
    this.setState({ editingKey: _id });
  }
  delete(_id) {
    console.log("checking the delete method@@@", _id);
  }

  render() {
    const { loading, error_occurred, show_message, action_type } = this.props;
    const components = {
      body: {
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });
    return (
      <div>
        <Card className="gx-card" title="Flats">
          <EditableContext.Provider value={this.props.form}>
            <Table
              components={components}
              bordered
              dataSource={this.state.data}
              columns={columns}
              rowClassName="editable-row"
              pagination={{
                onChange: this.cancel,
              }}
            />
          </EditableContext.Provider>
          <div>
            {loading ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {error_occurred ? message.error(show_message.toString()) : null}
          </div>
        </Card>
        <div>
            {action_type == "new_flat_success"
              ? message.success("Successfully created new flat")
              : null}
          </div>
      </div>
    );
  }
}

const FlatList = Form.create()(EditableTable);

function mapStateToProps({ common, flats }) {
  const { flats_list } = flats;
  const { error_occurred, loading, show_message, warning_message, action_type } = common;
  return { flats_list, error_occurred, loading, show_message, warning_message, action_type };
}
export default connect(mapStateToProps, {
  get_all_flats,
  fetchStart,
  update_flat,
})(FlatList);
