import React, {Component} from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { connect } from "react-redux";

import { hideMessage, showAuthLoader, userChangePassword,hideAuthLoader } from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;


class ChangePassword extends Component {


  componentDidUpdate() {
   
    setTimeout(() => {
        this.props.hideAuthLoader();
    }, 100);
    
    if (this.props.authUser == null) {
      this.props.history.push("/");
    }
  };

  handleChangePasswordSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.showAuthLoader();
        this.props.userChangePassword(values);
      }
    });
  };

  // handleChangeTransactionPasswordSubmit = (e) => {
  //   e.preventDefault();
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       console.log('Received values of form: ', values);
  //       //this.props.showAuthLoader();
  //       //this.props.userSignIn(values);
  //     }
  //   });
  // };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  validateToNextTransactionPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm_transaction'], {force: true});
    }
    callback();
  };

  compareToFirstTransactionPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password_transaction')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };


  render() {
    const {getFieldDecorator} = this.props.form;
    const { showMessage, loader, alertMessage } = this.props;

    return (
      <div className="gx-login-container">
        <div className="gx-login-content">

          <div className="gx-login-header">
            {/* <img src={require("assets/images/logo-white.png")} alt="wieldy" title="wieldy"/> */}
            <img src="https://ycitysmartmeter.s3.ap-south-1.amazonaws.com/YCity+Logo.png" />
          </div>
          <div className="gx-mb-4">
            <h2>Change Password</h2>
            <p><IntlMessages id="appModule.enterPasswordReset"/></p>
          </div>

          <Form onSubmit={this.handleChangePasswordSubmit} className="gx-login-form gx-form-row0">

            <FormItem>
              {getFieldDecorator('password', {
                rules: [{
                  required: true, message: 'Please input your password!',
                }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input type="password" placeholder="New Password"/>
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('confirm', {
                rules: [{
                  required: true, message: 'Please confirm your password!',
                }, {
                  validator: this.compareToFirstPassword,
                }],
              })(
                <Input placeholder="Retype New Password" type="password" onBlur={this.handleConfirmBlur}/>
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
                <IntlMessages id="app.userAuth.change"/>
              </Button>
            </FormItem>
          </Form>

        </div>
        
        {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {showMessage ? message.error(alertMessage.toString()) : null}
      </div>
    );
  }
}

const WrappedChangePasswordForm = Form.create()(ChangePassword);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userChangePassword,
  hideMessage,
  showAuthLoader,
  hideAuthLoader
})(WrappedChangePasswordForm);

