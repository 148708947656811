import React from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import BlockList from "./BlockList/BlockList";
class Blocks extends React.Component {
  handleClick = this.handleClick.bind(this);
  handleClick() {
    this.props.history.push("/blocks/addblock");
  }
  render() {
    var projectType = ""; 
    if (this.props.userData) {
      projectType = this.props.userData.projectMode;
    }
    return (
      <div>
        {projectType === "lora" && (
          <Button
            type="primary"
            onClick={this.handleClick}
            style={{ float: "right", "z-index": "1", top: "10px" }}
          >
            Add New Block
          </Button>
        )}
        <div>
          <BlockList />
        </div>
      </div>
    );
  }
}
function mapStateToProps({ auth }) {
  const { userData, authUser } = auth;
  return { userData, authUser };
}
export default connect(mapStateToProps)(Blocks);
