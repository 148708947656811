import {
  CUMULATIVE_REPORT,
  CUMULATIVE_REPORT_SUCCESS,
  INDIVIDUAL_REPORT,
  INDIVIDUAL_REPORT_SUCCESS,
  CUMULATIVE_DETAIL_REPORT,
  CUMULATIVE_DETAIL_REPORT_SUCCESS,
  CUMULATIVE_WEEK_REPORT,
  CUMULATIVE_WEEK_REPORT_SUCCESS,
  INDIVIDUAL_BLOCK_REPORT,
  INDIVIDUAL_BLOCK_REPORT_SUCCESS,
} from "constants/ActionTypes";

export const cumulative_report = (payload) => {
  return {
    type: CUMULATIVE_REPORT,
    payload: payload,
  };
};

export const cumulative_report_success = (payload) => {
  return {
    type: CUMULATIVE_REPORT_SUCCESS,
    payload: payload,
  };
};

export const cumulative_detail_report = (payload) => {
  return {
    type: CUMULATIVE_DETAIL_REPORT,
    payload: payload,
  };
};

export const cumulative_detail_report_success = (payload) => {
  return {
    type: CUMULATIVE_DETAIL_REPORT_SUCCESS,
    payload: payload,
  };
};

export const cumulative_week_report = (payload) => {
  return {
    type: CUMULATIVE_WEEK_REPORT,
    payload: payload,
  };
};

export const cumulative_week_report_success = (payload) => {
  return {
    type: CUMULATIVE_WEEK_REPORT_SUCCESS,
    payload: payload,
  };
};

export const individual_report = (payload) => {
  return {
    type: INDIVIDUAL_REPORT,
    payload: payload,
  };
};
export const individual_report_success = (payload) => {
  return {
    type: INDIVIDUAL_REPORT_SUCCESS,
    payload: payload,
  };
};

export const individual_block_report = (payload) => {
  return {
    type: INDIVIDUAL_BLOCK_REPORT,
    payload: payload,
  };
};

export const individual_block_report_success = (payload) => {
  return {
    type: INDIVIDUAL_BLOCK_REPORT_SUCCESS,
    payload: payload,
  };
};
