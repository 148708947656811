import React from "react";
import { Select, Typography } from "antd";

const { Text } = Typography;

export const BILL_DATES = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
];

export function BillDate({ date, editing, setDate }) {
  return (
    <div>
      <Text strong style={{ margin: "30px 0 10px 0", display: "block" }}>
        Bill Generation Date
      </Text>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          value={date}
          style={{ width: 100 }}
          onChange={setDate}
          disabled={!editing}
        >
          {BILL_DATES.map((date) => (
            <Select.Option value={date} key={date}>
              {date}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}
