import React, { Component } from "react";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Tooltip,
  message,
} from "antd";
import { connect } from "react-redux";
import { fetchStart } from "../../../../appRedux/actions/Common";
import { post_block, get_all_blocks } from "../../../../appRedux/actions/Block.js";
import CircularProgress from "components/CircularProgress/index";
const FormItem = Form.Item;
class Registration extends Component {
  page_name = "Create Block";
  state = {
    checked: true,
  };

  onChange = (e) => {
    console.log("checked = ", e.target.checked);
    this.setState({
      checked: e.target.checked,
    });
  };

  componentDidMount() {}

  handleSubmit = (e) => {
    this.props.fetchStart();
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var status = this.state.checked;
        if (status) values.status = 1001;
        else values.status = 1000;  
        this.props.post_block(values);
        setTimeout(() => {
          this.props.get_all_blocks();
          this.props.history.push(`/blocks`);
        }, 0);
      }
    });
  };

  render() {
    const { loading, error_occurred, show_message } = this.props;
    const label = `${this.state.checked ? "Active" : "In-Active"}`;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div>
      <Card className="gx-card" title={this.page_name}>
        <div>
          {loading ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {error_occurred ? message.error(show_message.toString()) : null}
        </div>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Name&nbsp;
                <Tooltip title="This is block name">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input your Block Name!",
                  whitespace: true,
                },
              ],
            })(
              <AutoComplete placeholder="Block Name">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={<span>Block Code</span>}>
            {getFieldDecorator(
              "block_code",
              {}
            )(
              <AutoComplete placeholder="Block Code">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <Col xs={{ span: 26 }} lg={{ span: 12, offset: 8 }}>
            <div>
              <p style={{ marginBottom: "20px" }}>
                <Checkbox
                  checked={this.state.checked}
                  disabled={this.state.disabled}
                  onChange={this.onChange}
                >
                  {label}
                </Checkbox>
              </p>
            </div>
          </Col>

          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </FormItem>
        </Form>
      </Card>
      </div>
    );
  }
}

const RegistrationForm = Form.create()(Registration);

function mapStateToProps({ common }) {
  return common;
}
export default connect(mapStateToProps, { fetchStart, post_block, get_all_blocks })(
  RegistrationForm
);
