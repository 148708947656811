import React from "react";
import { Select } from "antd";

const Option = Select.Option;

class SearchField extends React.Component {
  state = {
    value: "",
  };
  handleChange = (value) => {
    let getType = this.props.type;
    this.props.selected(value, getType);
    this.setState({ value: value });
  };
  render() {
    let combined_arr;
    let obj;
    obj = [
      {
        device_id : "All",
        _id: "All",
      },
    ];
    combined_arr = obj.concat(this.props.data);

    return (
      <div>
        <div style={{ marginLeft: "10px" }}>
          {"Select "}
          {this.props.type}
        </div>
        <div>
          <Select
            showSearch
            style={{ width: 200, margin: "10px" }}
            optionFilterProp="children"
            onChange={this.handleChange}
            value={
              this.state.value !== "" ? this.state.value : this.props.place
            }
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {combined_arr.map((list) => {
              return (
                <Option value={list.device_id} key={list._id}>
                  {list.device_id == "All" ? "All" : list.device_id + " - " + list.mac_id}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
}

export default SearchField;
