import React from "react";
import { connect } from "react-redux";
import { Table } from "antd";

const TransactionsTable = ({ transactions, ...props }) => {
  return (
    <Table
      columns={props.columns}
      dataSource={transactions.data}
      rowKey="_id"
      bordered
      pagination={{
        total: transactions.count,
        current: props.page,
        onChange: (val) => props.setPage(val),
        showSizeChanger: true,
        onShowSizeChange: (cur, pageSize) => props.setRows(pageSize),
      }}
    />
  );
};

export default connect((state) => ({
  transactions: state.flats.flats_transactions,
}))(TransactionsTable);
