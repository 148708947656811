import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import { connect } from "react-redux";
import Moment from "moment";
import { fetchStart, fetchSuccess } from "../../../../appRedux/actions/Common";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";
import { get_tariff, update_tariff } from "../../../../appRedux/actions/Tariff";
import { Maintenance } from "./Maintenance";
import { BILL_DATES, BillDate } from "./billDate";

const rowStyles = {
  marginTop: 10,
};

const labelStyles = {
  width: 150,
  display: "inline-block",
};

const inputStyles = {
  marginLeft: 16,
  borderRadius: 4,
  padding: 6,
  border: "1px solid #d9d9d9",
  outline: 0,
};

const groupStyles = {
  marginTop: 20,
};

const NestedTable = (props) => {
  const [tariffs, setTariffs] = useState(props.tariffs);
  const [maintenancePrice, setMaintenancePrice] = useState({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [individualPrice, setIndividualPrice] = useState(true)
  const [editing, setEditing] = useState(false);
  const [password, setPassword] = useState("");
  const [date, setDate] = useState(BILL_DATES[5]);
  useEffect(() => {
    if (props.tariffData && props.tariffData.maintenance) {
      setMaintenancePrice(props.tariffData.maintenance.bhkPrice);
    }
  }, [props.tariffData]);
  const handleChange = (e) => {
    setErrorMessage(false)
    const [utiTitle, rangeTitle] = e.target.name.split(":");

    if (utiTitle && rangeTitle) {
      const tempState = [...tariffs].map((item) => {
        if (item.name === utiTitle) {
          let temp = { ...item };
          temp.range = item.range.map((x) => {
            if (x.title === rangeTitle) {
              return {
                ...x,
                price: e.target.value,
              };
            }
            return x;
          });
          return temp;
        }
        return item;
      });
      setTariffs(tempState);
    } else {
      alert("error in form");
    }
  };
  const changeMaintenance = (e) => {
    setErrorMessage(false)
    var getCurrentValues = maintenancePrice;
    getCurrentValues[e.target.name] = e.target.value
    setIndividualPrice(current => !current)
    setMaintenancePrice(getCurrentValues)
  }

  const handleSubmit = (e) => {
    if (password == "" && editing) {
      setEditing(true);
      setErrorMessage(true)
      return;
    }
    if (!editing) {
      setEditing(true);
      return;
    }
    setEditing(false);
    const payload = {
      multiTariff: tariffs,
      isMultiTariff: true,
      billDate: date,
      password,
      maintenancePrice
    };
    console.log(
      "🚀 ~ file: NewTariff.js ~ line 63 ~ handleChange ~ payload",
      payload
    );

    //props.update_tariff(payload);
  };

  return (
    <div>
      {tariffs.map((item) => (
        <div style={groupStyles}>
          <h5 style={{ fontWeight: 700, fontSize: 18 }}>{item.name}</h5>
          {item.range.map((ran) => (
            <div style={rowStyles}>
              <label
                style={labelStyles}
                htmlFor={`${item.name + ":" + ran.title}`}
              >
                {ran.title} units
              </label>
              <input
                disabled={!editing}
                style={inputStyles}
                value={ran.price}
                onChange={handleChange}
                type="text"
                id={`${item.name + ":" + ran.title}`}
                name={`${item.name + ":" + ran.title}`}
              />
            </div>
          ))}
        </div>
      ))}
      <BillDate date={date} editing={editing} setDate={setDate} />
      <Maintenance
        editing={editing}
        tariffData={props.tariffData}
        values={maintenancePrice}
        setMaintenance={changeMaintenance} />
      {editing ? (
        <div style={rowStyles}>
          <label style={labelStyles} htmlFor="password">
            Confirm Password
          </label>
          <input
            style={inputStyles}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            id="password"
            name="password"
          />
        </div>
      ) : null}
      <Button
        onClick={handleSubmit}
        style={{ marginTop: 16, padding: "0 20px" }}
        type="primary"
      >
        {editing ? "Save" : "Edit"}
      </Button>
      {errorMessage
        ? message.error("Please enter password to save the details")
        : null}
    </div>
  );
};

function mapStateToProps({ common, utility, tariff }) {
  const { utilities } = utility;
  const { tariffPrice } = tariff;
  const { error_occurred, loading, show_message, warning_message } = common;
  return {
    error_occurred,
    loading,
    show_message,
    warning_message,
    utilities,
    tariffPrice,
  };
}

export default connect(mapStateToProps, {
  fetchStart,
  fetchSuccess,
  get_all_utilities,
  get_tariff,
  update_tariff,
})(NestedTable);
