import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";

/**
 * @description `This component renders Individual reports data table`
 * @param data individual reports data
 * @param utilities available utilities is used to create data source for table
 */

const Individual = ({ data, utilities, period, ...props }) => {
  const [columns, setColumns] = useState([]);
  
  useEffect(() => {
    var setPeriodType;
    var indexItem = 1;
    if (period.name == "Day") {
      setPeriodType = "_DAY";
      indexItem = 0;
    } else if (period.name == "Week") {
      setPeriodType = "_WEEKLY";
    } else if (period.name == "Month") {
      setPeriodType = "_MONTHLY";
    } else if (period.name == "Custom") {
      setPeriodType = "_YEARLY";
    }

    const headerColumns = [
      {
        title: "From Date",
        dataIndex: `usage[0].date`,
        width: "25%",
        render: (value) => (
          <span>
            {moment(value).add(1, 'days')
              .format("DD-MM-YYYY")}
          </span>
        ),
      },
      {
        title: "To Date",
        dataIndex: `usage[${indexItem}].date`,
        width: "25%",
        render: (value) => (
          <span>
            {moment(value)
              .format("DD-MM-YYYY")}
          </span>
        ),
      },
      {
        title: "Block",
        dataIndex: "block.name",
        width: "20%",
      },
      {
        title: "Flat",
        dataIndex: "flat.name",
        width: "20%",
        sorter: (a, b) => 
        a.flat.name.length -
        b.flat.name.length,
        // (a['flat.name'] > b['flat.name']) ? 1 : ((b['flat.name'] > a['flat.name']) ? -1 : 0)
      },
      {
        title: "Balance",
        dataIndex: `usage[${indexItem}].walletBalance`,
        width: "20%",
        sorter: (a, b) => 
        a['usage'][`${indexItem}`].walletBalance -
        b['usage'][`${indexItem}`].walletBalance,
        render: (value) => {
          if (typeof value === "number") {
            return Number(value).toFixed(2);
          }
        } 
      },
    ]
    if (data.data && data.data.length && utilities.length) {
      const temp = [];
      let usageKey;
      if(indexItem == 0){
        usageKey = "_DAY"
      }else{
        usageKey = "_TOTAL"
      }
      utilities.forEach((util) => {
        // add all utilities except maintenance
        if (util.code !== "MTCE") {
          let obj = {
            title: util.name,
          }
          obj.children = [
            {
              title: 'Usage',
              dataIndex: `diffObj.${util.code}${usageKey}`,
              width: "20%",
              sorter: (a, b) => 
              a["diffObj"][util.code + usageKey] -
              b["diffObj"][util.code + usageKey],
              render: (value) => {
                if (typeof value === "number") {
                  return Number(value).toFixed(2);
                }
              }  
            },
            {
              title: 'Reading',
              dataIndex: `usage[${indexItem}].utility.${util.code}${"_READING"}`,
              width: "20%",
              sorter: (a, b) => 
              a["usage"][`${indexItem}`].utility[`${util.code}_READING`] -
              b["usage"][`${indexItem}`].utility[`${util.code}_READING`],
            render: (value) => {
              if (typeof value === "number") {
                return Number(value).toFixed(2);
              }
            },
            }
          ]
          temp.push(obj);
        }
      });
      setColumns([...headerColumns, ...temp]);
    }
  }, [data, utilities, period]);

  return (
    <div>
      <Table
        bordered
        scroll={{ x: 'calc(700px + 50%)'}}
        dataSource={data.data}
        columns={columns}
        rowKey="_id"
        pagination={true}
      />
      <div>
        {/* {error_occurred ? message.error(show_message.toString()) : null} */}
      </div>
    </div>
  );
};

export default Individual;
