import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_DEVICES,
  MAP_DEVICE_DATA,
  GET_ALL_DEVICES_IN_USE,
  GET_FLAT_DEVICE_READING,
  RELAY_DEVICE,
  UNMAP_PROJECT_DEVICE,
  GET_ALL_FLAT_DEVICES,
  EDIT_DEVICE,
  RESET_METER_DEVICE
} from "../../constants/ActionTypes";

import {
  get_all_devices_success,
  get_all_devices_in_use_success,
  get_flat_device_readings_success,
  get_all_flat_devices_success
} from "../actions/Device";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";
import axios from "axios";

const fetchDevices = async () =>
  await axios
    .get("/device?projectId=" + JSON.parse(localStorage.getItem("projectId")))
    .then((res) => res.data)
    .catch((error) => error.response);

const fetchFlatDevices = async (payload) =>
  await axios
    .get("/project/flat/device/" + JSON.parse(localStorage.getItem("projectId")) +'?'+payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const updateDevice = async (payload) =>
  await axios
    .post("/edit/device/autooff", payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const resetMeterData = async (payload) =>
  await axios
    .post("/reset/meter/values", payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const postDevicesData = async (payload) =>
  await axios
    .post("/mapDevice", payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const inUseDevices = async (payload) =>
  await axios
    .get("/devices/in/use?projectId=" + JSON.parse(localStorage.getItem("projectId"))+'&'+payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const postRelay = async (payload) => {
  return await axios
    .post(
      "/device/relay", payload
    )
    .then((res) => res.data)
    .catch((error) => error.response);
};

const projectUnmap = async (payload) => {
  return await axios
    .post(
      "/project/device/unmap", payload
    )
    .then((res) => res.data)
    .catch((error) => error.response);
};

const fetchFlatDeviceReading = async (payload) => {
  const { blockId, flatId, projectId, date } = payload;
  return await axios
    .get(
      `/device/reports?projectId=${projectId}&date=${date}&blockId=${blockId}&flatId=${flatId}`
    )
    .then((res) => res.data)
    .catch((error) => error.response);
};

function* getalldevices({ payload }) {
  try {
    const data = yield call(fetchDevices, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_all_devices_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* mapthedevices({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(postDevicesData, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* useindevices({ payload }) {
  try {
    const data = yield call(inUseDevices, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_all_devices_in_use_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* flatDeviceReadings({ payload }) {
  try {
    const data = yield call(fetchFlatDeviceReading, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_flat_device_readings_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* deviceOnAndOff({ payload }) {
  try {
    const data = yield call(postRelay, payload);
    if (data.data.status === "error") {
      var message = data.data.message;
      var action_type = "error_relay_device";
      yield put(fetchSuccess({ message, action_type }));
    } else {
      var message = data.message;
      var action_type = "relay_device";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* unMapProjectDevice({ payload }) {
  try {
    const data = yield call(projectUnmap, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "unmap_device";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* deviceFlatReadingsFun({ payload }) {
  try {
    const data = yield call(fetchFlatDevices, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      //var action_type = "";
      yield put(fetchSuccess({ message }));
      yield put(get_all_flat_devices_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* editDeviceData({ payload }) {
  try {
    const data = yield call(updateDevice, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "auto_off";
      yield put(fetchSuccess({ message, action_type }))
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* resetMeterValue({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(resetMeterData, payload);
    if (data.data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "reset_meter";
      yield put(fetchSuccess({ message, action_type }))
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
export function* getAllDevices() {
  yield takeEvery(GET_ALL_DEVICES, getalldevices);
}

export function* mapdevice() {
  yield takeEvery(MAP_DEVICE_DATA, mapthedevices);
}
export function* usedevices() {
  yield takeEvery(GET_ALL_DEVICES_IN_USE, useindevices);
}

export function* getFlatDeviceReading() {
  yield takeEvery(GET_FLAT_DEVICE_READING, flatDeviceReadings);
}

export function* relayDevice() {
  yield takeEvery(RELAY_DEVICE, deviceOnAndOff);
}

export function* unmapDevice() {
  yield takeEvery(UNMAP_PROJECT_DEVICE, unMapProjectDevice)
}

export function* deviceFlatReadings() {
  yield takeEvery(GET_ALL_FLAT_DEVICES, deviceFlatReadingsFun)
}
export function* editDevice() {
  yield takeEvery(EDIT_DEVICE, editDeviceData)
}

export function* resetMeterDevice() {
  yield takeEvery(RESET_METER_DEVICE, resetMeterValue)
}


export default function* rootSaga() {
  yield all([
    fork(getAllDevices),
    fork(mapdevice),
    fork(usedevices),
    fork(getFlatDeviceReading),
    fork(relayDevice),
    fork(unmapDevice),
    fork(deviceFlatReadings),
    fork(editDevice),
    fork(resetMeterDevice)
  ]);
}
