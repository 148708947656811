import React, { Component } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    var projectName = "";
    var projectType = "";
    var failedMeter = false;
    if (this.props.userData) {
      projectName = this.props.userData.name;
      projectType = this.props.userData.projectMode;
      failedMeter = this.props.userData.failedMeter;
    }

    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1).split("/")[0];
    const defaultOpenKeys = selectedKeys;
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile projectName={projectName} />
            {/* <AppsNavigation /> */}
            {/* <AppsNavigation /> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              <MenuItemGroup
                key="main"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.main" />}
              >
                <SubMenu
                  key="dashboard"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      {" "}
                      <i className="icon icon-dasbhoard" />
                      <IntlMessages id="sidebar.dashboard" />
                    </span>
                  }
                >
                  <Menu.Item key="total">
                    <Link to="/total">
                      <i className="icon icon-dasbhoard" />
                      <IntlMessages id="sidebar.total" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="individual">
                    <Link to="/individual">
                      <i className="icon icon-crm" />
                      <IntlMessages id="sidebar.individual" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </MenuItemGroup>
              <MenuItemGroup
                key="analytics"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.analytics" />}
              >
                <Menu.Item key="reports">
                  <Link to="/reports">
                    <i className="icon icon-data-display" />
                    <IntlMessages id="sidebar.report" />
                  </Link>
                </Menu.Item>
                {projectType === "lora" && (
                  <Menu.Item key={"readings"}>
                    <Link to="/readings">
                      <i className="icon icon-product-grid" />
                      <IntlMessages id="sidebar.reading" />
                    </Link>
                  </Menu.Item>
                )}
                {projectType === "lora" && (
                  <Menu.Item key={"flatreadings"}>
                    <Link to="/flatreadings">
                      <i className="icon icon-product-list" />
                      <IntlMessages id="sidebar.flatreading" />
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="bills">
                  <Link to="/bills">
                    <i className="icon icon-pricing-table" />
                    <IntlMessages id="sidebar.bills" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="recharge">
                  <Link to="/recharge">
                    <i className="icon icon-wysiwyg" />
                    <IntlMessages id="sidebar.recharge" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="tariff">
                  <Link to="/tariff">
                    <i className="icon icon-revenue-new" />
                    <IntlMessages id="sidebar.tariff" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="transactions">
                  <Link to="/transactions">
                    <i className="icon icon-table-data" />
                    Transactions
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup
                key="masters"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.master" />}
              >
                <Menu.Item key="blocks">
                  <Link to="/blocks">
                    <i className="icon icon-company" />
                    <IntlMessages id="sidebar.block" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="flats">
                  <Link to="/flats">
                    <i className="icon icon-apps" />
                    <IntlMessages id="sidebar.flat" />
                  </Link>
                </Menu.Item>
                {projectType === "lora" && (
                  <Menu.Item key="devices">
                    <Link to="/devices">
                      <i className="icon icon-anchor" />
                      <IntlMessages id="sidebar.device" />
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="users">
                  <Link to="/users">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.user" />
                  </Link>
                </Menu.Item>
                {failedMeter === true && (
                <Menu.Item key="failedmeters">
                  <Link to="/failedmeters">
                    <i className="icon icon-wysiwyg" />
                    FailedMeters
                  </Link>
                </Menu.Item>
                )}
                <Menu.Item key="support">
                  <Link to="/support">
                    <i className="icon icon-apps" />
                    Support
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              {/*  <MenuItemGroup
                key="components"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.community" />}
              >
                <Menu.Item key="visitor">
                  <Link to="">
                    <i className="icon icon-visits" />
                    <IntlMessages id="sidebar.visitor" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="helpdesk">
                  <Link to="">
                    <i className="icon icon-tickets" />
                    <IntlMessages id="sidebar.helpdesk" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="facility">
                  <Link to="">
                    <i className="icon icon-tasks" />
                    <IntlMessages id="sidebar.facility" />
                  </Link>
                </Menu.Item>
              </MenuItemGroup> */}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { userData, authUser } = auth;

  return { navStyle, themeType, locale, pathname, userData, authUser };
};
export default connect(mapStateToProps)(SidebarContent);
