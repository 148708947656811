import React from "react";
import { Card } from "antd";
import DropdownGroup from "./Dropdown/index";
import RechargeTable from "./RechargeTable";

const Index = () => {
  return (
    <Card className="gx-card" title="Recharge">
      <div>
        <DropdownGroup />
      </div>
      <div className="gx-mt-5">
        <RechargeTable />
      </div>
    </Card>
  );
};

export default Index;
