import React from "react";
import { Popconfirm } from "antd";
import { EditableContext } from "./EditableCell";

export const ActionCell = ({ isEditing, save, toggleEditing, record }) => {
  return isEditing ? (
    <span>
      <EditableContext.Consumer>
        {(form) => (
          <a onClick={() => save(form, record._id)} style={{ marginRight: 8 }}>
            Save
          </a>
        )}
      </EditableContext.Consumer>
      <Popconfirm title="Sure to cancel?" onConfirm={toggleEditing}>
        <a>Cancel</a>
      </Popconfirm>
    </span>
  ) : (
    <span>
      {isEditing === false ? (
        <a disabled={isEditing !== false} onClick={toggleEditing}>
          Edit
        </a>
      ) : (
        <Popconfirm
          title="Sure to Update?"
          onConfirm={() => console.log("saving")}
        >
          <a disabled={isEditing !== false}>Save</a>
        </Popconfirm>
      )}
    </span>
  );
};
