import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  POST_USER,
  GET_USER,
  UPDATE_USER,
  GET_USER_BY_ID,
  GET_USERS_PHONE_NUMBER,
  GET_USER_TICKETS,
  UPDATE_USER_TICKET,
} from "../../constants/ActionTypes";

import { get_user_success, get_user_by_id_success, get_users_number_success, get_users_tickets_success } from "../actions/User";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";

import axios from "axios";

const createUser = async (payload) =>
  await axios
    .post("/create/user", payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const fetchUsers = async (url) =>
  await axios
    .get(
      "/project/users/" + JSON.parse(localStorage.getItem("projectId")) + url
    )
    .then((res) => res.data)
    .catch((error) => error.response);

const fetchUserById = async (user_id) =>
  await axios
    .get(
      `/users/list/${user_id}?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}`
    )
    .then((res) => res.data)
    .catch((error) => error.response);

const putUser = async (data, id) =>
  await axios
    .put(`/update/user/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error.response);

const updateTicket = async (data) =>
  await axios
    .post(`/close/project/ticket`, data)
    .then((res) => res.data)
    .catch((error) => error.response);

const fetchUsersNumber = async (projectId) =>
  await axios
    .get(`/user/phonenumber/${projectId}`)
    .then((res) => res.data)
    .catch((error) => error.response);

const fetchUsersTickets = async (url) =>
  await axios
    .get(`/project/tickets/${JSON.parse(
      localStorage.getItem("projectId")
    )}${url}`)
    .then((res) => res.data)
    .catch((error) => error.response);

function* getUsers({ payload }) {
  try {
    const data = yield call(fetchUsers, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_user_success({ data: data.data, count: data.count }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* postNewUser({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(createUser, payload);
    if (data.data.status === "error") {
      yield put(showMessage(data.data.message));
    } else {
      var message = data.message;
      var action_type = "create_new_user";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getUserById({ payload }) {
  try {
    const data = yield call(fetchUserById, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "edit_user";
      yield put(fetchSuccess({ message, action_type }));
      yield put(get_user_by_id_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* updateUserById({ payload }) {
  try {
    const { _id } = payload;
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(putUser, payload, _id);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "update_user";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* updateUserTicketFunction({ payload }) {
  try {
    const data = yield call(updateTicket, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "update_user_ticket";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getUserNumberFunction() {
  try {
    const projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(fetchUsersNumber, projectId);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_users_number_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getUserTicketsFunction({payload}) {
  try {
    const data = yield call(fetchUsersTickets, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_users_tickets_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* getAllUsers() {
  yield takeEvery(GET_USER, getUsers);
}
export function* postUser() {
  yield takeEvery(POST_USER, postNewUser);
}
export function* getSingleUser() {
  yield takeEvery(GET_USER_BY_ID, getUserById);
}
export function* updateUser() {
  yield takeEvery(UPDATE_USER, updateUserById);
}
export function* getUsersNumber() {
  yield takeEvery(GET_USERS_PHONE_NUMBER, getUserNumberFunction);
}
export function* getUsersTickets() {
  yield takeEvery(GET_USER_TICKETS, getUserTicketsFunction);
}
export function* updateUserTicket() {
  yield takeEvery(UPDATE_USER_TICKET, updateUserTicketFunction);
}

export default function* rootSaga() {
  yield all([
    fork(getAllUsers),
    fork(postUser),
    fork(getSingleUser),
    fork(updateUser),
    fork(updateUserTicket),
    fork(getUsersNumber),
    fork(getUsersTickets)
  ]);
}
