import React from "react";
import { connect } from "react-redux";
import { Table, Popconfirm, Form, Card, message } from "antd";
import Moment from "moment";
import { fetchStart, fetchSuccess } from "../../../../appRedux/actions/Common";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";
import { get_tariff, update_tariff } from "../../../../appRedux/actions/Tariff";
import CircularProgress from "components/CircularProgress/index";
import { BILL_DATES, BillDate } from "./billDate";
import { Maintenance } from "./Maintenance"
import { EditableContext, EditableCell } from "./EditableCell";
import { ActionCell } from "./TableActionCell";
import NewTariff from "./NewTariff";

const rowStyles = {
  marginTop: 10,
};

const labelStyles = {
  width: 150,
  display: "inline-block",
};

const inputStyles = {
  marginLeft: 16,
  borderRadius: 4,
  padding: 6,
  border: "1px solid #d9d9d9",
  outline: 0,
};

class TariffTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isEditing: false,
      utilities : {},
      columnData: [],
      tableData: [],
      tarifMaintenancePrice : "",
      isPasswordEmpty : false,
      tariffData: [],
      maintenanceType : "",
      password: "",
      billDate: "",
      hideBillDate : false
    };
  }

  componentDidMount() {
    this.props.get_all_utilities();
    setTimeout(() => {
      this.props.get_tariff();
    }, 1000);
  }

  toggleEditing = () =>
    this.setState((state) => ({ isEditing: !state.isEditing }));

  shouldComponentUpdate(nextProps, nextState) {
    console.log(nextProps);
    // console.log(nextProps.tariffPrice.threshold.isThreshold2Reached);
    if (
      this.props.utilities === nextProps.utilities &&
      nextProps.tariffPrice.updateTariff && nextProps.tariffPrice.updateTariff.length > 0 &&
      this.props.tariffPrice.updateTariff !== nextProps.tariffPrice.updateTariff
    ) {
      let columnArr = [
        {
          title: "Last updated at",
          dataIndex: "updatedAt",
          width: "25%",
        },
      ];
      let utilitiesObj = {}
      nextProps.utilities.forEach((list) => {
         utilitiesObj[list._id] = list.code
        // TODO: uncomment below if and remove if below that
        /* if (
          list.code === "MTCE" ||
          (nextProps.tariffPrice.isMultiTariff && list.code === "EB")
        ) {
          return;
        } */
        if (list.code === "MTCE") return;
        columnArr.push({
          title: list.name,
          dataIndex: "price_" + list.code,
          width: "15%",
          editable: true,
        });
      });
      columnArr.push({
          title: "Threshold1Reached",
          dataIndex: "isThreshold1Reached",
          width: "15%",
          editable: true,
      },{
        title: "Threshold2Reached",
        dataIndex: "isThreshold2Reached",
        width: "15%",
        editable: true,
      },{
        title: "Threshold3Reached",
        dataIndex: "isThreshold3Reached",
        width: "15%",
        editable: true,
      });
      columnArr.push({
        title: "Action",
        dataIndex: "operation",
        render: (_, record) => (
          <ActionCell
            record={record}
            save={this.save}
            toggleEditing={this.toggleEditing}
            isEditing={this.state.isEditing}
          />
        ),
      });
      this.setState({ columnData: columnArr });
      let rowObj = {};
      let tableArr = [];
      this.setState({ tariffData: nextProps.tariffPrice.updateTariff });
      rowObj.updatedAt = Moment(nextProps.tariffPrice.updatedAt).format(
        "YYYY-MM-DD hh:mm a"
      );
      rowObj.isThreshold1Reached = nextProps.tariffPrice.threshold.isThreshold1Reached;
      rowObj.isThreshold2Reached = nextProps.tariffPrice.threshold.isThreshold2Reached;
      rowObj.isThreshold3Reached = nextProps.tariffPrice.threshold.isThreshold3Reached;
      console.log(rowObj)
      //Logic for getting Utility names based on ObjectId
      if(nextProps.tariffPrice && nextProps.tariffPrice.maintenance){
        if(nextProps.tariffPrice.maintenance.maintenance == "BHK"){
          if( nextProps.tariffPrice.maintenance.hasOwnProperty("bhkPrice")){
            this.setState({tarifMaintenancePrice : nextProps.tariffPrice.maintenance.bhkPrice, maintenanceType : "BHK"})
          }
        }else{
          this.setState({tarifMaintenancePrice : nextProps.tariffPrice.maintenance.maintenancePrice,  maintenanceType : nextProps.tariffPrice.maintenance.maintenance})
        }  
      }
      if(nextProps.tariffPrice.hasOwnProperty("projectWalletType")){
        if(nextProps.tariffPrice.projectWalletType == "Pre-Paid"){
          this.setState({hideBillDate : true})
        }else{
          this.setState({billDate : nextProps.tariffPrice.billDate})
        }
      }else{
        this.setState({billDate : nextProps.tariffPrice.billDate})
      }
      nextProps.tariffPrice.updateTariff.map((list) => {
        const item = this.props.utilities.find(
          (uti) => uti._id === list.utilityId
        );
        const code = (item && item.code) || "";
        rowObj["price_" + code] = list.price;
      });
      tableArr.push(rowObj);
      this.setState({ tableData: tableArr, utilities : utilitiesObj });
    }
    return true;
  }
  changeMaintenance = (e) => {
    if(this.state.maintenanceType == "BHK"){
      var getCurrentValues = this.state.tarifMaintenancePrice;
      getCurrentValues[e.target.name] = e.target.value
      this.setState({tarifMaintenancePrice : getCurrentValues})
    }else{
      this.setState({tarifMaintenancePrice : e.target.value})
    }
  }
  save = (form, _id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.tableData];
      const index = newData.findIndex((item) => _id === item._id);
      // TODO: bug here, check this log
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        let updatedTariff = newData[index];
        let getTariffData = this.state.tariffData;
        console.log( this.state)
        console.log(newData[index])
        const updatedTariffPrice = getTariffData.map((list) => {
          let getPriceKey = "price_" + this.state.utilities[list.utilityId];
          if (this.state.utilities[list.utilityId]) {
            list.price = parseFloat(updatedTariff[getPriceKey]);
          }
          return list;
        });
        let obj = {
          isMultiTariff: false,
          password: this.state.password,
          billDate: this.state.billDate,
          tarifMaintenancePrice : this.state.tarifMaintenancePrice,
          maintenanceType: this.state.maintenanceType
        };
        obj.tariff = getTariffData;
        obj['isThreshold1Reached'] = parseInt(newData[index].isThreshold1Reached)
        obj['isThreshold2Reached'] = parseInt(newData[index].isThreshold2Reached)
        obj['isThreshold3Reached'] = parseInt(newData[index].isThreshold3Reached)
        this.props.update_tariff(obj);
        this.setState({ tableData: newData, isEditing: false });
      } else {
        console.log("something went wrong");
      }
    });
  };

  render() {
    const { getFieldDecorator,  } = this.props.form;
    console.log(this.state.columnData)
    const components = {
      body: {
        cell: EditableCell,
      },
    };
    const { loading, error_occurred, show_message, tariffPrice, action_type } = this.props;
    const columns = this.state.columnData.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => 
        ({
          record,
          inputType: col.dataIndex === "code" ? "text" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.state.isEditing,
        }),
      };
    });
    return (
      <div>
        <Card className="gx-card" title="Project Tariff">
          <div>
            {tariffPrice.isMultiTariff ? (
              <div>
                <NewTariff tariffs={tariffPrice.multiTariff} tariffData={tariffPrice} getFieldDecorator={getFieldDecorator}/>
              </div>
            ) : (
              <div>
                <EditableContext.Provider value={this.props.form}>
                  <Table
                    components={components}
                    bordered
                    dataSource={this.state.tableData}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                  />
                </EditableContext.Provider>
                {!this.state.hideBillDate && <BillDate
                  date={this.state.billDate}
                  setDate={(val) => this.setState({ billDate: val })}
                  editing={this.state.isEditing}
                />}
                <Maintenance
                  editing={this.state.isEditing}
                  setMaintenance={(val) => this.changeMaintenance(val)}
                  values={this.state.tarifMaintenancePrice}
                  tariffData={tariffPrice}
                />  
                {this.state.isEditing ? (
                  <div style={rowStyles}>
                    <label style={labelStyles} htmlFor="password">
                      Confirm Password
                    </label>
                    <input
                      style={inputStyles}
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      type="password"
                      id="password"
                      name="password"
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>

          <div>
            {loading ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {/* {error_occurred ? message.error(show_message.toString()) : null} */}
          </div>
          <div style={{ marginTop: 20 }}>
            <p>
              <strong>NOTE:</strong> tariff will be updated after midnight 12
              O'clock
            </p>
          </div>
        </Card>
      </div>
    );
  }
}

const Tariff = Form.create()(TariffTable);

function mapStateToProps({ common, utility, tariff }) {
  const { utilities } = utility;
  const { tariffPrice } = tariff;
  const { error_occurred, loading, show_message, warning_message, action_type } = common;
  return {
    error_occurred,
    action_type,
    loading,
    show_message,
    warning_message,
    utilities,
    tariffPrice,
  };
}
export default connect(mapStateToProps, {
  fetchStart,
  fetchSuccess,
  get_all_utilities,
  get_tariff,
  update_tariff,
})(Tariff);
