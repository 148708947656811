import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { get_failed_utilities } from "../../../appRedux/actions/Utility";
import Modal from "./Modal";

const errorHandler = (index) => {
  // the callback. Use a better name
  console.log(index);
};
const columns = [
  {
    title: "Block Name",
    dataIndex: "blockId.name",
    width: "15%",
  },
  {
    title: "Flat Name",
    dataIndex: "flatId.name",
    width: "15%",
  },
  {
    title: "utility",
    dataIndex: "utility",
    width: "15%",
  },
  {
    title: "Failed Reading",
    dataIndex: "incomingReading",
    width: "15%",
  },
  {
    title: "Current Reading",
    dataIndex: "presentReading",
    width: "15%",
  },
  {
    title: "Failed Date",
    dataIndex: "timestamp",
    width: "15%",
    render: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    title: "Resolve",
    render: (value, record) => {
      return <Modal record={record} />;
    },
  },
];
const RechargeTable = (props) => {
  const [failureMeters, setFailureMeters] = useState([]);
  useEffect(() => {
    var url = '&blockId=All&flatId=All'
    props.get_failed_utilities(url);
  },[]);
  useEffect(() => {
    setFailureMeters(props.failedUtilites.data)
    props.setFailedCount(props.failedUtilites.count)
  },[props.failedUtilites]);
  return (
    <div>
      <Table
        bordered
        rowKey="_id"
        columns={columns}
        dataSource={failureMeters}
        pagination={{
          total: props.failedUtilites.count,
          current: props.page,
          onChange: (val) => props.setPage(val),
          showSizeChanger: true,
          onShowSizeChange: (cur, pageSize) => props.setRows(pageSize),
        }}
      />
      {props.common.action_type == "reset_meter"
        ? message.success("Successfully reseted meter")
        : null}
    </div>
  );
};

export default connect((state) => ({
  failedUtilites: state.utility.failedUtilites,
  common: state.common,
}),{get_failed_utilities})(RechargeTable);
