import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_FLATS,
  POST_FLAT,
  GET_ALL_FLATS_BLOCK_ID,
  GET_ALL_FLATS_ID,
  UPDATE_FLAT,
  GET_ALL_FLATS_BALANCE,
  RECHARGE_WALLET,
  GET_FLATS_TRANSACTIONS,
  GET_FLAT_BLOCK_NAME
} from "../../constants/ActionTypes";

import {
  get_all_flats_success,
  get_all_flats_block_id_success,
  get_all_flats_id_success,
  get_all_flats_balance_success,
  get_flats_transactions_success,
  get_flat_block_names_success
} from "../actions/Flat";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";

import axios from "axios";

const createNewFlat = async (payload) =>
  await axios
    .post("/flat", payload)
    .then((res) => res.data)
    .catch((error) => error);

const fetchFlats = async () =>
  await axios
    .get("/flats?projectId=" + JSON.parse(localStorage.getItem("projectId")))
    .then((res) => res.data)
    .catch((error) => error);

const getFlatsbyId = async (block_id) =>
  await axios
    .get(
      `/flats/block/${block_id}?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}`
    )
    .then((res) => res.data)
    .catch((error) => error);

const getFlatsbyFlatsId = async (flat_id) =>
  await axios
    .get(
      `/flats/${flat_id}?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}`
    )
    .then((res) => res.data)
    .catch((error) => error);

const putFlat = async (data, id) =>
  await axios
    .put(`/update/flat/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

const fetchFlatsBalance = async (url) =>
  await axios
    .get(
      `/flat/rechargeNew?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}${url}`
    )
    .then((res) => res.data)
    .catch((error) => error);
const createRecharge = async (data) =>
  await axios
    .post(`/create/transaction`, data)
    .then((res) => res.data)
    .catch((error) => error.response.data);

const getBlockFlatData = async (payload) =>
  await axios
    .get(`/all/devices/inuse/${JSON.parse(localStorage.getItem("projectId"))}?`+payload)
    .then((res) => res.data)
    .catch((error) => error.response.data);

const getFlatTransactions = async (url) =>
  await axios
    .get("/user/transactions/web?projectId=" + url)
    .then((res) => res.data)
    .catch((error) => error);

function* getFlats() {
  try {
    const data = yield call(fetchFlats);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_all_flats_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* postNewFlat({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(createNewFlat, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "new_flat_success";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getflatsByBlockId({ payload }) {
  const { block_id } = payload;
  try {
    const data = yield call(getFlatsbyId, block_id);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(get_all_flats_block_id_success(data.data));
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}
function* getFlatsByFlatId({ payload }) {
  try {
    const data = yield call(getFlatsbyFlatsId, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(get_all_flats_id_success(data.data));
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* updateFlats({ payload }) {
  try {
    const { _id } = payload;
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(putFlat, payload, _id);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* flatsBalance({ payload }) {
  try {
    const data = yield call(fetchFlatsBalance, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_all_flats_balance_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* rechargeWallet({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(createRecharge, payload);
    var message = "";
    if (data.status === "error") {
      if (data.statusCode == 401) {
        message = "Password is incorrect";
      } else {
        message = data.message;
      }
      var action_type = "transaction_error";
      yield put(fetchError({ message, action_type }));
    } else {
      message = data.message;
      var action_type = "transaction_success";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getFlatTransactionWorker({ payload }) {
  try {
    const projectId = JSON.parse(localStorage.getItem("projectId"));
    const url = `${projectId}${payload}`;
    const data = yield call(getFlatTransactions, url);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(
        get_flats_transactions_success({ data: data.data, count: data.count })
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getFlatBlockNameValues({ payload }) {
  try {
    const data = yield call(getBlockFlatData, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(
        get_flat_block_names_success(data.data)
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* getAllFlats() {
  yield takeEvery(GET_ALL_FLATS, getFlats);
}
export function* postFlat() {
  yield takeEvery(POST_FLAT, postNewFlat);
}
export function* getFlatByBlockId() {
  yield takeEvery(GET_ALL_FLATS_BLOCK_ID, getflatsByBlockId);
}
export function* getFlatsByFlat() {
  yield takeEvery(GET_ALL_FLATS_ID, getFlatsByFlatId);
}
export function* updateFlat() {
  yield takeEvery(UPDATE_FLAT, updateFlats);
}
export function* get_all_flats_balance_list() {
  yield takeEvery(GET_ALL_FLATS_BALANCE, flatsBalance);
}
export function* rechargewallet() {
  yield takeEvery(RECHARGE_WALLET, rechargeWallet);
}

export function* getFlatTransactionWatcher() {
  yield takeEvery(GET_FLATS_TRANSACTIONS, getFlatTransactionWorker);
}

export function* getFlatBlockNames() {
  yield takeEvery(GET_FLAT_BLOCK_NAME, getFlatBlockNameValues);
}

export default function* rootSaga() {
  yield all([
    fork(getAllFlats),
    fork(postFlat),
    fork(getFlatByBlockId),
    fork(getFlatsByFlat),
    fork(updateFlat),
    fork(get_all_flats_balance_list),
    fork(rechargewallet),
    fork(getFlatTransactionWatcher),
    fork(getFlatBlockNames),
  ]);
}
