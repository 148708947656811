import React from "react";
import { Card } from "antd";
import Dropdown from "./DropDown";

/**
 * @description `This component renders Reports page`
 */

const CardTab = () => {
  return (
    <Card className="gx-card" title="Reports">
      <div>
        <Dropdown />
      </div>
    </Card>
  );
};
export default CardTab;
