import React, { Component } from "react";
import { Button, Card, Form, Icon, Select, Tooltip } from "antd";
import { connect } from "react-redux";
import { get_all_blocks } from "../../../../appRedux/actions/Block.js";
import { get_all_flats_block_id } from "../../../../appRedux/actions/Flat";
import {
  get_all_devices,
  map_device_data,
} from "../../../../appRedux/actions/Device";
import { fetchStart } from "../../../../appRedux/actions/Common";
import { post_flat } from "../../../../appRedux/actions/Flat";
const FormItem = Form.Item;

class FlatRegistration extends Component {
  state = {
    blocks: [],
    flatList: [],
    checked: true,
    get_devices: [],
    devices : {},
    multiDeviceType: false,
    channelList : [],
    deviceData : [],
    channelType : ""
  };

  componentDidMount() {
    this.props.get_all_blocks();
    this.props.get_all_devices();
    this.setState({ multiDeviceType: false, channelType : "" })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.blocks !== nextProps.blocks) {
      this.setState({ blocks: nextProps.blocks });
      return false;
    } else if (this.props.block_flats !== nextProps.block_flats) {
      this.setState({ flatList: nextProps.block_flats });
      return false;
    } else if (this.props.get_devices !== nextProps.get_devices) {
      this.setState({ get_devices: nextProps.get_devices });
      return false;
    } else {
      return true;
    }
  }

  handleSubmit = (e) => {
    this.props.fetchStart();
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.devices = this.state.devices
       this.props.map_device_data(values);
       this.props.history.push(`/devices`);
      }
    });
  };

  handleBlockChange = (e) => {
    this.props.form.setFieldsValue({
      flatId: "",
    });
    let obj = {
      block_id: e,
    };
    this.props.get_all_flats_block_id(obj);
  };
  handleDeviceChange = (e) => {
    let getDeviceData = this.state.get_devices.length &&  this.state.get_devices.find((deviceList) => deviceList._id == e)
    // if (getDeviceData && getDeviceData.nodeType == "Multi-Node") {
      let channleListData = []
      getDeviceData.multiUtilityReadings.map(list=>{
        if(!list.flatId){
         channleListData.push(list)
        }
      })
      this.setState({ multiDeviceType: true, channelList : channleListData,channelType : "", devices : getDeviceData })
    // } else {
    //   this.setState({ multiDeviceType: false })
    // }
  };
  handleChannelChange = (e) =>{
   this.setState({channelType : e})
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const children = [];
    for (let i = 10; i < 15; i++) {
      children.push(
        <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
      );
    }
    return (
      <Card className="gx-card" title="Map device">
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Select Block&nbsp;
                <Tooltip title="This is the Block">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("blockId", {
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Select a Block"
                optionFilterProp="children"
                onChange={(value) => {
                  this.handleBlockChange(value);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.blocks.map((list) => {
                  return <Option value={list._id}>{list.name}</Option>;
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={<span>Select Flat</span>}>
            {getFieldDecorator("flatId", {
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Select a Flat"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.flatList.map((list) => {
                  return <Option value={list._id}>{list.name}</Option>;
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={<span>Select Devices</span>}>
            {getFieldDecorator("deviceId", {
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                mode="multiple"
                placeholder="Please select"
                onChange={(value) => {
                  this.handleDeviceChange(value);
                }}
              >
                {this.state.get_devices.map((list, i) => {
                  return (
                    <Option key={i} value={list._id}>
                      {"Device Id. "}
                      {list.device_id}
                      {" - "}
                      {list.mac_id}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          {this.state.multiDeviceType && <FormItem {...formItemLayout} label={<span>Select Devices Channels</span>}>
            {getFieldDecorator("deviceChannels", {
              rules: [
                {
                  required: this.state.multiDeviceType,
                },
              ],
            })(
              <Select
                mode="single"
                placeholder="Please select"
                onChange={(value) => {
                  this.handleChannelChange(value);
                }}
              >
                {this.state.channelList.map((list, i) => {
                  return (
                    <Option key={i} value={list.channel}>
                      {list.channel}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>}

          <br></br>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Map device
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const FlatRegistrationForm = Form.create()(FlatRegistration);

function mapStateToProps({ common, Block, flats, device }) {
  const { blocks } = Block;
  const { block_flats } = flats;
  const { get_devices } = device;
  const { error_occurred, loading, show_message, warning_message } = common;
  return {
    get_devices,
    blocks,
    block_flats,
    error_occurred,
    loading,
    show_message,
    warning_message,
  };
}
export default connect(mapStateToProps, {
  get_all_blocks,
  post_flat,
  get_all_flats_block_id,
  get_all_devices,
  map_device_data,
  fetchStart,
})(FlatRegistrationForm);
