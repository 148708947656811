import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_BILLS,
  GENERATE_BILLS,
  SEND_BILL_REQUEST,
} from "../../constants/ActionTypes";

import { get_all_bills_success } from "../actions/Bill";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";

import axios from "axios";

const createBills = async (payload) =>
  await axios
    .post("/generatebills", payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const postUserBills = async (payload) =>
  await axios
    .post("/send/user/bills", payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const fetchBills = async (url, projectId) =>
  await axios
    .get(`/bills/web?projectId=${projectId}${url}`)
    .then((res) => res.data)
    .catch((error) => error);

function* getBills({ payload }) {
  try {
    var projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(fetchBills, payload, projectId);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "bills";
      yield put(fetchSuccess({ message, action_type }));
      yield put(
        get_all_bills_success({ bills: data.data, count: data.totalCount })
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* generateBills({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(createBills, payload);
    if (data.data.status === "error") {
      var message = data.message;
      var action_type = "bills";
      yield put(fetchError({ message, action_type }));
    } else {
      var message = data.message;
      var action_type = "generateBills";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* sendBills({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(postUserBills, payload);
    if (data.data.status === "error") {
      var message = data.data.message;
      var action_type = "bills";
      yield put(fetchError({ message, action_type }));
    } else {
      yield put(fetchSuccess(data.status));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* getAllBills() {
  yield takeEvery(GET_ALL_BILLS, getBills);
}
export function* postBills() {
  yield takeEvery(GENERATE_BILLS, generateBills);
}
export function* sendUserBills() {
  yield takeEvery(SEND_BILL_REQUEST, sendBills);
}

export default function* rootSaga() {
  yield all([fork(getAllBills), fork(postBills), fork(sendUserBills)]);
}
