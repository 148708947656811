import React, { useEffect, useState } from "react";
import { Table } from "antd";

/**
 * @description `This component renders Cummulative reports data table`
 * @param data cummulative reports data
 * @param utilities available utilities is used to create data source for table
 */

const Cummulative = ({ data, utilities, ...props }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    //? map over utilities to create columns for table source
    if (data.data.length && utilities.length) {
      const temp = [];
      utilities.forEach((util) => {
        if (util.code !== "MTCE") {
          temp.push({
            title: util.name,
            dataIndex: util.code,
            width: "15%",
          });
        }
      });
      setColumns([
        {
          title: "Period",
          dataIndex: "date",
          width: "20%",
        },
        ...temp,
      ]);
    }
  }, [data, utilities]);

  return (
    <div>
      <Table
        bordered
        dataSource={data.data}
        columns={columns}
        rowKey="date"
        pagination={{
          total: data.count,
          current: props.page,
          onChange: (val) => props.setPage(val),
        }}
      />
      <div>
        {/* {error_occurred ? message.error(show_message.toString()) : null} */}
      </div>
    </div>
  );
};

export default Cummulative;
