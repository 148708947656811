import React from "react";
import { connect } from "react-redux";
import { Table, Card, message, Select, Row, Tabs } from "antd";

import moment from "moment";
import queryString from "query-string";

import { get_flat_device_readings } from "appRedux/actions/Device";
import { fetchStart } from "appRedux/actions/Common";
import { get_all_utilities } from "../../../appRedux/actions/Utility";
import CircularProgress from "components/CircularProgress/index";
const TabPane = Tabs.TabPane;
class DeviceReadingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flat_device_readings: [],
      selectedUtility: { code: "all", name: "All" },
    };

    this.columns = [
      {
        title: "Date",
        dataIndex: "timestamp",
        width: "25%",
        render: (value) => (
          <span>
            {moment(value)
              .utc()
              .format("DD-MM-YYYY")}
          </span>
        ),
      },
      {
        title: "Time",
        dataIndex: "timestamp",
        width: "25%",
        render: (value) => (
          <span>
            {moment(value)
              .utc()
              .format("hh:mm a")}
          </span>
        ),
      },
      {
        title: "Units used",
        dataIndex: "value",
        width: "25%",
        render: (value) => <span>{Number(value).toFixed(2)}</span>,
      },
      {
        title: "Usage cost",
        dataIndex: "costUsage",
        width: "25%",
        render: (value) => <span>{Number(value).toFixed(2)}</span>,
      },
    ];
  }

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    this.props.get_all_utilities();
    this.props.fetchStart();
    this.props.get_flat_device_readings(queryParams);
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.flat_device_readings !== nextProps.flat_device_readings) {
      this.setState({ flat_device_readings: nextProps.flat_device_readings });
      return false;
    } else {
      return true;
    }
  }
  handleMenuChange = (value) => {
    const { utilities } = this.props;
    let selectedUtility = {};
    [{ code: "all", name: "All" }, ...utilities].forEach((item) => {
      const { name, code } = item;
      if (code === value) {
        selectedUtility = { code, name };
      }
    });
    this.setState({ selectedUtility });
  };
  renderSelectMenu = () => {
    const { utilities } = this.props;
    const utilityCodes = this.state.flat_device_readings.map((item) => {
      return Object.keys(item)[0];
    });
    const availableUtilities = [{ code: "all", name: "All" }];
    utilities.forEach((item) => {
      const { name, code } = item;
      if (utilityCodes.includes(code)) {
        availableUtilities.push({ code, name });
      }
    });
    return (
      <Select
        defaultValue={this.state.selectedUtility.code}
        onChange={this.handleMenuChange}
        style={{ width: 150 }}
      >
        {availableUtilities.map((item) => (
          <Select.Option key={item.code} value={item.code}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  render() {
    const { loading, error_occurred, show_message } = this.props;
    return (
      <div>
        <Row
          style={{
            justifyContent: "flex-end",
            marginBottom: 20,
            marginRight: 5,
          }}
        >
          {this.renderSelectMenu()}
        </Row>
        <Card
          className="gx-card"
          title={`${this.state.selectedUtility.name} Device Readings`}
        >
          <Tabs type="card">
            {this.state.flat_device_readings.length > 0 &&
              this.state.flat_device_readings.map((reading) => {
                /****
                 *! ADD IF CONDITIONS TO RENDER ALL AND ONE OF THEM
                 ****/
                const key = Object.keys(reading)[0];
                if (this.state.selectedUtility.code === "all") {
                  const item = this.props.utilities.find((i) => i.code === key);
                  return (
                    <TabPane tab={key} key={key}>
                      <h4 style={{ marginBottom: 20 }}>{item.name}</h4>
                      <Table
                        key={key}
                        bordered
                        dataSource={reading[key]}
                        columns={this.columns}
                      />
                    </TabPane>
                  );
                }
                if (key === this.state.selectedUtility.code) {
                  return (
                    <TabPane tab={key} key={key}>
                      <Table
                        key={key}
                        bordered
                        dataSource={reading[key]}
                        columns={this.columns}
                      />
                    </TabPane>
                  );
                }
              })}
          </Tabs>
          <div>
            {loading ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {error_occurred ? message.error(show_message.toString()) : null}
          </div>
        </Card>
      </div>
    );
  }
}

function mapStateToProps({ common, device, utility }) {
  const { flat_device_readings } = device;
  const { error_occurred, loading, show_message, warning_message } = common;
  const { utilities } = utility;
  return {
    flat_device_readings,
    error_occurred,
    loading,
    show_message,
    warning_message,
    utilities,
  };
}
export default connect(mapStateToProps, {
  get_flat_device_readings,
  fetchStart,
  get_all_utilities,
})(DeviceReadingDetails);
