import React from "react";
import { Card } from "antd";
import Paymentgroup from "./Dropdown/index";

const Transactions = () => {
  return (
    <Card className="gx-card" title="Reports">
      <Paymentgroup />
    </Card>
  );
};

export default Transactions;
