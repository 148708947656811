import {
  POST_USER,
  GET_USER,
  UPDATE_USER,
  GET_USER_SUCCESS,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USERS_PHONE_NUMBER,
  GET_USERS_PHONE_NUMBER_SUCCESS,
  GET_USER_TICKETS,
  GET_USER_TICKETS_SUCCESS,
  UPDATE_USER_TICKET
} from "constants/ActionTypes";

export const get_user = (payload) => {
  return {
    type: GET_USER,
    payload,
  };
};
export const get_user_by_id = (payload) => {
  return {
    type: GET_USER_BY_ID,
    payload: payload,
  };
};
export const get_user_by_id_success = (payload) => {
  return {
    type: GET_USER_BY_ID_SUCCESS,
    payload: payload,
  };
};
export const get_user_success = (payload) => {
  return {
    type: GET_USER_SUCCESS,
    payload: payload,
  };
};
export const get_users_number_success = (payload) => {
  return {
    type: GET_USERS_PHONE_NUMBER_SUCCESS,
    payload: payload,
  };
};
export const get_user_tickets = (payload) => {
  return {
    type: GET_USER_TICKETS,
    payload: payload,
  };
};
export const get_users_tickets_success = (payload) => {
  return {
    type: GET_USER_TICKETS_SUCCESS,
    payload: payload,
  };
};
export const get_users_phone_number = () => {
  return {
    type: GET_USERS_PHONE_NUMBER
  };
};

export const post_user = (payload) => {
  return {
    type: POST_USER,
    payload: payload,
  };
};
export const update_user = (payload) => {
  return {
    type: UPDATE_USER,
    payload: payload,
  };
};
export const update_user_ticket = (payload) => {
  return {
    type: UPDATE_USER_TICKET,
    payload: payload,
  };
};
