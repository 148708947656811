import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import blocks from "./Block";
import flats from "./Flat";
import devices from "./Device";
import users from "./User";
import utility from "./Utility";
import Report from "./Reports";
import Tariff from "./Tariff";
import Bills from "./Bills";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    blocks(),
    flats(),
    devices(),
    users(),
    utility(),
    Report(),
    Tariff(),
    Bills(),
  ]);
}
