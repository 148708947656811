import React from "react";
import { Button } from "antd";
import DeviceList from "./DeviceList/devicelist";
class Template extends React.Component {
  handleClick = this.handleClick.bind(this);
  handleClick() {
    this.props.history.push("/devices/mapdevice");
  }
  render() {
    return (
      <div>
        <Button
          type="primary"
          onClick={this.handleClick}
          style={{ float: "right", "z-index": "1", top: "10px" }}
        >
          Map Device
        </Button>
        <div>
          <DeviceList />
        </div>
      </div>
    );
  }
}

export default Template;
