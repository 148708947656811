import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { auth } from "../../firebase/firebase";
import { SIGNIN_USER, SIGNOUT_USER, CHANGE_PASSWORD_USER,CHANGE_TRANSACTION_PASSWORD_USER } from "../../constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userChangeTransactionPasswordSuccess,
  userChangePasswordSuccess
} from "../../appRedux/actions/Auth";
import axios from "axios";

const signInUserWithEmailPasswordRequest = async (payload) =>
  await axios
    .post("/login", payload)
    .then((res) => res.data)
    .catch((error) => error.response);

const changePasswordRequest = async (payload) =>
    await axios
      .post("/project/changePassword", payload)
      .then((res) => res.data)
      .catch((error) => error.response);

      
function* changePassword({ payload }) {
        try {
          payload.projectId = JSON.parse(localStorage.getItem("projectId"));
          const userData = yield call(changePasswordRequest, payload);
          if (userData.status == 401) {
            yield put(showAuthMessage(userData.data));
          } else {
              localStorage.removeItem("userData");
              localStorage.removeItem("projectId");
              localStorage.removeItem("projectMode");
              localStorage.clear();
              yield put(userChangePasswordSuccess("Success"));
          }
        } catch (error) {
          yield put(showAuthMessage(error));
        }
}

const changeTransactionPasswordRequest = async (payload) =>
      await axios
        .post("/project/changeTransactionPassword", payload)
        .then((res) => res.data)
        .catch((error) => error.response);

function* changeTransactionPassword({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const userData = yield call(changeTransactionPasswordRequest, payload);
    if (userData.status == 401) {
      yield put(showAuthMessage(userData.data));
    } else {
        localStorage.removeItem("userData");
        localStorage.removeItem("projectId");
        localStorage.removeItem("projectMode");
        localStorage.clear();
        yield put(userChangeTransactionPasswordSuccess("Success"));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
// const signOutRequest = async () =>
//   await auth
//     .signOut()
//     .then((authUser) => authUser)
//     .catch((error) => error);

function* signInUserWithEmailPassword({ payload }) {
  try {
    const userData = yield call(signInUserWithEmailPasswordRequest, payload);
    if (userData.status == 401) {
      yield put(showAuthMessage(userData.data));
    } else {
     var signInUser  = userData.data
      localStorage.setItem("userData", JSON.stringify(signInUser));
      localStorage.setItem("projectId", JSON.stringify(signInUser._id));
      localStorage.setItem(
        "projectMode",
        JSON.stringify(signInUser.projectMode)
      );
      yield put(userSignInSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem("userData");
    localStorage.removeItem("projectId");
    localStorage.removeItem("projectMode");
    localStorage.clear();
    yield put(userSignOutSuccess("Success"));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* changePasswordUser() {
  yield takeEvery(CHANGE_PASSWORD_USER, changePassword);
}

export function* changeTransactionPasswordUser() {
  yield takeEvery(CHANGE_TRANSACTION_PASSWORD_USER, changeTransactionPassword);
}

export default function* rootSaga() {
  yield all([fork(signInUser),fork(signOutUser),fork(changePasswordUser),fork(changeTransactionPasswordUser)]);
}
