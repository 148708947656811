import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { connect } from "react-redux";

const handleDeviceON = async (record) => {
  // var payload = {
  //   code: "ON",
  //   EUI: record.mac_id,
  // };
  // this.props.relay_device(payload);
};

const handleDeviceOff = async (record) => {
  // var payload = {
  //   code: "OFF",
  //   EUI: record.mac_id,
  // };
  // this.props.relay_device(payload);
};
const DeviceUtilitiesReadings = ({ record, utilities, loading, ...props }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const columns = [{
      title: "channel",
      dataIndex: "channel",
      key: "channel"
    },
    ];
    utilities.map(list => {
      var obj = {
        title: list.name,
        dataIndex: `utilityReadings.[`+list.code+`]`,
        key: list.code
      }
      columns.push(obj)
    })

    columns.push({
      title: "Balance",
      dataIndex: `utilityReadings.[Bal]`,
      key: "Bal",
      render: (value) =>{
        return  value ? Math.round(value * 100) / 100 : ""
      } 
    },
      {
        title: "Flat",
        dataIndex: "flatName",
        key: "flatName"
      },
      {
        title: "Block",
        dataIndex: "blockName",
        key: "blockName"
      }
    )
    setColumns(columns)
  }, [])
  return <Table columns={columns} dataSource={record ? record : []} pagination={false} />;
};

function mapStateToProps({ common }) {
  const { error_occurred, loading, show_message, warning_message } = common;
  return {
    error_occurred,
    loading,
    show_message,
    warning_message
  };
}

export default connect(mapStateToProps, {})(DeviceUtilitiesReadings);