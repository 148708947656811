import React, { useEffect, useState } from "react";
import { Table, Button, message, Tag } from "antd";
import { FilePdfFilled } from "@ant-design/icons";
import { connect } from "react-redux";
import axios from "axios";
import Modal from "./Paybill";

const handleDownload = async (val) => {
  try {
    const res = await axios.get("/bill/pdf/" + val._id);
    window.open(res.data.data.downloadUrl, "_blank");
  } catch (error) {
    console.log(error);
  }
};

const columns = [
  {
    title: "Bill Date",
    dataIndex: "billDate",
    width: "20%",
  },
  {
    title: "Invoice Id",
    dataIndex: "invoiceId",
    width: "20%",
  },
  {
    title: "Block",
    dataIndex: "blockId.name",
    width: "15%",
  },
  {
    title: "Flat",
    dataIndex: "flatId.name",
    width: "10%",
  },
  {
    title: "Amount",
    dataIndex: "billAmount",
    width: "10%",
  },
  {
    title: "Payment Status",
    dataIndex: "paymentStatus",
    width: "20%",
  },
  {
    title: "Sent Status",
    key: "notificationSent",
    dataIndex: "notificationSent",
    width: "20%",
    render: (notificationSent) => (
      <span>
        {
          <Tag
            color={notificationSent ? "green" : "volcano"}
            key={notificationSent}
          >
            {notificationSent ? "Sent" : "Not Sent"}
          </Tag>
        }
      </span>
    ),
  },
  {
    title: "Pdf",
    dataIndex: "billName",
    width: "20%",
    render: (_, record) => (
      <FilePdfFilled
        onClick={() => handleDownload(record)}
        style={{ fontSize: 24, color: "#EA473A" }}
      />
    ),
  },
  {
    title: "Recharge",
    render: (value, record) => {
      return <Modal record={record} />;
    },
  },
];

const BillsTable = ({ bills, common, ...props }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    props.setAllSelectedBills(selectAll);
    props.setBillsId(selectedRowKeys);
  };
  const tableChanges = () => {
    props.setAllSelectedBills(selectAll);
    props.setBillsId(selectedRowKeys);
  };
  useEffect(() => {
    if (selectAll) {
      let newSelectedRowKeys = [];
      bills.bills.map((key, index) => {
        newSelectedRowKeys.push(key._id);
      });
      setSelectedRowKeys(newSelectedRowKeys);
    }
  }, [bills.bills]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => onSelectChange(selectedRowKeys),
    hideDefaultSelections: true,
    selections: [
      {
        key: "all-data",
        text: "Select All Data",
        onSelect: () => {
          let newSelectedRowKeys = [];
          bills.bills.map((key, index) => {
            newSelectedRowKeys.push(key._id);
          });
          setSelectedRowKeys(newSelectedRowKeys);
          setSelectAll(true);
          props.setAllSelectedBills(true);
          props.setBillsId(selectedRowKeys);
        },
      },
      {
        key: "un-select",
        text: "Un Select All Data",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
          setSelectAll(false);
          props.setAllSelectedBills(false);
          props.setBillsId([]);
        },
      },
    ],
  };
  return (
    <div className="gx-mt-4">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={bills.bills}
        onChange={() => tableChanges()}
        rowKey="_id"
        rowClassName="editable-row"
        bordered
        pagination={{
          total: bills.count,
          current: props.page,
          onChange: (val) => props.setPage(val),
          showSizeChanger: true,
          onShowSizeChange: (cur, pageSize) => props.setRows(pageSize),
        }}
      />
      {common.error_occurred
        ? message.error(common.show_message.toString())
        : null}
      {common.action_type == "generateBills"
        ? message.success("Successfully generated bills")
        : null}
    </div>
  );
};

export default connect((state) => ({
  bills: state.bills,
  common: state.common,
}))(BillsTable);
