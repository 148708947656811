import React, { useEffect, useState } from "react";
import { Button, DatePicker, Modal, Popconfirm } from "antd";
import moment from "moment";

import { connect } from "react-redux";
import { fetchStart } from "../../../appRedux/actions/Common";
import { generate_bills } from "../../../appRedux/actions/Bill";

import Dropdown from "./Dropdowns/Dropdown";

const { MonthPicker } = DatePicker;
const monthFormat = "YYYY/MM";

const allType = { name: "All", id: "myall" };

const MyModal = ({ blocks, flats, tariff, common, ...props }) => {
  const [dropdownBlocks, setDropdownBlocks] = useState([allType]);
  const [dropdownFlats, setDropdownFlats] = useState([allType]);
  var [billDate, setBillDate] = useState();
  const [block, setBlock] = useState(allType);
  const [flat, setFlat] = useState(allType);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [popVisible, setPopVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (tariff) {
      setBillDate(tariff.billDate);
    }
  }, [tariff]);
  useEffect(() => {
    if (block.id === "myall") {
      setDropdownFlats([allType]);
      setFlat(allType);
    } else {
      const temp = [];
      flats.forEach((flat) => {
        if (flat.blockId._id === block.id) {
          temp.push({ name: flat.name, id: flat._id });
        }
      });
      setDropdownFlats([allType, ...temp]);
      setFlat(allType);
    }
  }, [block]);

  useEffect(() => {
    const temp = blocks.map((block) => ({ name: block.name, id: block._id }));
    setDropdownBlocks([allType, ...temp]);
  }, [blocks]);

  const handleCancel = () => {
    setVisible(false);
    setPopVisible(false);
  };

  const handleDateChange = (date, dateString) => {
    const getDate1 = parseInt(billDate);
    var getDate2;
    if (getDate1 == 1) {
      getDate2 = moment(dateString)
        .endOf("month")
        .format("DD");
    } else {
      getDate2 = getDate1 - 1;
    }
    const setDate2 =
      getDate2.toString().length === 1 ? "0" + getDate2 : getDate2;
    const getStartDateMonth = moment(dateString + "/" + getDate1).format(
      "YYYY-MM-DD"
    );
    var getEndDateMonth;
    if (getDate1 == 1) {
      getEndDateMonth = moment(dateString + "/" + setDate2).format(
        "YYYY-MM-DD"
      );
    } else {
      getEndDateMonth = moment(dateString + "/" + setDate2)
        .add(1, "months")
        .format("YYYY-MM-DD");
    }
    setStartDate(getStartDateMonth);
    setEndDate(getEndDateMonth);
  };

  const handleOk = () => {
    let type;
    if (block.id === "myall" && flat.id === "myall") {
      type = "1000";
    } else if (block.id !== "myall" && flat.id !== "myall") {
      type = "1002";
    } else if (block.id !== "myall" && flat.id === "myall") {
      type = "1001";
    }
    const payload = {
      blockId: block.id === "myall" ? "All" : block.id,
      flatId: flat.id === "myall" ? "All" : flat.id,
      startDate,
      endDate,
      type,
    };
    if (!(startDate && endDate)) {
      alert("Please select bill month");
      return;
    }
    props.generate_bills(payload);
    props.fetchStart();
    handleCancel();
  };

  return (
    <div>
      <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-mb-4">
        <h4>Bills</h4>
        <Button type="primary" onClick={() => setVisible(true)}>
          Generate Bills
        </Button>
      </div>
      <Modal
        title="Select the required details"
        visible={visible}
        onOk={() => setPopVisible(true)}
        onCancel={handleCancel}
      >
        <div className="gx-d-flex gx-flex-column gx-align-items-center">
          <div className="gx-mb-4">
            <Dropdown
              title="block"
              data={dropdownBlocks}
              value={block}
              setValue={setBlock}
            />
          </div>
          <div className="gx-mb-4">
            <Dropdown
              title="flat"
              data={dropdownFlats}
              value={flat}
              setValue={setFlat}
            />
          </div>
          <div className="gx-mb-4">
            <h5>Select Month</h5>
            <Popconfirm
              title={`Are you sure?`}
              visible={popVisible}
              onConfirm={handleOk}
              onCancel={() => setPopVisible(false)}
              okText="Yes"
              cancelText="No"
            >
              <MonthPicker format={monthFormat} onChange={handleDateChange} />
            </Popconfirm>
          </div>
          <div className="gx-mt-4">
            <h4>Bills are generated from {billDate} Day of the month.</h4>
          </div>
        </div>
        <div></div>
      </Modal>
    </div>
  );
};

export default connect(
  (state) => ({
    blocks: state.Block.blocks,
    flats: state.flats.flats_list,
    tariff: state.tariff.tariffPrice,
    common: state.common,
  }),
  { fetchStart, generate_bills }
)(MyModal);
