import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Tabs, message } from "antd";

import { fetchStart } from "../../../../appRedux/actions/Common";
import { get_all_blocks } from "../../../../appRedux/actions/Block";
import { get_all_flats } from "../../../../appRedux/actions/Flat";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";
import {
  cumulative_report,
  individual_report,
  cumulative_detail_report,
} from "../../../../appRedux/actions/Reports";

import Dropdown from "./Dropdown";
import CustomRangePicker from "./CustomRangePicker";
import DownloadDataButton from "./DownloadData";
import Cumulative from "../Cumulative/cumulative";
import Individual from "../Flat/individual";
import IndividualAll from "../Individual/index";

const TabPane = Tabs.TabPane;

const fmt = "YYYY/MM/DD";

const dropdownPeriod = [
  { name: "Day", id: "1" },
  { name: "Week", id: "2" },
  { name: "Month", id: "3" },
  { name: "Custom", id: "4" },
];

/**
 * @description `This component renders dropdown group for reports page`
 * @param type type of tab either "cummulative" or "individual"
 * @param data reports data of cummulative and individual
 */

const DropdownGroup = ({
  blocks,
  flats,
  utilities,
  common,
  reports,
  ...props
}) => {
  const [dropdownBlocks, setDropdownBlocks] = useState([
    { name: "All", id: "myall" },
  ]);
  const [dropdownFlats, setDropdownFlats] = useState([
    { name: "All", id: "myall" },
  ]);

  const [block, setBlock] = useState(dropdownBlocks[0]);
  const [flat, setFlat] = useState(dropdownFlats[0]);
  const [period, setPeriod] = useState(dropdownPeriod[0]);
  const [individualList, setIndividualList] = useState([])
  const [individualCumulative, setIndividualCumulative] = useState([])
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [type, setType] = useState("cummulative");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  useEffect(() => {
    props.get_all_blocks();
    props.get_all_flats();
    // TODO: add utilities filter
    props.get_all_utilities();
  }, []);

  //? this is to reset dropdowns whenever tab changes
  useEffect(() => {
    setBlock(dropdownBlocks[0]);
    setFlat(dropdownFlats[0]);
    setPeriod(dropdownPeriod[0]);
  }, [type]);

  useEffect(() => {
    if (type === "individual") {
      if (block.id === "myall") {
        setDropdownFlats(dropdownFlats.slice(0, 1));
        setFlat(dropdownFlats[0]);
      } else {
        setError(false);
        const temp = [];
        flats.forEach((flat) => {
          if (flat.blockId && flat.blockId._id === block.id) {
            temp.push({ name: flat.name, id: flat._id });
          }
        });
        setDropdownFlats([{ name: "Please select", id: "myall" }, ...temp]);
        setFlat(dropdownFlats[0]);
      }
    }
  }, [block]);

  useEffect(() => {
    const temp = blocks.map((block) => ({ name: block.name, id: block._id }));
    setDropdownBlocks([{ name: "All", id: "myall" }, ...temp]);
  }, [blocks]);
  const handleChangeTab = (val) => {
    setError(false);
    setType(val);
    if (val == "individual") {
      dropdownBlocks[0] = { name: "Please select", id: "myall" };
      dropdownFlats[0] = { name: "Please select", id: "myall" };
      setIndividualList([])
    } else {
      dropdownBlocks[0] = { name: "All", id: "myall" };
      dropdownFlats[0] = { name: "All", id: "myall" };
      setIndividualCumulative([])
    }
  };
  const handleSubmit = (val) => {
    var error = false;
    if (type == "individual") {
      if (block.id == "myall") {
        setError(true);
        setErrorMessage("Please select Block");
        error = true;
      } else if (flat.id == "myall") {
        setError(true);
        setErrorMessage("Please select Flat");
        error = true;
      }
    }
    if (!error) {
      props.fetchStart();
      val && setPage(1);
      let blockId = "All";
      if (block.id !== "myall") {
        blockId = block.id;
      }
      let flatId = "All";
      if (flat.id !== "myall") {
        flatId = flat.id;
      }
      let start = "";
      let end = "";
      if (period.name === "Custom") {
        start = startDate.format(fmt);
        end = endDate.format(fmt);
      }
      const url = `&period=${period.name}&blockId=${blockId}&flatId=${flatId}&startDate=${start}&endDate=${end}&pagination=${2000}&page=${page}`;
      if (type === "cummulative") {
        props.cumulative_report(url);
      } else if (type == "individual") {
        props.individual_report(url);
      } else {
        props.cumulative_detail_report(url);
      }
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [page, rows]);


  useEffect(() => {
    setIndividualCumulative(reports.individualCumulative)
    setIndividualList(reports.individual)
  }, [reports.individualCumulative, reports.individual]);

  

  return (
    <div>
      <div className="gx-d-flex gx-align-items-end">
        { type !== "individualAll" &&  <Dropdown
          title="block"
          data={dropdownBlocks}
          disabled={type === "individualAll" ? true : false}
          value={block}
          setValue={setBlock}
          setError={setError}
        />}

        {type === "individual" && type !== "individualAll" && (
          <Dropdown
            title="flat"
            data={dropdownFlats}
            value={flat}
            setValue={setFlat}
            width={100}
            setError={setError}
          />
        )}
        <Dropdown
          title="period"
          data={dropdownPeriod}
          value={period}
          setValue={setPeriod}
          width={100}
          setError={setError}
        />
        {period.name === "Custom" && (
          <CustomRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        )}
        <Button
          type="primary"
          className="gx-mb-0"
          loading={common.loading}
          onClick={() => handleSubmit(true)}
        >
          Submit
        </Button>
        <DownloadDataButton data={reports} type={type} utilities={utilities} period={period.name} />
      </div>
      <div className="gx-mt-4">
        <Tabs type="card" onTabClick={(val) => handleChangeTab(val)}>
          <TabPane tab="Cumulative Readings" key="cummulative">
            <Cumulative
              data={reports.cumulative}
              utilities={utilities}
              setPage={setPage}
              page={page}
            />
          </TabPane>
          <TabPane tab="Individual Readings" key="individual">
            <Individual
              data={individualList}
              utilities={utilities}
              period={period}
              setPage={setPage}
              page={page}
              setRows={setRows}
            />
          </TabPane>
          <TabPane tab="All Readings" key="individualAll">
            <IndividualAll
              data={individualCumulative}
              utilities={utilities}
              period={period}
            />
          </TabPane>
        </Tabs>
      </div>
      {error && (block.id == "myall" || flat.id == "myall")
        ? message.error(errorMsg.toString())
        : null}
      {/* {error && flat.id == "myall" ? message.error(errorMsg.toString()) : null} */}
    </div>
  );
};

export default connect(
  (state) => ({
    blocks: state.Block.blocks,
    flats: state.flats.flats_list,
    utilities: state.utility.utilities,
    common: state.common,
    reports: state.reports,
  }),
  {
    get_all_blocks,
    get_all_flats,
    get_all_utilities,
    cumulative_report,
    individual_report,
    cumulative_detail_report,
    fetchStart,
  }
)(DropdownGroup);
