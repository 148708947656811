import {
  GET_ALL_FLATS_SUCCESS,
  GET_ALL_FLATS_BLOCK_ID_SUCCESS,
  GET_ALL_FLATS_ID_SUCCESS,
  GET_ALL_FLATS_BALANCE_SUCCESS,
  GET_FLATS_TRANSACTIONS_SUCCESS,
  GET_FLAT_BLOCK_NAME_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  flats_list: [],
  block_flats: [],
  flats_id_list: {},
  flats_wallet_balance: [],
  flats_transactions: { data: [], count: 0 },
  block_flat_name : []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_FLATS_SUCCESS: {
      return {
        ...state,
        flats_list: action.payload || [],
      };
    }
    case GET_ALL_FLATS_BLOCK_ID_SUCCESS: {
      return {
        ...state,
        block_flats: action.payload || [],
      };
    }
    case GET_ALL_FLATS_ID_SUCCESS: {
      return {
        ...state,
        flats_id_list: action.payload || {},
      };
    }
    case GET_FLAT_BLOCK_NAME_SUCCESS: {
      return {
        ...state,
        block_flat_name: action.payload || {},
      };
    }
    case GET_ALL_FLATS_BALANCE_SUCCESS: {
      return {
        ...state,
        flats_wallet_balance: action.payload || [],
      };
    }
    case GET_FLATS_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        flats_transactions: action.payload || { data: [], count: 0 },
      };
    }

    default:
      return state;
  }
};
