import React, { useState } from "react";
import { Card } from "antd";
import DropdownGroup from "./Dropdowns";
import RechargeTable from "./meter";


const FailedMeter = () => {

    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [failedCount , setFailedCount] = useState(0);
    return (
        <Card className="gx-card" title="Failed Meters" extra={`Total : ${failedCount}`}>
            <div>
                <DropdownGroup 
                    page={page}
                    rows={rows} />
            </div>
            <div className="gx-mt-5">
                <RechargeTable 
                    page={page}
                    setFailedCount={setFailedCount}
                    setPage={setPage}
                    rows={rows}
                    setRows={setRows} />
            </div>
        </Card>
    );
};

export default FailedMeter;
