import React from "react";
import { Col, Row } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import {
  faBolt,
  faCarBattery,
  faTint,
  faFaucet,
  faBurn,
} from "@fortawesome/free-solid-svg-icons";
import { increamentData } from "../../Metrics/data";
import ChartCard from "components/dashboard/Crypto/ChartCard";
import Auxiliary from "util/Auxiliary";
import Portfolio from "components/dashboard/Crypto/Portfolio";
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import BalanceHistory from "components/dashboard/Crypto/BalanceHistory";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";
import { get_tariff } from "../../../../appRedux/actions/Tariff";
import { cumulative_week_report } from "../../../../appRedux/actions/Reports";

class Crypto extends React.Component {
  state = {
    dailyUsage: [],
    title: [],
    utility: {},
    count: {},
    totalPrice: [],
    weekly: [],
  };
  componentDidMount() {
    const projectId = JSON.parse(localStorage.getItem("projectId"));
    // url for cummulative week reports
    /*  const url = `&period=Week&blockId=All&flatId=All&startDate=&endDate=&pagination=10&page=1`;
    this.props.cumulative_week_report(url); */
    this.props.get_tariff();
    axios
      .get(`dashboard/data?projectId=${projectId}&period=Day`)
      .then((res) => {
        var keys = res.data.data[0] ? Object.keys(res.data.data[0]) : [];
        this.setState({
          dailyUsage: res.data.data[0],
          title: keys,
          count: res.data.projectCount,
        });
      })
      .catch((error) => error.response);
    this.props.get_all_utilities();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.utilities && this.props.utilities !== nextProps.utilities) {
      var utility = {};
      nextProps.utilities.map((list) => {
        utility[list.code] = list.name;
      });
      this.setState({ utility: utility });
      return true;
    } /*  else if (
      nextProps.utilities &&
      nextProps.weekly_usage &&
      this.props.weekly_usage !== nextProps.weekly_usage
    ) {
       const data = [...nextProps.weekly_usage.data];
      const keys = data[0] ? Object.keys(data[0]) : [];
      const temp = [];
      data.forEach((item) => {
        const dataitem = { date: item.date };
        nextProps.utilities.forEach((uti) => {
          if (keys.includes(uti.code)) {
            dataitem[uti.name] = item[uti.code];
          }
        });
        temp.push(dataitem);
      });
      this.setState({ weekly: temp });
      return true;
    } */ else {
      return true;
    }
  }
  getUnitsAndIcon(utilityType) {
    switch (utilityType) {
      case "EB":
        return { icon: faBolt, unit: "Units" };
      case "DG":
        return { icon: faCarBattery, unit: "Units" };
      case "GM":
        return { icon: faBurn, unit: "Kgs" };
      case "MWM":
        return { icon: faTint, unit: "Litres" };
      case "TWM":
        return { icon: faFaucet, unit: "Litres" };
      default:
        return { icon: "", unit: "" };
    }
  }
  render() {
    return (
      <Auxiliary>
        <Row>
          {this.state.title &&
            this.state.title.map((list, index) => {
              if (list !== "date") {
                const { icon, unit } = this.getUnitsAndIcon(list);
                return (
                  <Col xl={8} lg={12} md={12} sm={12} xs={24} key={index}>
                    <ChartCard
                      prize={
                        this.state.dailyUsage && this.state.dailyUsage[list]
                      }
                      title={unit}
                      icon={icon || "bitcoin"}
                      children={
                        <ResponsiveContainer width="100%" height={75}>
                          <AreaChart
                            data={increamentData}
                            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                          >
                            <defs>
                              <linearGradient
                                id="color4"
                                x1="0"
                                y1="0"
                                x2="1"
                                y2="0"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#090979"
                                  stopOpacity={0.9}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#00d4ff"
                                  stopOpacity={0.9}
                                />
                              </linearGradient>
                            </defs>
                            <Area
                              dataKey="price"
                              strokeWidth={0}
                              stackId="2"
                              stroke="#4D95F3"
                              fill="url(#color4)"
                              fillOpacity={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      }
                      desc={this.state.utility && this.state.utility[list]}
                    />
                  </Col>
                );
              }
            })}
        </Row>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <IconWithTextCard
              cardColor="cyan"
              icon="company"
              title={this.state.count && this.state.count.blocks}
              subTitle="Total Blocks"
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <IconWithTextCard
              cardColor="orange"
              icon="home"
              title={this.state.count && this.state.count.flats}
              subTitle="Total Flats"
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <IconWithTextCard
              cardColor="teal"
              icon="user"
              title={this.state.count && this.state.count.users}
              subTitle="Total users"
            />
          </Col>
        </Row>
        <Row className="gx-align-items-stretch">
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <Portfolio
              utilities={this.props.utilities}
              title={this.state.title}
              dailyUsage={this.state.dailyUsage}
              tariffPrice={this.props.tariffPrice}
            />
          </Col>
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <BalanceHistory data={this.state.weekly} />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps({ common, utility, tariff, reports }) {
  const { utilities } = utility;
  const { error_occurred, loading, show_message, warning_message } = common;
  const { tariffPrice } = tariff;
  return {
    tariffPrice,
    error_occurred,
    loading,
    show_message,
    warning_message,
    utilities,
    weekly_usage: reports.week,
  };
}
export default connect(mapStateToProps, {
  get_all_utilities,
  get_tariff,
  cumulative_week_report,
})(Crypto);
