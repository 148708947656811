import React from "react";
import { Select } from "antd";

const Option = Select.Option;

/**
 * @description `This component renders Select with option to search `
 * @param title title of select dropdown
 * @param data list of items for dropdown
 * @param value current value of the select dropdown state
 * @param setValue value setter for select dropdown state
 */

const SearchField = ({ title, data, value, setValue, disabled, width }) => {
  //? update state by finding selected item
  const handleChange = (id) => {
    const updatedValue = data.find((item) => item.id === id);
    setValue(updatedValue);
  };

  return (
    <div className="gx-mr-3">
      <h5>Select {title}</h5>
      <Select
        showSearch
        defaultValue={value.name}
        value={value.name}
        style={{ width: width || 150 }}
        disabled={disabled}
        optionFilterProp="children"
        onChange={handleChange}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {data.map((option, i) => {
          return (
            <Option value={option.id} key={option.id}>
              {option.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SearchField;
