import { expr } from "jquery";

// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";
//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
//Auth const
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_LOCAL_PERSIST = "SIGNIN_USER_LOCAL_PERSIST";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const CHANGE_PASSWORD_USER = "CHANGE_PASSWORD_USER";
export const CHANGE_PASSWORD_USER_SUCCESS = "CHANGE_PASSWORD_USER_SUCCESS";
export const CHANGE_TRANSACTION_PASSWORD_USER = "CHANGE_TRANSACTION_PASSWORD_USER";
export const CHANGE_TRANSACTION_PASSWORD_USER_SUCCESS = "CHANGE_TRANSACTION_PASSWORD_USER_SUCCESS";
//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";
//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
//Blocks
export const GET_ALL_BLOCKS = "GET_ALL_BLOCKS";
export const GET_ALL_BLOCKS_SUCCESS = "GET_ALL_BLOCKS_SUCCESS";
export const POST_BLOCK = "POST_BLOCK";
export const POST_BLOCK_SUCCESS = "POST_BLOCK_SUCCESS";
export const UPDATE_BLOCK = "UPDATE_BLOCK";
//FLATS
export const GET_ALL_FLATS = "GET_ALL_FLATS";
export const GET_FLAT_BLOCK_NAME = "GET_FLAT_BLOCK_NAME"
export const GET_ALL_FLATS_SUCCESS = "GET_ALL_FLATS_SUCCESS";
export const POST_FLAT = "POST_FLAT";
export const POST_FLAT_SUCCESS = "POST_FLAT_SUCCESS";
export const GET_ALL_FLATS_BLOCK_ID = "GET_ALL_FLATS_BLOCK_ID";
export const GET_ALL_FLATS_BLOCK_ID_SUCCESS = "GET_ALL_FLATS_BLOCK_ID_SUCCESS";
export const GET_ALL_FLATS_ID = "GET_ALL_FLATS_ID";
export const GET_ALL_FLATS_ID_SUCCESS = "GET_ALL_FLATS_ID_SUCCESS";
export const UPDATE_FLAT = "UPDATE_FLAT";
export const GET_ALL_FLATS_BALANCE = "GET_ALL_FLATS_BALANCE";
export const GET_ALL_FLATS_BALANCE_SUCCESS = "GET_ALL_FLATS_BALANCE_SUCCESS";
export const RECHARGE_WALLET = "RECHARGE_WALLET";
export const GET_FLATS_TRANSACTIONS = "GET_FLATS_TRANSACTIONS";
export const GET_FLATS_TRANSACTIONS_SUCCESS = "GET_FLATS_TRANSACTIONS_SUCCESS";
export const GET_FLAT_BLOCK_NAME_SUCCESS = "GET_FLAT_BLOCK_NAME_SUCCESS"
//Devices
export const GET_ALL_DEVICES = "GET_ALL_DEVICES";
export const EDIT_DEVICE = "EDIT_DEVICE";
export const RESET_METER_DEVICE = "RESET_METER_DEVICE"
export const UNMAP_PROJECT_DEVICE = "UNMAP_PROJECT_DEVICE"
export const GET_ALL_DEVICES_SUCCESS = "GET_ALL_DEVICES_SUCCESS";
export const MAP_DEVICE_DATA = "MAP_DEVICE_DATA";
export const GET_ALL_DEVICES_IN_USE = "GET_ALL_DEVICES_IN_USE";
export const GET_ALL_DEVICES_IN_USE_SUCCESS = "GET_ALL_DEVICES_IN_USE_SUCCESS";
export const GET_FLAT_DEVICE_READING = "GET_FLAT_DEVICE_READING";
export const GET_FLAT_DEVICE_READING_SUCCESS =
  "GET_FLAT_DEVICE_READING_SUCCESS";
export const RELAY_DEVICE = "RELAY_DEVICE";
export const GET_ALL_FLAT_DEVICES = "GET_ALL_FLAT_DEVICES";
export const GET_ALL_FLAT_DEVICES_SUCCESS = "GET_ALL_FLAT_DEVICES_SUCCESS";
//users
export const POST_USER = "POST_USER";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USERS_PHONE_NUMBER_SUCCESS = "GET_USERS_PHONE_NUMBER_SUCCESS";
export const GET_USERS_PHONE_NUMBER = "GET_USERS_PHONE_NUMBER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER_TICKETS = "GET_USER_TICKETS"
export const GET_USER_TICKETS_SUCCESS = "GET_USER_TICKETS_SUCCESS"
export const UPDATE_USER_TICKET = "UPDATE_USER_TICKET"
//utilites
export const GET_ALL_UTILITIES = "GET_ALL_UTILITIES";
export const GET_ALL_UTILITIES_SUCCESS = "GET_ALL_UTILITIES_SUCCESS";
export const GET_FAILED_UTILITES = "GET_FAILED_UTILITES"
export const GET_FAILED_UTILITES_SUCCESS = "GET_FAILED_UTILITES_SUCCESS"

//Reports
export const CUMULATIVE_REPORT = "CUMULATIVE_REPORT";
export const CUMULATIVE_REPORT_SUCCESS = "CUMULATIVE_REPORT_SUCCESS";
export const CUMULATIVE_WEEK_REPORT = "CUMULATIVE_WEEK_REPORT";
export const CUMULATIVE_WEEK_REPORT_SUCCESS = "CUMULATIVE_WEEK_REPORT_SUCCESS";
export const INDIVIDUAL_REPORT = "INDIVIDUAL_REPORT";
export const INDIVIDUAL_REPORT_SUCCESS = "INDIVIDUAL_REPORT_SUCCESS";
export const CUMULATIVE_DETAIL_REPORT = "CUMULATIVE_DETAIL_REPORT";
export const INDIVIDUAL_BLOCK_REPORT = "INDIVIDUAL_BLOCK_REPORT";
export const INDIVIDUAL_BLOCK_REPORT_SUCCESS =
  "INDIVIDUAL_BLOCK_REPORT_SUCCESS";
export const CUMULATIVE_DETAIL_REPORT_SUCCESS =
  "CUMULATIVE_DETAIL_REPORT_SUCCESS";

//Tariff
export const GET_TARIFF = "GET_TARIFF";
export const GET_TARIFF_SUCCESS = "GET_TARIFF_SUCCESS";
export const UPDATE_TARIFF = "UPDATE_TARIFF";
//Bills
export const GET_ALL_BILLS = "GET_ALL_BILLS";
export const GET_ALL_BILLS_SUCCESS = "GET_ALL_BILLS_SUCCESS";
export const GENERATE_BILLS = "GENERATE_BILLS";
export const UPDATE_BILL_DATE_REQUEST = "UPDATE_BILL_DATE_REQUEST";
export const UPDATE_BILL_DATE_SUCCESS = "UPDATE_BILL_DATE_SUCCESS";
export const SEND_BILL_REQUEST = "SEND_BILL_REQUEST";

