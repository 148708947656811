import React from "react";
import { Card } from "antd";
import Bills from "./Bills";
import Modal from "./Modal";

const Index = () => {
  return (
    <Card className="gx-card">
      <Modal />
      <Bills />
    </Card>
  );
};

export default Index;
