import React from "react";
import { connect } from "react-redux";
import { message } from "antd";
import Tariff from "./tarif";

const Index = ({common, ...props}) => {
  return (
    <div>
      <Tariff />;
      {common.action_type == "tariff_error" ?  message.error(common.show_message.toString()) : null}
      {common.action_type == "tariff_success" ?  message.success(common.show_message.toString()) : null}
    </div>
  );
};

export default connect(
    (state) => ({
      common: state.common
    })
  )(Index);