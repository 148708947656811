import React, { useEffect, useState } from "react";
import { Table, message, Typography } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import MyModal from "./Modal";

const { Text } = Typography;

const Feedback = ({ tickets_list, ...props }) => {
    const [tickets, SetTickets] = useState([]);

    const successHandler = (record) => {
        const newData = tickets
        const index = newData.findIndex((item) => record._id === item._id);
        if (index > -1) {
            newData.splice(index, 1);
            SetTickets(newData);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "userData[0].ownerName",
            width: "15%",
        },
        {
            title: "Phone",
            dataIndex: "userData[0].ownerMobile",
            width: "15%",
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "25%",
        },
        {
            title: "Description",
            dataIndex: "description",
            width: "25%",
        },
        {
            title: "Reported Date",
            dataIndex: "createdAt",
            width: "10%",
            render: (value) => moment(value).format("DD-MM-YYYY"),
        },
        {
            title: "Action",
            dataIndex: "status",
            width: "10%",
            render: (value, record) => {
                return value == 1000 ? <MyModal record={record} successHandler={successHandler} /> : <Text type="warning">Ticket Closed</Text>;
            },
        },
    ];
    useEffect(() => {
        SetTickets(tickets_list)
    }, [tickets_list])
    return (
        <div>
            <Table
                pagination={true}
                bordered
                rowKey="_id"
                columns={columns}
                dataSource={tickets}
            />
            {props.common.action_type == "update_user_ticket"
                ? message.success(props.common.show_message)
                : null}
        </div>
    );
};

export default connect((state) => ({
    tickets_list: state.users.tickets_list,
    common: state.common,
}))(Feedback);
