import { GET_ALL_BILLS_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  bills: [],
  count: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_BILLS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
