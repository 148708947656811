import React from "react";
import { Card } from "antd";
import UserList from "./UserList/user";
import TestList from "./Test";
import Users from "./Users";

const Index = () => {
  return (
    <Card className="gx-card">
      <Users />;
    </Card>
  );
};

export default Index;
