import React from "react";
import { connect } from "react-redux";
import { Table, Form, Card, Tag, message, Button } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";
import { get_all_blocks } from "../../../../appRedux/actions/Block.js";
import { get_flat_block_names } from "../../../../appRedux/actions/Flat";
import {
  get_all_devices_in_use,
  relay_device,
} from "../../../../appRedux/actions/Device";
import { fetchStart } from "../../../../appRedux/actions/Common";
import CircularProgress from "components/CircularProgress/index";
import DeviceUtilitiesReadings from "./DeviceUtilitiesReadings";

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list_devices: [], editingKey: "", blocks : [] };
    const handleDeviceOff = async (record) => {
      var payload = {
        code: "OFF",
        EUI: record.mac_id,
      };
      this.props.relay_device(payload);
    };
    const handleDeviceON = async (record) => {
      var payload = {
        code: "ON",
        EUI: record.mac_id,
      };
      this.props.relay_device(payload);
    };
    this.columns = [
      {
        title: "Device Id",
        dataIndex: "device_id",
        width: "25%",
      },
      {
        title: "Mac Id",
        dataIndex: "mac_id",
        width: "25%",
        render: (value) => {
          const basepath = "readings/details?";
          const flat = this.state.list_devices.find(
            (item) => item.mac_id === value
          );
          const pid = flat && flat.projectId ? flat.projectId : null;
          const bid = flat && flat.blockId ? flat.blockId._id : null;
          const fid = flat && flat.flatId ? flat.flatId._id : null;
          const routePath =
            basepath +
            `projectId=${pid}&date=${moment()
              .utc()
              .format("YYYY-MM-DD")}&blockId=${bid}&flatId=${fid}`;

          return <Link to={routePath}>{value}</Link>;
        },
      },
      {
        title: "Last seen",
        dataIndex: "updatedAt",
        width: "25%",
        render: (value) => {
          return (
            <span>
              {moment(value)
                .fromNow()}
            </span>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "25%",
        render: (value) => {
          const color = value === 1001 ? "green" : "volcano";
          const name = value === 1001 ? "Active" : "In-Active";
          return <span>{<Tag color={color}>{name}</Tag>}</span>;
        },
      },
    ];
  }

  handleIndividualValues = (record, index) => {
    let blockNames = {}
    this.state.blocks.length && this.state.blocks.map(blockList => {
      blockNames[blockList._id] = blockList.name
    })
    let recordsData = []
    let multiDeviceReadings = record.multiUtilityReadings
    if(record.flatData && record.flatData.length){
      record.flatData.map(flatList => {
       const searchDevice = multiDeviceReadings.find((device) => device.flatId == flatList._id)
       if(searchDevice){
        searchDevice.flatName = flatList.name
        searchDevice.blockName = blockNames[flatList.blockId]
        recordsData.push(searchDevice)
       }
      })
    }
    return <DeviceUtilitiesReadings record={recordsData} utilities={record.utilities} index={index} />
  };
  componentDidMount() {
    this.props.fetchStart();
    //this.props.get_all_devices_in_use();
    this.props.get_all_blocks();
    this.props.get_flat_block_names()
    this.props.get_all_utilities();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.block_flat_name !== nextProps.block_flat_name) {
      this.setState({ list_devices: nextProps.block_flat_name });
      return false;
    } else if (this.props.blocks !== nextProps.blocks) {
      this.setState({ blocks: nextProps.blocks });
      return false;
    }
    else {
      return true;
    }
  }
  render() {
    // const expandedRowRender = (record) => {
    //   const columns = [
    //     { title: "Block Name", dataIndex: "blockId.name", key: "blockname" },
    //     { title: "Flat Name", dataIndex: "name", key: "name" },
    //     { title: "Flat Sq ft", dataIndex: "sqft", key: "sqft" },
    //     { title: "BHK", dataIndex: "bhk", key: "bhk" },
    //   ];
    //   return <Table columns={columns} pagination={false} />;
    // };
    const {
      loading,
      error_occurred,
      show_message,
      action_type,
      utilities,
    } = this.props;
    return (
      <div>
          <Table
            bordered
            dataSource={this.state.list_devices}
            columns={this.columns}
            expandedRowRender={this.handleIndividualValues}
            rowClassName="editable-row"
            pagination={true}
          />
          <div>
            {loading ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {error_occurred ? message.error(show_message.toString()) : null}
          </div>
        {action_type == "relay_device" ? message.success(show_message) : null}
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

function mapStateToProps({ common, device, utility, flats, Block }) {
  const { list_devices } = device;
  const { blocks } = Block;
  const {
    error_occurred,
    loading,
    show_message,
    warning_message,
    action_type,
  } = common;
  const { utilities } = utility;
  const { block_flat_name } = flats
  return {
    utilities,
    list_devices,
    error_occurred,
    loading,
    show_message,
    warning_message,
    action_type,
    block_flat_name,
    blocks
  };
}
export default connect(mapStateToProps, {
  get_all_devices_in_use,
  relay_device,
  fetchStart,
  get_all_utilities,
  get_flat_block_names,
  get_all_blocks
})(EditableFormTable);
