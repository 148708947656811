import React from "react";
import Widget from "components/Widget/index";
import { Button, Col, Row } from "antd";
import LineIndicator from "./LineIndicator";

function formatNumber(x = 0) {
  x = x.toFixed(0);
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return res;
}

const colors = ["primary", "pink", "orange", "teal", "purple"];

const Portfolio = ({ utilities, title, dailyUsage, tariffPrice }) => {
  const data = [];
  if (utilities.length && tariffPrice.tariff && title.length && dailyUsage) {
    //? match utility id with tariff utility id then push data
    utilities.forEach((util) => {
      if (title.includes(util.code)) {
        tariffPrice.tariff.forEach((tari) => {
          if (tari.utilityId === util._id) {
            data.push({
              name: util.name,
              _id: util._id,
              usage: dailyUsage[util.code],
              price: tari.price,
            });
          }
        });
      }
    });
  }
  const totalCost = data.reduce((acc, curr) => {
    return acc + curr.usage * curr.price;
  }, 0);

  return (
    <Widget>
      <Row className="gx-align-items-center">
        <Col lg={12} md={12} sm={12} xs={24}>
          <h2 className="h2 gx-font-weight-medium gx-fs-xxl gx-mt-1">
            Total Cost
          </h2>
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <div className="ant-row-flex">
            <h2 className="gx-mr-2 gx-mb-0 gx-fs-xxxl gx-font-weight-medium">
              &#8377;{formatNumber(totalCost)}
            </h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={48}>
          <div className="gx-site-dash gx-mt-1">
            <h5 className="gx-mb-3">Utilities Distribution</h5>
            <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
              {data.map((item, i) => {
                const total = item.price * item.usage;
                const width = Number((total / totalCost) * 100).toFixed(2);
                if (total > 0) {
                  return (
                    <li key={item._id}>
                      <LineIndicator
                        width={`${width}%`}
                        title={item.name}
                        title2={`${item.price}`}
                        color={colors[i]}
                        value={`${width}%`}
                      />
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        </Col>
      </Row>
    </Widget>
  );
};

export default Portfolio;
