import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Blocks from "./Block";
import Flats from "./Flat";
import Device from "./Device";
import Users from "./User";
import Utility from "./Utility";
import Reports from "./Reports";
import Tariff from "./Tariff";
import Bills from "./Bills";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  common: Common,
  Block: Blocks,
  flats: Flats,
  device: Device,
  users: Users,
  utility: Utility,
  reports: Reports,
  tariff: Tariff,
  bills: Bills,
});

export default reducers;
