import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Table,
  Icon,
  Tooltip,
  Tag,
  message,
  Button,
  Checkbox,
  Popconfirm,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";

import {
  get_all_flat_devices,
  relay_device,
  edit_device,
} from "../../../../appRedux/actions/Device";
// import { fetchStart } from "../../../../appRedux/actions/Common";
// import CircularProgress from "components/CircularProgress/index";
// import DeviceUtilitiesReadings from "./DeviceUtilitiesReadings";

const FlatDevices = ({
  users,
  common,
  all_flat_readings,
  utilities,
  ...props
}) => {
  const [records, setRecords] = useState([]);

  const [flatUtilites, setFlatUtilities] = useState([]);
  const { action_type, show_message } = common;
  useEffect(() => {
    props.get_all_flat_devices();
    props.get_all_utilities();
  }, []);

  // useEffect(() => {
  //   props.get_all_flat_devices();
  //   props.get_all_utilities();
  // }, [checkBoxData]);

  useEffect(() => {
    setRecords(all_flat_readings);
    setFlatUtilities(utilities);
  }, [all_flat_readings, utilities]);
  const expandedRowRender = (record, flatUtilites) => {
    const availableUtilities = [];
    flatUtilites.forEach((item) => {
      const { code } = item;
      record.utilityData.forEach((uItem) => {
        if (code == uItem.code) {
          return availableUtilities.push(item);
        }
      });
    });
    const handleDeviceON = async (record) => {
      var payload = {
        channel: record.multiUtilityReadings.channel,
        device_id: record.device_id,
        mac_id: record.mac_id,
        relayType: "on",
      };
      props.relay_device(payload);
      const newData = [...records];
      const index = newData.findIndex(
        (item) => record.flatData._id === item.flatData._id
      );
      if (index > -1) {
        const item = newData[index];
        item.multiUtilityReadings.deviceState = 1;
        newData[index] = item;
        setRecords(newData);
      }
    };

    const handleDeviceOff = async (record) => {
      var payload = {
        channel: record.multiUtilityReadings.channel,
        device_id: record.device_id,
        mac_id: record.mac_id,
        relayType: "off",
      };
      props.relay_device(payload);
      const newData = [...records];
      const index = newData.findIndex(
        (item) => record.flatData._id === item.flatData._id
      );
      if (index > -1) {
        const item = newData[index];
        item.multiUtilityReadings.deviceState = 0;
        newData[index] = item;
        setRecords(newData);
      }
    };
    const autoTurnOffDevice = (record) => {
      const newData = [...records];
      const index = newData.findIndex(
        (item) => record.flatData._id === item.flatData._id
      );
      if (index > -1) {
        const item = newData[index];
        item.multiUtilityReadings.autoOff = item.multiUtilityReadings.autoOff
          ? false
          : true;
        props.edit_device(item);
        newData[index] = item;
        setRecords(newData);
      }
    };

    const columns = [
      {
        title: "Device Channel",
        dataIndex: "multiUtilityReadings.channel",
        key: "channel",
        width: "10%",
        render: (value) => {
          return record.nodeType == "Multi-Node" ? value : 'Single-Node';
        }
      },
    ];
    availableUtilities.map((list) => {
      if (list.name != "Maintenance") {
        var obj = {
          title: list.name,
          width: "10%",
        };
        if(record.nodeType == "Multi-Node"){
          obj.dataIndex = "multiUtilityReadings.utilityReadings." + list.code;
          obj.key = list.code;
        }else{
          obj.dataIndex = 'currentReading';
          obj.key = record.currentReading;
        }
        columns.push(obj);
      }
    });
    if (record.nodeType == "Multi-Node") {
      columns.push(
        {
          title: "Balance",
          dataIndex: "multiUtilityReadings.utilityReadings.Bal",
          width: "10%",
          key: "Bal",
          render: (value) => {
            return value ? Math.round(value * 100) / 100 : 0;
          },
        },
        {
          title: "Turn on",
          dataIndex: "on",
          key: "on",
          width: "10%",
          render: (_, record) => {
            return (
              <span>
                {
                  <Button type="primary" onClick={() => handleDeviceON(record)}>
                    Turn On
                  </Button>
                }
              </span>
            );
          },
        },
        {
          title: "Turn off",
          dataIndex: "off",
          key: "off",
          width: "10%",
          render: (_, record) => {
            return (
              <span>
                {
                  <Button type="danger" onClick={() => handleDeviceOff(record)}>
                    Turn Off
                  </Button>
                }
              </span>
            );
          },
        },
        {
          title: "Device status",
          dataIndex: "multiUtilityReadings.deviceState",
          key: "deviceState",
          width: "10%",
          render: (value, record) => {
            let color = value === 1 ? "green" : "volcano";
            let name = value === 1 ? "Turned On" : "Turned Off";
            if (!record.multiUtilityReadings.hasOwnProperty("deviceState")) {
              color = "green";
              name = "Turned On";
            }
            return <span>{<Tag color={color}>{name}</Tag>}</span>;
          },
        },
        {
          title: "Auto off",
          dataIndex: "multiUtilityReadings.autoOff",
          key: "autoOff",
          width: "10%",
          render: (value, record) => {
            return (
              <span>
                <Popconfirm
                  title={
                    value
                      ? "Are you sure to De-Activate Auto turn off"
                      : "Are you sure to Active auto turn off"
                  }
                  onConfirm={() => autoTurnOffDevice(record)}
                >
                  <a>
                    <Checkbox checked={value}>
                      <span className="gx-link">Auto Off</span>
                      <Tooltip title="If Auto off is active device get turn off automatically when wallet balance is less than zero">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </Checkbox>
                  </a>
                </Popconfirm>
              </span>
            );
          },
        }
      );
    }
    return (
      <Table
        columns={columns}
        dataSource={record ? [record] : []}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: "Block",
      dataIndex: "blockData[0].name",
      width: "15%",
    },
    {
      title: "Flat",
      dataIndex: "flatData.name",
      width: "15%",
    },
    {
      title: "Device Name",
      dataIndex: "device_id",
      width: "15%",
    },
    {
      title: "Mac Id ",
      dataIndex: "mac_id",
      width: "15%",
    },
    {
      title: "SFT",
      dataIndex: "flatData.sft",
      width: "15%",
    },
    {
      title: "BHK",
      dataIndex: "flatData.bhk",
      width: "15%",
    },
    {
      title: "Last Sync",
      dataIndex: "timestamp",
      width: "25%",
      render: (value) => {
        return <span>{value ? moment(value).fromNow() : "-"}</span>;
      },
    },
  ];
  return (
    <div>
      <div>
        <Table
          className="componets-flats-table"
          columns={columns}
          expandedRowRender={(record) =>
            expandedRowRender(record, flatUtilites)
          }
          dataSource={records}
          rowKey={(records) =>
            records.nodeType == "Multi-Node"
              ? records.device_id + records.multiUtilityReadings.channel
              : records.device_id
          }
          bordered
          pagination={true}
        />
      </div>
      <div>
        {action_type == "auto_off" ? message.success(show_message) : null}
        {action_type == "relay_device" ? message.success(show_message) : null}
        {action_type == "error_relay_device"
          ? message.error(show_message)
          : null}
      </div>
    </div>
  );
};
export default connect(
  (state) => ({
    users: state.users.users_list,
    common: state.common,
    all_flat_readings: state.device.all_flat_readings,
    utilities: state.utility.utilities,
  }),
  { get_all_flat_devices, get_all_utilities, edit_device, relay_device }
)(FlatDevices);
