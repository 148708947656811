import {
  GET_TARIFF_SUCCESS,
  UPDATE_BILL_DATE_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  tariffPrice: { tariff: [] },
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TARIFF_SUCCESS: {
      return {
        ...state,
        tariffPrice: action.payload || { tariff: [] },
      };
    }
    case UPDATE_BILL_DATE_SUCCESS:
      return {
        ...state,
        tariffPrice: {
          ...state.tariffPrice,
          billDate: action.payload,
        },
      };
    default:
      return state;
  }
};
