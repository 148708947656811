import React from "react";

import Widget from "components/Widget/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// function formatNumber(x) {
//   x = x.toString();
//   let lastThree = x.substring(x.length - 3);
//   let otherNumbers = x.substring(0, x.length - 3);
//   if (otherNumbers != "") lastThree = "," + lastThree;
//   const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
//   return res;
// }

const ChartCard = ({ prize, title, children, styleName, desc, icon }) => {
  return (
    <Widget styleName="gx-card-full">
      <div className="gx-actchart gx-px-3 gx-pt-3">
        <div className="ant-row-flex">
          <h2 className="gx-mb-0 gx-fs-xxl gx-font-weight-medium">
            {prize}
            <span
              className={`gx-mb-0 gx-ml-2 gx-pt-xl-2 gx-fs-md gx-text-grey gx-chart-${styleName}`}
            >
              {title}
            </span>
          </h2>
          <FontAwesomeIcon
            icon={icon}
            color={"#1D77BF"}
            className={`icon gx-fs-xl gx-ml-auto`}
          />
        </div>
        <p className="gx-mb-0 gx-fs-sm gx-text-grey">{desc}</p>
      </div>
      {children}
    </Widget>
  );
};

export default ChartCard;
