import React, { useEffect, useState } from "react";
import { Button, Modal, InputNumber, Popconfirm, Input, Radio, Typography  } from "antd";
import { connect } from "react-redux";
import { fetchStart } from "../../../../appRedux/actions/Common";
import { update_user_ticket } from "../../../../appRedux/actions/User";

const RadioGroup = Radio.Group;
const { Text } = Typography;
const MyModal = ({ record, loading, successHandler, ...props }) => {
  const { TextArea } = Input;
  const [type, setType] = useState("");
  const [remarks, setRemarks] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setConfirmLoading(false);
  }, [loading]);

  const handleOk = () => {
    const payload = {
        id : record._id,
        remarks: remarks,
    }
    if(type == "close"){
        payload.status = 1001;
    }
    if(type == "close"){
        successHandler(record);
        props.fetchStart();
        props.update_user_ticket(payload);
        setConfirmLoading(true);
        setVisible(false);
    }else{
        setError(true)
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onChangMark = (e) => {
    if(e.target.value == "close"){
        setError(false)
    }
    setType(e.target.value)
  }

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        View Ticket
      </Button>
      <Modal
        title="Select the required details"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
        <div className="gx-mb-2">{"Title"}</div>
        <div className="gx-mb-2">
        <TextArea defaultValue={record.title} autoSize={true} />
        </div>
        </div>
        <div>
        <div className="gx-mb-2">{"Description"}</div>
        <div className="gx-mb-2">
        <TextArea defaultValue={record.description} autoSize={true}/>
          </div>
        </div>
        <div>
        <div className="gx-mb-2">{"Admin remarks"}</div>
        <div className="gx-mb-2">
        <TextArea placeholder="Please enter admin remarks to close the ticket" onChange={(text) => setRemarks(text.target.value)}/>
          </div>
        </div>
        <div style={{ margin: "16px 0" }}>
          <div className="gx-mb-2">{"Check mark to close the ticket"}</div>
          <div className="gx-mb-2">
            <RadioGroup value={type} onChange={onChangMark}>
              <Radio value={"close"}>Close the ticket</Radio>
            </RadioGroup>
          </div>
        </div>
        {error ? <Text type="danger">Please check the close ticket option.</Text> : ""}
      </Modal>
    </div>
  );
};

export default connect(
  (state) => ({
    loading: state.common.loading,
  }),
  { fetchStart, update_user_ticket}
)(MyModal);
