import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_BLOCKS,
  POST_BLOCK,
  UPDATE_BLOCK,
} from "../../constants/ActionTypes";

import { get_all_blocks_success } from "../actions/Block";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";

import axios from "axios";
const createNewBlock = async (payload) =>
  await axios
    .post("/block", payload)
    .then((res) => res.data.data)
    .catch((error) => error);

const fetchBlocks = async (projectId) =>
  await axios
    .get(`/block?projectId=${projectId}`)
    .then((res) => res.data)
    .catch((error) => error);

const putBlock = async (data, id) =>
  await axios
    .put(`/update/block/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

function* getBlocks() {
  try {
    const projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(fetchBlocks, projectId);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
      yield put(get_all_blocks_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* postNewBlock({ payload }) {
  try {
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(createNewBlock, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      var message = data.message;
      var action_type = "new_blocK_success";
      yield put(fetchSuccess({ message, action_type }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* updateBlocks({ payload }) {
  try {
    const { _id } = payload;
    payload.projectId = JSON.parse(localStorage.getItem("projectId"));
    const data = yield call(putBlock, payload, _id);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess(data.status));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* getAllBlocks() {
  yield takeEvery(GET_ALL_BLOCKS, getBlocks);
}
export function* postBlock() {
  yield takeEvery(POST_BLOCK, postNewBlock);
}
export function* updateBlock() {
  yield takeEvery(UPDATE_BLOCK, updateBlocks);
}

export default function* rootSaga() {
  yield all([fork(getAllBlocks), fork(postBlock), fork(updateBlock)]);
}
