import {
  GET_USER_SUCCESS,
  GET_USER_BY_ID_SUCCESS,
  GET_USERS_PHONE_NUMBER_SUCCESS,
  GET_USER_TICKETS_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  users_list: { data: [], count: 0 },
  user_list: [],
  users_phone_number: [],
  user_data : {},
  tickets_list : []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS: {
      return {
        ...state,
        users_list: action.payload,
      };
    }
    case GET_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        user_data: action.payload,
      };
    }
    case GET_USERS_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        users_phone_number: action.payload,
      };
    }
    case GET_USER_TICKETS_SUCCESS: {
      return {
        ...state,
        tickets_list: action.payload,
      };
    }
    default:
      return state;
  }
};
