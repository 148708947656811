import React from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import FlatList from "./FlatList/flatlist";

class Flats extends React.Component {
  handleClick = this.handleClick.bind(this);

  handleClick() {
    this.props.history.push("/flats/addflat");
  }

  render() {
    var projectType = "";
    if (this.props.userData) {
      projectType = this.props.userData.projectMode;
    }
    return (
      <div>
        {projectType === "lora" && (
          <Button
            type="primary"
            onClick={this.handleClick}
            style={{ float: "right", "z-index": "1", top: "10px" }}
          >
            Add New Flat
          </Button>
        )}
        <div>
          <FlatList />
        </div>
      </div>
    );
  }
}
function mapStateToProps({ auth }) {
  const { userData, authUser } = auth;
  return { userData, authUser };
}
export default connect(mapStateToProps)(Flats);