import React, { useState } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import MainDropdownGroup from "./Dropdowns/Main";
import UsersTable from "./UserList/user";
import { withRouter } from "react-router-dom";

const Users = ({ ...props }) => {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const handleChange = (e) => {
    props.history.push("/users/adduser");
  };
  var projectType = "";
  if (props.userData) {
    projectType = props.userData.projectMode;
  }
  return (
    <div>
      <div>
        {projectType === "lora" && (
          <Button
            type="primary"
            onClick={handleChange}
            style={{ float: "right", "z-index": "1", top: "10px" }}
          >
            Add New User
          </Button>
        )}
      </div>
      <MainDropdownGroup page={page} rows={rows} />
      <br></br>
      <UsersTable page={page} setPage={setPage} rows={rows} setRows={setRows} />
    </div>
  );
};

function mapStateToProps({ auth }) {
  const { userData, authUser } = auth;
  return { userData, authUser };
}
export default connect(mapStateToProps)(withRouter(Users));

