import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  CUMULATIVE_REPORT,
  CUMULATIVE_WEEK_REPORT,
  INDIVIDUAL_REPORT,
  CUMULATIVE_DETAIL_REPORT,
  INDIVIDUAL_BLOCK_REPORT,
} from "../../constants/ActionTypes";

import {
  cumulative_report_success,
  individual_report_success,
  cumulative_week_report_success,
  cumulative_detail_report_success,
  individual_block_report_success,
} from "../actions/Reports";
import { fetchSuccess, fetchError, showMessage } from "../actions/Common";
import axios from "axios";
const fetchCumulativeReport = async (url) =>
  await axios
    .get(
      `/reports?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}${url}`
    )
    .then((res) => res.data)
    .catch((error) => error);

const fetchIndividualData = async (url) =>
  await axios
    .get(
      `/individual/reports?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}${url}`
    )
    .then((res) => res.data)
    .catch((error) => error);
const fetchIndividualCumulativeData = async (url) =>
  await axios
    .get(
      `/individual/reports/web?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}${url}`
    )
    .then((res) => res.data)
    .catch((error) => error);

const fetchIndividualBlockReportData = async (url) =>
  await axios
    .get(
      `/individual/blocks/reports?projectId=${JSON.parse(
        localStorage.getItem("projectId")
      )}${url}`
    )
    .then((res) => res.data)
    .catch((error) => error);
function* getCumulativeData({ payload }) {
  try {
    const data = yield call(fetchCumulativeReport, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess());
      yield put(
        cumulative_report_success({ data: data.data, count: data.totalCount })
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getCumulativeWeekData({ payload }) {
  try {
    const data = yield call(fetchCumulativeReport, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess());
      yield put(
        cumulative_week_report_success({
          data: data.data || [],
          count: data.totalCount || 0,
        })
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getIndividualData({ payload }) {
  try {
    const data = yield call(fetchIndividualData, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess());
      yield put(
        individual_report_success({ data: data.data, count: data.totalCount })
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getIndividualCumulativeData({ payload }) {
  try {
    const data = yield call(fetchIndividualCumulativeData, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess());
      yield put(
        cumulative_detail_report_success({
          data: data.data,
          count: 10,
        })
      );
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* getIndividualBlockReportData({ payload }) {
  try {
    const data = yield call(fetchIndividualBlockReportData, payload);
    if (data.status === "error") {
      yield put(showMessage(data.message));
    } else {
      yield put(fetchSuccess());
      yield put(individual_block_report_success(data.data));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}
export function* getCumulativeReport() {
  yield takeEvery(CUMULATIVE_REPORT, getCumulativeData);
}
export function* getCumulativeWeekReport() {
  yield takeEvery(CUMULATIVE_WEEK_REPORT, getCumulativeWeekData);
}
export function* getIndividualReport() {
  yield takeEvery(INDIVIDUAL_REPORT, getIndividualData);
}
export function* getIndividualCumulativeReport() {
  yield takeEvery(CUMULATIVE_DETAIL_REPORT, getIndividualCumulativeData);
}
export function* getIndividualBlockReport() {
  yield takeEvery(INDIVIDUAL_BLOCK_REPORT, getIndividualBlockReportData);
}

export default function* rootSaga() {
  yield all([
    fork(getCumulativeReport),
    fork(getCumulativeWeekReport),
    fork(getIndividualReport),
    fork(getIndividualCumulativeReport),
    fork(getIndividualBlockReport),
  ]);
}
