import React from "react";
import { Table, message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "./Modal";

const errorHandler = (index) => {
  // the callback. Use a better name
  console.log(index);
};
const columns = [
  {
    title: "Block Name",
    dataIndex: "blockId.name",
    width: "15%",
  },
  {
    title: "Flat Name",
    dataIndex: "flatId.name",
    width: "15%",
  },
  {
    title: "Name",
    dataIndex: "user.ownerName",
    width: "15%",
    // render: () => "",
  },
  {
    title: "Last Date",
    dataIndex: "flatId.lastDate",
    width: "15%",
    render: () => moment().format("YYYY/MM/DD"),
  },
  {
    title: "Last Amount",
    dataIndex: "transaction",
    width: "15%",
    // render: () => transaction == null ? 0 : transaction,
  },
  {
    title: "Wallet Balance",
    dataIndex: "walletBalance",
    width: "20%",
    render: (value) => <span>{Number(value).toFixed(2)}</span>,
  },
  {
    title: "Recharge",
    render: (value, record) => {
      return <Modal record={record} errorHandler={errorHandler} />;
    },
  },
];

const RechargeTable = (props) => {
  return (
    <div>
      <Table
        pagination
        bordered
        rowKey="_id"
        columns={columns}
        dataSource={props.flatBalances}
      />
      {props.common.action_type == "transaction_error"
        ? message.error(props.common.show_message)
        : null}
      {props.common.action_type == "transaction_success"
        ? message.success(props.common.show_message)
        : null}
    </div>
  );
};

export default connect((state) => ({
  flatBalances: state.flats.flats_wallet_balance,
  common: state.common,
}))(RechargeTable);
