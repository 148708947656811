import React, { useEffect, useState } from "react";
import { Button, Modal, InputNumber, Input, Radio, message } from "antd";
import { connect } from "react-redux";
import Moment from "moment";
import { recharge_wallet } from "../../../appRedux/actions/Flat";
import { fetchStart } from "../../../appRedux/actions/Common";

const RadioGroup = Radio.Group;

const MyModal = ({ record, loading, ...props }) => {
  const [amount, setAmount] = useState(1);
  const [type, setType] = useState("cash");
  const [password, setPassword] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showError, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [transactionId, setTransactionId] = useState(
    "Y0000" + Moment().format("x")
  );
  useEffect(() => {
    setConfirmLoading(false);
  }, [loading]);
  //TODO: setup a saga that first verifies password then pays bill
  const handleOk = () => {
    if (parseFloat(amount) != parseFloat(record.billAmount)) {
      setError(true);
    } else {
      setError(false);
      const payload = {
        flatId: record.flatId._id,
        blockId: record.blockId._id,
        amount: amount,
        userId: "",
        amountAfterTax: amount,
        paymentStatus: 0,
        type: "bill",
        transactionType: type,
        transactionId: transactionId,
        billId: record._id,
        versionCheck: false,
        password,
      };
      props.fetchStart();
      props.recharge_wallet(payload);
      setConfirmLoading(true);
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <div>
      <Button
        type="primary"
        disabled={record.paymentStatus == "fully-paid" ? true : false}
        onClick={() => setVisible(true)}
      >
        {record.paymentStatus == "fully-paid" ? "paid" : "pay"}
      </Button>
      <Modal
        title="Select the required details"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <div className="gx-mb-2">{"Enter transaction number"}</div>
          <div className="gx-mb-2">
            <Input
              placeholder="Transaction id"
              onChange={(e) => setTransactionId(e.target.value)}
              value={transactionId}
            />
          </div>
        </div>
        <div style={{ margin: "16px 0" }}>
          <div className="gx-mb-2">{"Select transaction type"}</div>
          <div className="gx-mb-2">
            <RadioGroup value={type} onChange={(e) => setType(e.target.value)}>
              <Radio value={"cash"}>Cash</Radio>
              <Radio value={"online"}>Online payment</Radio>
            </RadioGroup>
          </div>
        </div>
        <div>
          <div className="gx-mb-2">{"Enter Amount"}</div>
          <InputNumber
            min={1}
            defaultValue={1}
            onChange={(e) => setAmount(e)}
          />
        </div>
        <div style={{ margin: "16px 0" }}>
          {"Bill Amount : " + record.billAmount || 0}
        </div>
        <div>
          <Input
            type="password"
            placeholder="Enter password to confirm"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
      </Modal>
      {showError ? message.error("Amount should match bill amount") : null}
    </div>
  );
};

export default connect(
  (state) => ({
    loading: state.common.loading,
  }),
  { fetchStart, recharge_wallet }
)(MyModal);
