import React from "react";
import { Button } from "antd";
import moment from "moment";
import XLSX from "xlsx";

//? format  data for xlsx in json format
function generateExcel(indata, columns) {
  const utils = {};
  columns.forEach((item) => {
    // add all utilities except maintenance
    if (item.title !== "PDF") {
      utils[item.dataIndex] = item.title;
    }
  });
  const utilKeys = Object.keys(utils);
  const outdata = indata.map((item) => {
    const obj = {};
    utilKeys.forEach((key) => {
      if (key.split(".").length == 2) {
        obj[utils[key]] =
          item[key.split(".")[0]] != null &&
          item[key.split(".")[0]] != undefined
            ? item[key.split(".")[0]][key.split(".")[1]]
            : "";
      } else {
        if (key == "paymentStatus") {
          obj[utils[key]] = item[key] == 0 ? "Success" : "Failed";
        }else if(key == "timestamp"){
          obj[utils[key]] = moment(item[key]).format("YYYY/MM/DD hh:mm A");
        }
         else {
          obj[utils[key]] = item[key];
        }
      }
    });
    return obj;
  });
  return outdata;
}

const DownloadData = ({ data, columns }) => {
  //? download data as excel file
  const handleDownload = () => {
    const filename = `report ${"transactions"} ${moment().format(
      "YYYY/MM/DD"
    )}.xlsx`;
    const ws_name = `${"transactions"}`;
    const wb = XLSX.utils.book_new();
    let fileData = [];
    fileData = generateExcel(data.data, columns);
    const ws = XLSX.utils.json_to_sheet(fileData);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, filename);
  };

  //? disable download button if selected section data is empty
  const disabled = data.data ? !data.data.length : !data.data.length;

  return (
    <Button
      type="primary"
      disabled={disabled}
      className="gx-mb-0 gx-ml-auto"
      onClick={handleDownload}
    >
      Download
    </Button>
  );
};

export default DownloadData;
