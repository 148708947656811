import React, { Component } from "react";
import {
  Modal,
  Upload,
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Select,
  Tooltip,
} from "antd";
import { get_all_blocks } from "../../../../appRedux/actions/Block.js";
import {
  get_all_flats_block_id,
  get_all_flats_id,
  get_all_flats,
} from "../../../../appRedux/actions/Flat";
import {
  post_user,
  get_user,
  get_user_by_id,
  update_user,
} from "../../../../appRedux/actions/User";
import { fetchStart } from "../../../../appRedux/actions/Common";
import { connect } from "react-redux";
const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;

class Registration extends Component {
  page_name = "Register User";
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    isEdit: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
    blocks: [],
    user_id: "",
    flats_id_list: [],
    flatList: [],
    block_id: "",
    userData: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      residence: "",
      projectId: "",
    },
  };

  /*****************************************  MOUNT LOGIC  **************************************************/

  componentDidMount() {
    if (this.props.match.params.id) {
      this.page_name = "Edit User";
      this.props.get_all_flats();
      this.props.get_user_by_id(this.props.match.params.id);
      this.setState({ isEdit: true, user_id: this.props.match.params.id });
    }
    this.props.fetchStart();
    this.props.get_all_blocks();
  }

  /*****************************************  MOUNT LOGIC  **************************************************/

  handleBlockChange = (e) => {
    if (e.length === 0) {
      //this.setState({blocks : []})
    } else {
      var obj = {
        block_id: e[e.length - 1],
      };
      this.props.get_all_flats_block_id(obj);
    }
  };

  handleRemoveBlock = (remove) => {
    if (remove !== "") {
      var get_flats_data = this.props.form.getFieldValue("flatId");
      if (get_flats_data !== undefined) {
        this.setState({ flats_id_list: [], block_id: remove });
        get_flats_data.map((list) => {
          this.props.get_all_flats_id(list);
        });
      }
    }
  };

  handleRemoveFlats(data) {
    var get_flats_data = this.props.form.getFieldValue("flatId");
    var get_block_id = this.state.block_id;
    if (get_flats_data.length === data.length) {
      var set_arr = [];
      data.filter(function (list) {
        if (get_block_id !== list.blockId) {
          set_arr.push(list._id);
          return;
        }
      });
      this.props.form.setFieldsValue({
        flatId: set_arr,
      });
    }
  }
  handleWebsiteChange = (value) => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = [".com", ".org", ".net", ".in"].map(
        (domain) => `${value}${domain}`
      );
    }
    this.setState({ autoCompleteResult });
  };

  /*****************************************  SUBMIT LOGIC  **************************************************/

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var obj = {};
        //values.imageUrl = "";
        if (this.state.isEdit) {
          values._id = this.state.user_id;
          this.props.update_user(values);
          setTimeout(() => {
            this.props.get_user("?pagination=10&page=1");
            this.props.history.push(`/users`);
          }, 0);
        } else {
          this.props.post_user(values);
          setTimeout(() => {
            this.props.get_user("?pagination=10&page=1");
            this.props.history.push(`/users`);
          }, 0);
        }
      }
    });
  };
  handleRedirect = () => {
    this.props.history.push(`/users`);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.warning_message !== nextProps.warning_message) {
      this.handleRedirect();
      return false;
    }
    if (this.props.blocks !== nextProps.blocks) {
      this.setState({ blocks: nextProps.blocks });
      return false;
    } else if (this.props.block_flats !== nextProps.block_flats) {
      this.setState({ flatList: nextProps.block_flats });
      return false;
    } else if (this.props.flats_list !== nextProps.flats_list) {
      this.setState({ flatList: nextProps.flats_list });
      return false;
    } else if (this.props.get_devices !== nextProps.get_devices) {
      this.setState({ get_devices: nextProps.get_devices });
      return false;
    } else if (this.props.flats_id_list !== nextProps.flats_id_list) {
      let arr = [];
      arr.push(nextProps.flats_id_list);
      let get_flat_list = this.state.flats_id_list;
      let combine_arr = get_flat_list.concat(arr);
      this.handleRemoveFlats(combine_arr);
      this.setState({ flats_id_list: combine_arr });
      return false;
    } else if (this.props.user_data !== nextProps.user_data) {
      this.props.form.setFieldsValue({
        first_name: nextProps.user_data.ownerName.split(" ")[0] || "",
        last_name: nextProps.user_data.ownerName.split(" ")[1] || "",
        email: nextProps.user_data.ownerEmail,
        phone: nextProps.user_data.ownerMobile,
        residence: nextProps.user_data.residence,
        flatId: nextProps.user_data.flatId,
        blockId: nextProps.user_data.blockId,
      });
      let arr = [];
      arr.push(nextProps.user_data.imageUrl);
      this.setState({ fileList: arr });
      return false;
    } else {
      return true;
    }
  }
  /*****************************************  SUBMIT LOGIC  **************************************************/

  /*****************************************  IMAGE LOGIC  **************************************************/

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  /*****************************************  IMAGE LOGIC  **************************************************/
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      autoCompleteResult,
      previewVisible,
      previewImage,
      fileList,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "91",
    })(
      <Select style={{ width: 70 }}>
        <Option value="91">+91</Option>
      </Select>
    );
    return (
      <Card className="gx-card" title={this.page_name}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem {...formItemLayout} label="First Name">
            {getFieldDecorator("first_name", {
              initialValue: "",
              rules: [{ required: true, message: "Please enter First Name!" }],
            })(
              <AutoComplete placeholder="First Name">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Last Name">
            {getFieldDecorator("last_name", {
              initialValue: "",
              rules: [{message: "Please enter Last Name!" }],
            })(
              <AutoComplete placeholder="Last Name">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  message: "Please input your E-mail!",
                },
              ],
            })(<Input id="email" placeholder="Email" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Phone Number">
            {getFieldDecorator("phone", {
              rules: [
                { required: true, message: "Please input your phone number!" },
              ],
            })(
              <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Address">
            {getFieldDecorator("residence", {
              initialValue: "",
              rules: [{ required: false, message: "Please enter address!" }],
            })(
              <AutoComplete placeholder="Address">
                <Input />
              </AutoComplete>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Select Block&nbsp;
                <Tooltip title="This is the Block">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("blockId", {
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Blocks"
                optionFilterProp="children"
                onDeselect={(remove) => {
                  this.handleRemoveBlock(remove);
                }}
                onChange={(value) => {
                  this.handleBlockChange(value);
                }}
              >
                {this.state.blocks.map((list) => {
                  return <Option value={list._id}>{list.name}</Option>;
                })}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={<span>Select Flat</span>}>
            {getFieldDecorator("flatId", {
              rules: [
                {
                  required: true,
                },
              ],
            })(
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Flats"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.flatList.map((list) => {
                  return (
                    <Option value={list._id}>
                      {list.name}
                      {" - "}
                      {list.blockId.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>

          <Col span={12} offset={8}>
            {this.state.isEdit ? (
              ""
            ) : (
              <div>
                <h4>Upload Image</h4>
                <Upload
                  action="//18.136.149.198:3071/api/containers/images/upload"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  onRemove={this.handleRemove}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            )}
          </Col>
          {this.state.isEdit ? (
            ""
          ) : (
            <FormItem {...tailFormItemLayout}>
              {getFieldDecorator("agreement", {
                valuePropName: "checked",
              })(
                <Checkbox>
                  I have read the <span className="gx-link">agreement</span>
                </Checkbox>
              )}
            </FormItem>
          )}

          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const FlatRegistrationForm = Form.create()(Registration);

function mapStateToProps({ common, Block, flats, device, users }) {
  const { blocks } = Block;
  const { user_list , user_data} = users;
  const { block_flats, flats_id_list, flats_list } = flats;
  const { get_devices } = device;
  const { error_occurred, loading, show_message, warning_message } = common;
  return {
    user_list,
    user_data,
    get_devices,
    blocks,
    block_flats,
    flats_list,
    flats_id_list,
    error_occurred,
    loading,
    show_message,
    warning_message,
  };
}
export default connect(mapStateToProps, {
  get_all_blocks,
  update_user,
  get_user_by_id,
  get_all_flats_block_id,
  get_all_flats,
  fetchStart,
  get_all_flats_id,
  post_user,
  get_user
})(FlatRegistrationForm);
