import React, { useEffect, useState } from "react";
import { Button, DatePicker, Modal, Popconfirm, Input, Checkbox, message } from "antd";
import moment from "moment";

import { connect } from "react-redux";
import { fetchStart } from "../../../appRedux/actions/Common";
import { reset_device_readings } from "../../../appRedux/actions/Device";
import { get_failed_utilities } from "../../../appRedux/actions/Utility";


const dateFormat = "DD/MM/YYYY HH:mm";


const MyModal = ({ record, common, ...props }) => {
  const [popVisible, setPopVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleInput, setVisibleInput] = useState(false);
  const [usage, setUsage] = useState(false);
  const [meterValue, setMeterValue] = useState("");
  const [meterDifferenceValue, setMeterDifferenceValue] = useState("");


  const handleCancel = () => {
    setVisible(false);
    setPopVisible(false);
  };


  const resetFormData = () => {
    setMeterValue("")
  }

  const handleOk = () => {
    resetFormData();
    if (visibleInput) {
      if (meterValue == "" || meterValue == undefined) {
        message.error("Please enter the new meter value")
      } else {
        const payload = {
          flatId: record.flatId._id,
          blockId: record.blockId._id,
          resetType: "resetmeter",
          utility: record.utility,
          meterReading: meterValue,
          usage: usage
        };
        props.reset_device_readings(payload)
        handleCancel();
        var url = '&blockId=All&flatId=All'
        setTimeout(() => {
          props.get_failed_utilities(url);
        }, 1000);
      }
    } else {
      const payload = {
        flatId: record.flatId._id,
        blockId: record.blockId._id,
        utility: record.utility,
        resetType: "reset"
      };
      props.reset_device_readings(payload)
      handleCancel();
      var url = '&blockId=All&flatId=All'
      setTimeout(() => {
        props.get_failed_utilities(url);
      }, 1000);
    }
  };
  /*
  To add disableddate for particular time in date component
              disabledDate={(current) => {
                  return moment().add(-1, 'days') >= current;
                }}
  */
  return (
    <div>
      <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-mb-4">
        <Button type="primary" onClick={() => setVisible(true)}>
          Reset Meter
        </Button>
      </div>
      <Modal
        title="Select the required details"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="gx-d-flex gx-flex-column gx-align-items-center">
          <div className="gx-mb-4">
            <h5>Current Reading</h5>
            <Input
              type="Current Reading"
              placeholder="Enter password to confirm"
              value={record && record.presentReading || 0}
              disabled={true}
            />
          </div>
          <div className="gx-mb-4">
            <h5>Failure or Incoming Reading</h5>
            <Input
              type="Current Reading"
              value={record && record.incomingReading || 0}
              disabled={true}
            />
          </div>
          {visibleInput &&
            <>
              <div className="gx-mb-4">
                <h5>New Reading</h5>
                <Input
                  placeholder="Enter new reading"
                  onChange={(e) => setMeterValue(e.target.value)} />
              </div>

              <div className="gx-mb-4">
                <h5>Add to consumption</h5>
                <Checkbox color="primary"
                  onClick={(event) => {
                    setUsage(current => !current)
                  }}
                />
              </div>
            </>

          }
          <div className="gx-mb-4">
            <h5>Input custom reading</h5>
            <Checkbox color="primary"
              onClick={(event) => {
                setVisibleInput(current => !current)
              }}
            />
          </div>
          <div className="gx-mt-4">
            <h4>By clicking Add to consumption checkbox meter reading value will be added to consumption as usage</h4>
            <h4>Continues the meter reading from given new reading and difference(usage) will be added to consumption when Add to consumption is checked</h4>
            <h4>Changes will reflect immediately after clicking on submit</h4>
          </div>
        </div>
        <div></div>
      </Modal>
    </div>
  );
};

export default connect(
  (state) => ({
    common: state.common,
  }),
  { fetchStart, reset_device_readings, get_failed_utilities }
)(MyModal);
