import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "./main/dashboard/Cummulative";
import Individual from "./main/dashboard/Individual";
import {
  DataDisplay,
  DataEntry,
  Feedback,
  Navigation,
  Others,
  General,
  Table,
  Blocks,
  Flats,
  AddFlat,
  AddBlock,
  Device,
  MapDevice,
  Users,
  AddUser,
  EditUser,
  DeviceReading,
  DeviceReadingDetails,
  Bills,
  Tariff,
  Payment,
  Recharge,
  Reports,
  FailedMeter,
  FlatReadings,
  Support,
  ChangePassword,
  ChangeTransactionPassword
} from "./components";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route exact path={`${match.url}total`} component={Dashboard} />
        <Route exact path={`${match.url}individual`} component={Individual} />
        <Route exact path={`${match.url}dataDisplay`} component={DataDisplay} />
        <Route exact path={`${match.url}dataEntry`} component={DataEntry} />
        <Route exact path={`${match.url}feedback`} component={Feedback} />
        <Route exact path={`${match.url}general`} component={General} />
        <Route exact path={`${match.url}navigation`} component={Navigation} />
        <Route exact path={`${match.url}others`} component={Others} />
        <Route exact path={`${match.url}table`} component={Table} />
        <Route exact path={`${match.url}blocks`} component={Blocks} />
        <Route
          exact
          path={`${match.url}blocks/addblock`}
          component={AddBlock}
        />
        <Route exact path={`${match.url}flats`} component={Flats} />
        <Route exact path={`${match.url}flats/addflat`} component={AddFlat} />
        <Route exact path={`${match.url}devices`} component={Device} />
        <Route
          exact
          path={`${match.url}devices/mapdevice`}
          component={MapDevice}
        />
        <Route exact path={`${match.url}users`} component={Users} />
        <Route exact path={`${match.url}users/adduser`} component={AddUser} />
        <Route
          exact
          path={`${match.url}users/edituser/:id`}
          component={EditUser}
        />
        <Route exact path={`${match.url}readings`} component={DeviceReading} />
        <Route
          path={`${match.url}readings/details`}
          component={DeviceReadingDetails}
        />
        <Route exact path={`${match.url}reports`} component={Reports} />
        <Route path={`${match.url}bills`} component={Bills} />
        <Route path={`${match.url}tariff`} component={Tariff} />
        <Route path={`${match.url}transactions`} component={Payment} />
        <Route path={`${match.url}recharge`} component={Recharge} />
        <Route path={`${match.url}failedmeters`} component={FailedMeter} />
        <Route path={`${match.url}flatreadings`} component={FlatReadings} />
        <Route path={`${match.url}support`} component={Support} />
        <Route path={`${match.url}changePassword`} component={ChangePassword} />
        <Route path={`${match.url}changeTransactionPassword`} component={ChangeTransactionPassword} />
      </Switch>
    </div>
  );
};

export default App;
