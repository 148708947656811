import React from "react";
import { Card } from "antd";
import DropdownGroup from "./Dropdown/index";
import Feedback from "./Feedback";

/**
 * @description `This component renders Reports page`
 */

const Support = () => {
  return (
    <Card className="gx-card" title="Support">
    <div>
      <DropdownGroup />
    </div>
    <div className="gx-mt-5">
      <Feedback />
    </div>
  </Card>
  );
};
export default Support;
