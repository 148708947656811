import React, { useEffect, useState } from "react";
import { Button, Modal, InputNumber, Popconfirm, Input, Radio } from "antd";
import { connect } from "react-redux";
import { recharge_wallet } from "../../../appRedux/actions/Flat";
import { fetchStart } from "../../../appRedux/actions/Common";

const RadioGroup = Radio.Group;

const MyModal = ({ record, loading, errorHandler, ...props }) => {
  const [amount, setAmount] = useState(1);
  const [type, setType] = useState("cash");
  const [Tid, setTid] = useState("");
  const [password, setPassword] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setConfirmLoading(false);
  }, [loading]);

  const handleOk = () => {
    const payload = {
      flatId: record.flatId._id,
      blockId: record.blockId._id,
      amount: amount,
      userId: "",
      amountAfterTax: amount,
      paymentStatus: 0,
      type: "recharge",
      transactionType: type,
      transactionId: Tid,
      versionCheck: false,
      password,
    };
    errorHandler("success");
    props.fetchStart();
    props.recharge_wallet(payload);
    setConfirmLoading(true);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  let finalBalance;
  if (record && record.walletBalance) {
    finalBalance = record.walletBalance + amount;
  } else {
    finalBalance = amount;
  }
  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        Recharge
      </Button>
      <Modal
        title="Select the required details"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <div className="gx-mb-2">{"Enter transaction number"}</div>
          <div className="gx-mb-2">
            <Input
              placeholder="Transaction id"
              onChange={(e) => setTid(e.target.value)}
            />
          </div>
        </div>
        <div style={{ margin: "16px 0" }}>
          <div className="gx-mb-2">{"Select transaction type"}</div>
          <div className="gx-mb-2">
            <RadioGroup value={type} onChange={(e) => setType(e.target.value)}>
              <Radio value={"cash"}>Cash</Radio>
              <Radio value={"online"}>Online payment</Radio>
            </RadioGroup>
          </div>
        </div>
        <div>
          <div className="gx-mb-2">{"Enter Amount"}</div>
          <InputNumber
            min={1}
            defaultValue={1}
            onChange={(e) => setAmount(e)}
          />
        </div>
        <div style={{ margin: "16px 0" }}>
          {"Wallet balance : " + finalBalance}
        </div>{" "}
        <div>
          <Input
            type="password"
            placeholder="Enter password to confirm"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
      </Modal>
    </div>
  );
};

export default connect(
  (state) => ({
    loading: state.common.loading,
  }),
  { fetchStart, recharge_wallet }
)(MyModal);
