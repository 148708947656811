import {
  GET_ALL_DEVICES,
  GET_ALL_DEVICES_SUCCESS,
  MAP_DEVICE_DATA,
  GET_ALL_DEVICES_IN_USE,
  GET_ALL_DEVICES_IN_USE_SUCCESS,
  GET_FLAT_DEVICE_READING,
  GET_FLAT_DEVICE_READING_SUCCESS,
  RELAY_DEVICE,
  UNMAP_PROJECT_DEVICE,
  GET_ALL_FLAT_DEVICES,
  GET_ALL_FLAT_DEVICES_SUCCESS,
  EDIT_DEVICE,
  RESET_METER_DEVICE
} from "constants/ActionTypes";

export const get_all_devices = () => {
  return {
    type: GET_ALL_DEVICES,
  };
};
export const get_all_devices_in_use = (payload) => {
  return {
    type: GET_ALL_DEVICES_IN_USE,
    payload: payload,
  };
};
export const edit_device = (payload) => {
  return {
    type: EDIT_DEVICE,
    payload: payload,
  };
};
export const get_all_flat_devices = (payload) => {
  return {
    type: GET_ALL_FLAT_DEVICES,
    payload: payload,
  };
};
export const get_all_flat_devices_success = (payload) => {
  return {
    type: GET_ALL_FLAT_DEVICES_SUCCESS,
    payload: payload,
  };
};
export const get_all_devices_in_use_success = (payload) => {
  return {
    type: GET_ALL_DEVICES_IN_USE_SUCCESS,
    payload: payload,
  };
};
export const get_all_devices_success = (payload) => {
  return {
    type: GET_ALL_DEVICES_SUCCESS,
    payload: payload,
  };
};

export const map_device_data = (payload) => {
  return {
    type: MAP_DEVICE_DATA,
    payload: payload,
  };
};

export const reset_device_readings = (payload) => {
  return {
    type: RESET_METER_DEVICE,
    payload: payload,
  };
};

export const unmap_project_device = (payload) => {
  return {
    type: UNMAP_PROJECT_DEVICE,
    payload: payload,
  };
};

export const get_flat_device_readings = (payload) => {
  return {
    type: GET_FLAT_DEVICE_READING,
    payload: payload,
  };
};

export const get_flat_device_readings_success = (payload) => {
  return {
    type: GET_FLAT_DEVICE_READING_SUCCESS,
    payload: payload,
  };
};

export const relay_device = (payload) => {
  return {
    type: RELAY_DEVICE,
    payload: payload,
  };
};
