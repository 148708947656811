import React from "react";
import { Table, Tag, message } from "antd";
import { connect } from "react-redux";
import Users from "../User/user";
import { withRouter } from "react-router-dom";



const UserTable = ({ users, common, ...props }) => {
  const { action_type } = common;
  const expandedRowRender = (record) => {
    const columns = [
      { title: "Block Name", dataIndex: "blockId.name", key: "blockname" },
      { title: "Flat Name", dataIndex: "name", key: "name" },
      { title: "Flat Sq ft", dataIndex: "sqft", key: "sqft" },
      { title: "BHK", dataIndex: "bhk", key: "bhk" },
    ];
    return (
      <Table columns={columns} dataSource={record.flatId} pagination={false} />
    );
  };
  const editUser = (record) => {
    props.history.push(`/users/edituser/${record}`);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "ownerName",
      width: "25%",
    },
    {
      title: "Mobile No",
      dataIndex: "ownerMobile",
      width: "25%",
    },
    {
      title: "Email",
      dataIndex: "ownerEmail",
      width: "25%",
    },
    {
      title: "Image",
      dataIndex: "imageUrl.thumbUrl",
      width: "25%",
      render: (value) => <img src={value} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "25%",
      editable: true,
      render: (value) => {
        const color = value === 1001 ? "green" : "volcano";
        const name = value === 1001 ? "Active" : "In-Active";
        return <span>{<Tag color={color}>{name}</Tag>}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "mode",
      render: (value, record) => {
        return (
          <span>
            <a
              disabled={value === "modbus-wired" ? true : false}
              onClick={() => editUser(record._id)}
            >
              Edit
            </a>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className="components-table-demo-nested"
        columns={columns}
        expandedRowRender={(record) => expandedRowRender(record)}
        dataSource={users.data}
        bordered
        pagination={{
          total: users.count,
          current: props.page,
          onChange: (val) => props.setPage(val),
          showSizeChanger: true,
          onShowSizeChange: (cur, pageSize) => {
            console.log({ pageSize });
            props.setRows(pageSize);
          },
        }}
      />
      <div>
        {action_type == "create_new_user"
          ? message.success("Successfully created new user")
          : null}
      </div>
      <div>
        {action_type == "new_flat_success"
          ? message.success("Successfully updated user")
          : null}
      </div>
    </div>
  );
};
export default connect((state) => ({
  users: state.users.users_list,
  common: state.common,
}))(withRouter(UserTable));

