import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "assets/vendors/style";
import configureStore, { history } from "./appRedux/store";
import { userSignInSuccess, userSignOut } from "./appRedux/actions";
import "./firebase/firebase";
import App from "./containers/App/index";

export const store = configureStore();

let userData = localStorage.getItem("userData");

if (userData && userData.access_tokens) {
  userData = JSON.parse(userData);
  const token = userData.access_tokens[0].access_token;
  const decoded = jwt_decode(token);
  const tokenIsValid = Date.now() / 1000 < decoded.exp;
  if (tokenIsValid) {
    store.dispatch(userSignInSuccess(userData));
  } else {
    store.dispatch(userSignOut());
  }
}

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
