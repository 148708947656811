import React from "react";
import { Select } from "antd";

const Option = Select.Option;

class SearchField extends React.Component {
  state = {
    value: "",
  };
  handleChange = (value) => {
    let getType = this.props.type;
    let filterVal;
    if(this.props.mode === "multiple"){
      if(value.length > 0){
        filterVal = value.filter(itm => itm !== "All utilities")
      } else{
        filterVal = ["All utilities"]
      }
    }else{
      filterVal = value
    }
    this.props.selected(filterVal, getType);
    this.setState({ value: filterVal });
  };
  render() {
    let combined_arr;
    let obj;
    if(this.props.place === "All"){
      obj = [
        {
          name: "All",
          _id: "All",
        },
      ];
      combined_arr = obj.concat(this.props.data);
    } else {
      obj = []
      combined_arr = obj.concat(this.props.data);
    }

    return (
      <div>
        <div style={{ marginLeft: "10px" }}>
          {"Select "}
          {this.props.type}
        </div>
        <div>
          <Select
            mode={this.props.mode}
            showSearch
            style={{ width: 200, margin: "10px" }}
            optionFilterProp="children"
            onChange={this.handleChange}
            value={
              this.state.value !== "" ? this.state.value : this.props.place
            }
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {combined_arr.map((list) => {
              return (
                <Option value={list._id} key={list._id}>
                  {this.props.mode === "default"
                    ? list.name
                    : list.name + "-" + list.code}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
}

export default SearchField;
