import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";

const fmt = "YYYY/MM/DD";
/**
 * @description `a custom date range picker with left and right limit of 30 days`
 * @param startDate start date of range picker
 * @param endDate end of range picker
 * @param setStartDate start date value setter
 * @param setEndDate end date value setter
 */

const CustomRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  //? left and right limit dates for end date picker
  const [leftEndDate, setLeftEndDate] = useState(moment());
  const [rightEndDate, setRightEndDate] = useState(moment());

  //? sets left and right limit for end date picker
  const setRange = () => {
    const templeft = moment(startDate).subtract(93, "days");
    const tempright = moment(startDate).add(93, "days");
    //? if (rightLimit > today) set rightLimit = today
    if (new Date(tempright).getTime() > new Date().getTime()) {
      setRightEndDate(moment());
    } else {
      setRightEndDate(tempright);
    }
    setLeftEndDate(templeft);
  };

  useEffect(() => {
    setRange();
  }, [startDate]);

  //? set start date value and end date value based on start date
  const handleStartChange = (date) => {
    if (date) {
      setStartDate(date);
      const tempend = moment(date).add(29, "days");
      if (new Date(tempend).getTime() < new Date().getTime()) {
        setEndDate(tempend);
      } else {
        setEndDate(moment());
      }
    } else {
      setStartDate(startDate);
    }
  };

  //? set end date value
  const handleEndChange = (date) =>
    date ? setEndDate(date) : setEndDate(endDate);

  //? disable start date selection beyond today
  const disableStart = (curr) => curr > moment().endOf("day");

  //? disable end date selection beyond left and right limit
  const disableEnd = (curr) => {
    return (
      new Date(curr).getTime() < new Date(leftEndDate).getTime() ||
      new Date(curr).getTime() > new Date(rightEndDate).getTime()
    );
  };

  return (
    <div className="gx-d-flex">
      <div className="gx-mr-3">
        <h5>Start date </h5>
        <DatePicker
          format={fmt}
          style={{ width: 130 }}
          disabledDate={disableStart}
          onChange={handleStartChange}
          defaultValue={startDate}
          value={startDate}
        />
      </div>
      <div className="gx-mr-3">
        <h5>End date </h5>
        <DatePicker
          format={fmt}
          style={{ width: 130 }}
          disabledDate={disableEnd}
          onChange={handleEndChange}
          defaultValue={endDate}
          value={endDate}
        />
      </div>
    </div>
  );
};

export default CustomRangePicker;
