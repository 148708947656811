import {
  GET_ALL_FLATS,
  GET_ALL_FLATS_SUCCESS,
  POST_FLAT,
  POST_FLAT_SUCCESS,
  GET_ALL_FLATS_BLOCK_ID,
  GET_ALL_FLATS_BLOCK_ID_SUCCESS,
  GET_ALL_FLATS_ID,
  GET_ALL_FLATS_ID_SUCCESS,
  UPDATE_FLAT,
  GET_ALL_FLATS_BALANCE,
  RECHARGE_WALLET,
  GET_ALL_FLATS_BALANCE_SUCCESS,
  GET_FLATS_TRANSACTIONS,
  GET_FLATS_TRANSACTIONS_SUCCESS,
  GET_FLAT_BLOCK_NAME,
  GET_FLAT_BLOCK_NAME_SUCCESS
} from "../../constants/ActionTypes";

export const get_all_flats = () => {
  return {
    type: GET_ALL_FLATS,
  };
};

export const get_flat_block_names = (payload) => {
  return {
    type: GET_FLAT_BLOCK_NAME,
    payload: payload,
  };
}

export const get_all_flats_block_id = (payload) => {
  return {
    type: GET_ALL_FLATS_BLOCK_ID,
    payload: payload,
  };
};

export const get_all_flats_id = (payload) => {
  return {
    type: GET_ALL_FLATS_ID,
    payload: payload,
  };
};
export const update_flat = (payload) => {
  return {
    type: UPDATE_FLAT,
    payload: payload,
  };
};

export const get_all_flats_id_success = (payload) => {
  return {
    type: GET_ALL_FLATS_ID_SUCCESS,
    payload: payload,
  };
};
export const get_flat_block_names_success = (payload) => {
  return {
    type: GET_FLAT_BLOCK_NAME_SUCCESS,
    payload: payload,
  };
};

export const get_all_flats_block_id_success = (payload) => {
  return {
    type: GET_ALL_FLATS_BLOCK_ID_SUCCESS,
    payload: payload,
  };
};

export const get_all_flats_success = (flats) => {
  return {
    type: GET_ALL_FLATS_SUCCESS,
    payload: flats,
  };
};

export const post_flat = (payload) => {
  return {
    type: POST_FLAT,
    payload: payload,
  };
};

export const get_all_flats_balance = (payload) => {
  return {
    type: GET_ALL_FLATS_BALANCE,
    payload: payload,
  };
};
export const get_all_flats_balance_success = (payload) => {
  return {
    type: GET_ALL_FLATS_BALANCE_SUCCESS,
    payload: payload,
  };
};

export const get_flats_transactions = (payload) => {
  return {
    type: GET_FLATS_TRANSACTIONS,
    payload,
  };
};

export const get_flats_transactions_success = (payload) => {
  return {
    type: GET_FLATS_TRANSACTIONS_SUCCESS,
    payload,
  };
};

export const recharge_wallet = (payload) => {
  return {
    type: RECHARGE_WALLET,
    payload: payload,
  };
};
